// Only for reference
export const rooms = {
  cuisine: {
    id: "cuisine",
    title: "la cuisine",
    description:
      "La cuisine combine des murs en brique et un sol en bois. À gauche, un ensemble d'étagères sous une arche expose de la vaisselle et des casseroles, au-dessus d'une table orange avec des tabourets verts. Au centre, un îlot inclut un évier entouré de plantes et d'aliments bio. Des lampes suspendues noires éclairent l'espace, tandis qu'à droite, un réfrigérateur crème vintage côtoie une cuisinière moderne et une bibliothèque pleine de livres.",
  },
  garage: {
    id: "garage",
    title: "le garage",
    description:
      "Dans le garage à la peinture murale rose, un véhicule bleu domine l'espace. Au-dessus, des poutres en bois ajoutent un contraste rustique. À gauche, la lumière filtre à travers une fenêtre et éclaire un vélo rose et des caisses avec des objets emballés. À droite, un établi porte des pots de peinture et des boîtes, sous des affiches qui donnent une touche de caractère au lieu.",
  },
  chambre: {
    id: "chambre",
    title: "la chambre",
    description:
      "Dans cette chambre familiale moderne, les murs sont peints d'un vert profond, et le plafond incliné intègre une baie vitrée qui baigne la pièce de lumière naturelle. Un lit spacieux avec une couverture verte se situe au centre, encadré par des tables de nuit en bois clair surmontées de lampes simples. Au-dessus du lit, une étagère murale originale évoque les branches d'un arbre et est agrémentée de livres. Une lanterne décorative en forme de diamant de couleur vert pâle pend du plafond vitré, ajoutant une touche élégante à l'espace. Un berceau traditionnel est placé à côté d'un panier animal vert déposé négligemment sur le parquet clair qui renforce l'atmosphère chaleureuse de la chambre.",
  },
  salon: {
    id: "salon",
    title: "le salon",
    description:
      "Dans le salon de la maison, les murs sont peints d'un orange éclatant et le plafond rouge s'arc-boute élégamment. Un canapé crème se tient sur un tapis bleu, accompagné d'une table basse orange et d'un téléviseur noir. Un arbre insolite émerge du plancher, ajoutant un élément organique au décor moderne. Des touches décoratives incluent une affiche artistique, des étagères avec des livres et une petite lampe, ainsi qu'un miroir rond à cadre jaune.",
  },
  hall: {
    id: "hall",
    title: "le hall",
    description:
      "Le hall d'entrée de la maison est énergique avec ses murs orange vif. Le sol en parquet brun crée une ambiance chaleureuse. À gauche, des meubles verts portent une plante et un globe terrestre, tandis qu'une balle de basket au sol évoque une ambiance familiale et active. Un passage voûté invite à explorer davantage, et sur la droite, des étagères et un porte-manteau en bois combinent utilité et élégance.",
  },
  bureau: {
    id: "bureau",
    title: "le bureau",
    description:
      "Le bureau à domicile est épuré avec des murs beige et un plancher en bois. Un bureau central avec ordinateur est flanqué de suspensions élégantes. À gauche, une bibliothèque déborde de livres, encadrée par des toiles artistiques, tandis que des sculptures sphériques violettes décorent la droite. Des rangements avec portes grises et une plante verte complètent l'espace.",
  },
};

import { MeshStandardMaterial, RepeatWrapping, TextureLoader } from "three";

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo
} from "react";
import {
  useGLTF,
  PerspectiveCamera,
  ScrollControls,
  Environment,
  Lightformer,
  Html,
  Outlines,
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import emitter from "../emitter.js";
import { useExperienceStore } from "../store/index.js";
import { CameraControl } from "../components/CameraControl";
import styles from "./Room.module.scss";
import Glow from "../components/Glow.js";
import useInactivityTimer from "../components/useInactivityTimer.js";

const camMobCalc = (data, currentPos, currentRot) => {
  const pos = [
    Math.sin(((data.offset - 0.5) * 0.15) / 1) * 1 + currentPos.current.x,
    Math.sin(((data.offset - 0.5) * 0.5) / 1) + currentPos.current.y,
    currentPos.current.z,
  ];
  const rot = [
    currentRot.current.x,
    Math.sin(((data.offset - 0.5) * 0.5) / -2) + currentRot.current.y,
    currentRot.current.z,
  ];
  return { rotation: rot, position: pos };
};

const camDeskCalc = (pointer, currentPos, currentRot) => {
  const pos = [
    Math.sin(pointer.current.x / 1) * 0.2,
    pointer.current.y / 5 + currentPos.current.y,
    currentPos.current.z,
  ];
  const rot = [
    currentRot.current.x,
    Math.sin(pointer.current.x / -48) + currentRot.current.y,
    currentRot.current.z,
  ];
  return { rotation: rot, position: pos };
};



export function Bureau(props) {
  const objectsInRoom = useExperienceStore((state) => state.objectsInRoom());
  const setObjectFound = useExperienceStore((state) => state.setObjectFound);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const objectsFound = useRef([]);
  const [colorsItems, setColorsItems] = useState(null);
  const isMobile = window.innerWidth <= 768;
  useInactivityTimer("parcourir");

  // Set LightMap


  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/lightmap/light-map-bureau.webp",
    process.env.PUBLIC_URL + "/matieres/plancher.webp",
    process.env.PUBLIC_URL + "/textures/bureau/matcapbureau.webp",
    process.env.PUBLIC_URL + "/textures/bureau/carteeuropeenne.webp",
    process.env.PUBLIC_URL + "/textures/bureau/chaise.webp",
    process.env.PUBLIC_URL + "/textures/bureau/cube.webp",
    process.env.PUBLIC_URL + "/textures/bureau/cup.webp",
    process.env.PUBLIC_URL + "/textures/bureau/fontaine.webp",
    process.env.PUBLIC_URL + "/textures/bureau/livresbureau.webp",
    process.env.PUBLIC_URL + "/textures/bureau/livresfake.webp",
    process.env.PUBLIC_URL + "/textures/bureau/ordinateur.webp",
    process.env.PUBLIC_URL + "/textures/bureau/poubelle.webp",
    process.env.PUBLIC_URL + "/textures/bureau/tableaubureau.webp",
    process.env.PUBLIC_URL + "/textures/bureau/tiroir.webp",
    process.env.PUBLIC_URL + "/textures/bureau/plantehall.webp",
  ], []);

  const [
    lightmapbureau,
    plancher,
    matcap,
    carteeuropeenne,
    chaise,
    cube,
    cup,
    fontaine,
    livresbureau,
    livresfake,
    ordinateur,
    poubelle,
    tableau,
    tiroir,
    plantehall,
  ] = useLoader(TextureLoader, texturePaths);


  useEffect(() => {
    [plancher].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(10, 5);
      t.offset.set(0, 0);
    });

    [livresfake].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(16, 12);
      t.offset.set(0, 0);
    });
  }, [plancher, livresfake]);

  // ENd Lightmap

  // Message intro
  useEffect(() => {
    emitter.emit("info:open", {
      title: "Restez en alerte !",
      text: `On ne sait jamais ce que peut cacher un objet !`,
    });
    //TODO plug animate out on scroll
    const timeoutId = setTimeout(() => {
      emitter.emit("info:close");
    }, 4000);
    // Fonction de nettoyage

    const items = {
      ordinateur: "#D3D62E",
      carteAssurance: "#D3D62E",
      fontaine: "#D3D62E",
    };

    for (const [key, value] of Object.entries(items)) {
      const obj = activeScenario.objects.find((obj) => {
        let tmpKey = key;
        if (key == "carteAssurance") {
          tmpKey = "carte-assurance";
        }
        return obj.id === tmpKey;
      });
      if (obj && obj.hasOwnProperty("found")) {
        items[key] = "#FF0000";
      }
    }

    setColorsItems(items);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // message intro

  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "/models/bureau.glb");

  useEffect(() => {
    emitter.emit("pageViewEvent", "bureau", activeScenario.id);
  }, []);

  useEffect(() => {
    emitter.emit("scene:loaded");
  }, [nodes]);

  const selectObject = useCallback(
    (id) => {

      const matchingObject = id.includes("ordinateur")
        ? objectsInRoom.find((obj) => obj.id.includes("ordinateur"))
        : objectsInRoom.find((obj) => obj.id === id);
      if (matchingObject) {
        if (colorsItems !== null) {
          if (id === "carte-assurance") {
            const copy = { ...colorsItems };
            copy["carteAssurance"] = "#FF0000";
            setColorsItems(copy);
          } else {
            const copy = { ...colorsItems };
            copy[id] = "#FF0000";
            setColorsItems(copy);
          }
        }

        setObjectFound(matchingObject.id);
        emitter.emit("popup:open", {
          type: "object",
          object: matchingObject,
        });
        if (!objectsFound.current.includes(matchingObject.id)) {
          emitter.emit("findObjectEvent", matchingObject.id, activeScenario.id, 'bureau');
          objectsFound.current.push(matchingObject.id);
        }
      }
    },
    [setObjectFound, objectsInRoom],
  );

  const pointerEvents = (pointing) => {
    document.getElementsByTagName("body")[0].style.cursor = pointing
      ? "pointer"
      : "default";
  };

  const onPointerEnter = useCallback((id) => {
    const isComputer = id.includes("ordinateur");
    const matchingObject = objectsInRoom.find((obj) =>
      isComputer || obj.id === id
    );
    if (matchingObject) {
      emitter.emit("sound:hover");
      pointerEvents(true);
    }
  }, []);


  useLayoutEffect(() => {
    Object.values(nodes).forEach(
      (node) => node.isMesh && (node.receiveShadow = node.castShadow = true),
    );

    materials.sol = new MeshStandardMaterial({
      map: plancher,
      envMapIntensity: 0.5,
      roughness: 0.5,
      metalness: 0.85,
    });

    materials.structure = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.5,
      metalness: 0.5,
    });
    materials.structure2 = new MeshStandardMaterial({
      envMapIntensity: 0.33,
      roughness: 0.5,
      metalness: 0.1,
    });
    materials.structure3 = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.5,
      metalness: 0.5,
    });
    materials.structure4 = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.75,
      metalness: 0.2,
    });
    materials.structure5 = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.65,
      metalness: 0.1,
    });

    materials.meubles2 = new MeshStandardMaterial({
      color: "#a46868",
      envMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.2,
    });

    materials.tableau = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.25,
      metalness: 0.2,
    });

    materials.chaise = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.85,
      metalness: 0.2,
    });

    materials.ordinateur = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.25,
      metalness: 0.5,
    });

    materials.cup = new MeshStandardMaterial({
      envMapIntensity: 1,
      roughness: 0.1,
      metalness: 0.2,
    });

    materials.etageres = new MeshStandardMaterial({
      map: cube,
      envMapIntensity: 0.25,
      roughness: 0.35,
      metalness: 0.5,
    });

    materials.cadres = new MeshStandardMaterial({
      color: "#b22d2d",
      envMapIntensity: 0.5,
      roughness: 0.75,
      metalness: 0.95,
    });

    materials.plantehall = new MeshStandardMaterial({
      map: plantehall,
      envMapIntensity: 1,
      roughness: 0.5,
      metalness: 0.2,
    });

    materials.planthall2 = new MeshStandardMaterial({
      color: "#37573f",
      envMapIntensity: 1,
      roughness: 0.65,
      metalness: 0.42,
    });

    materials.cylinder = new MeshStandardMaterial({
      color: "#a46868",
      envMapIntensity: 1,
      roughness: 0.65,
      metalness: 0.42,
    });

    materials.lantern = new MeshStandardMaterial({
      color: "#292b44",
      envMapIntensity: 1,
      roughness: 0.65,
      metalness: 0.42,
    });

    materials.fontaine = new MeshStandardMaterial({
      map: fontaine,
      mapflipY: false,
      envMapIntensity: 1,
      roughness: 0.7,
      metalness: 0,
    });

    materials.tiroir = new MeshStandardMaterial({
      map: tiroir,
      envMapIntensity: 1.5,
      roughness: 0.4,
      metalness: 0.4,
    });

    materials.livresbureau = new MeshStandardMaterial({
      map: livresbureau,
      envMapIntensity: 1,
      roughness: 0.9,
      metalness: 0,
    });
  }, [nodes, materials]);

  const content = () => (
    <group position={[-3, 0, 0]}>
      <group
        position={[0.5, 3.179, -12.325]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={1.137}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.WP_20180112_020g001.geometry}
          material={materials.tableau}
          material-map={tableau}
          material-map-flipY={false}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.WP_20180112_020g001_1.geometry}
          material={materials.cadres}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.chaise.geometry}
        material={materials.chaise}
        material-map={chaise}
        material-map-flipY={false}
        position={[3.96, 0.895, -5.69]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ordinateur.geometry}
        material={materials.ordinateur}
        material-map={ordinateur}
        material-map-flipY={false}
        position={[4.8, 1.367, -7.595]}
        onClick={() => selectObject("ordinateur")}
        onPointerEnter={() => onPointerEnter("ordinateur")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find(
          (obj) =>
            obj.id === "ordinateur" ||
            obj.id === "ordinateur-produit-bio" ||
            obj.id === "ordinateur-berceau" ||
            obj.id === "ordinateur-rangement" ||
            obj.id === "ordinateur-cv",
        ) && (
            <>
              <Glow scale={1} near={-4} color={colorsItems["ordinateur"]} />
              <Outlines thickness={0.009} color={colorsItems["ordinateur"]} />
              <Html>
                <button
                  className={styles.objetsscene}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      selectObject("ordinateur");
                    }
                  }}
                  aria-label="Ouvrir la modale ordinateur"
                >
                  L'ordinateur
                </button>
              </Html>
            </>
          )}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cup.geometry}
        material={materials.cup}
        material-map={cup}
        material-map-flipY={false}
        position={[3.718, 0.962, -7.563]}
        rotation={[0, 1.119, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.etageres.geometry}
        material={materials.etageres}
        material-map={cube}
        material-map-flipY={false}
        position={[4.835, 2.248, -12.056]}
        rotation={[0, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.livres.geometry}
        material={materials.livresbureau}
        material-map={livresbureau}
        material-map-flipY={false}
        position={[6.4, 1.267, -8.041]}
        rotation={[0, -0.346, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["carte-europenne"].geometry}
        material-map={carteeuropeenne}
        material-map-flipY={false}
        position={[3.135, 1.068, -7.428]}
        rotation={[0.913, 1.559, 0]}
        onClick={() => selectObject("carte-assurance")}
        onPointerEnter={() => onPointerEnter("carte-assurance")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "carte-assurance") && (
          <>
            <Glow
              scale={0.5}
              near={-5}
              far={0.5}
              color={colorsItems["carteAssurance"]}
              position={[0, 0.1, 0]}
              rotation={[1.5, 9, 0]}
            />

            <Outlines
              thickness={0.04}
              angle={2}
              color={colorsItems["carteAssurance"]}
            />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("carte-assurance");
                  }
                }}
                aria-label="Ouvrir la modale carte assurance"
              >
                La carte d'assurance
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tiroir.geometry}
        material={materials.tiroir}
        material-map={tiroir}
        material-map-flipY={false}
        position={[-0.201, 0.759, -11.226]}
      />
      <group position={[2.593, 0, -8.495]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mülleimer_Image_Texture_Cylinder002.geometry}
          material={materials["Mülleimer_Boden.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mülleimer_Image_Texture_Cylinder002_1.geometry}
        >
          <meshStandardMaterial
            color="grey"
            alphaMap={poubelle}
            alphaMap-flipY={false}
            transparent={true}
          />
        </mesh>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["20001"].geometry}
        material={materials["paper.001"]}
        position={[3.181, 0.972, -7.381]}
        rotation={[0, 0.663, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fontaine.geometry}
        material={materials.fontaine}
        material-map-flipY={false}
        position={[1.237, 1.705, -11.769]}
        rotation={[0, -0.956, 0]}
        onClick={() => selectObject("fontaine")}
        onPointerEnter={() => onPointerEnter("fontaine")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "fontaine") && (
          <>
            <Glow
              scale={0.7}
              near={-2}
              far={0.4}
              color={colorsItems["fontaine"]}
              position={[0, 0.2, 0.19]}
              rotation={[0, 1, 0]}
            />

            <Outlines thickness={0.017} color={colorsItems["fontaine"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("fontaine");
                  }
                }}
                aria-label="Ouvrir la modale fontaine"
              >
                La fontaine
              </button>
            </Html>
          </>
        )}
      </mesh>
      <group position={[-4.419, 2.103, -15.33]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.structure001_1.geometry}
          material-lightMap={lightmapbureau}
          material-lightMapIntensity={15}
          material-lightMap-flipY={false}
          material={materials.sol}
          material-map={plancher}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.structure001_2.geometry}
          material-lightMap={lightmapbureau}
          material-lightMapIntensity={10}
          material-lightMap-flipY={false}
          material={materials.structure}
          material-color="#ffaf81"
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.structure001_3.geometry}
          material-lightMap={lightmapbureau}
          material-lightMapIntensity={5}
          material-lightMap-flipY={false}
          material={materials.structure2}
          material-color={"#8f6448"}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.structure001_4.geometry}
          material={materials.structure3}
          material-map={livresfake}
          material-map-flipY={false}
          material-lightMap={lightmapbureau}
          material-lightMapIntensity={5}
          material-lightMap-flipY={false}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.structure001_5.geometry}
          material-lightMap={lightmapbureau}
          material-lightMapIntensity={10}
          material-lightMap-flipY={false}
          material={materials.structure4}
          material-color={"#ffaf81"}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.structure001_6.geometry}
          material-lightMap={lightmapbureau}
          material-lightMapIntensity={3}
          material-lightMap-flipY={false}
          material={materials.structure5}
          material-color={"#6b4935"}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.structure001.geometry}
        material={materials.meubles2}
        position={[-4.419, 2.103, -15.33]}
      >
        <meshMatcapMaterial matcap={matcap} color="#cfc5a9" />
      </mesh>
      <group position={[3.646, 4.243, -8.889]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder008.geometry}
          material={materials.lantern}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder008_1.geometry}
          material={materials["Material.009"]}
        />
      </group>
      <group position={[6.694, 1.372, -5.944]} rotation={[0, -0.041, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2_1"].geometry}
          material={materials.plantehall}
          // material-map={plantehall}
          material-color="#74c487"
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2_2"].geometry}
          material={materials.planthall2}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder002.geometry}
        material={materials.cylinder}
        position={[6.665, 1.353, -5.937]}
      />
    </group>
  );


  return (
    <group {...props} dispose={null}>
      {isMobile ? (
        <ScrollControls pages={8} damping={0.0001} horizontal={true}>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={32.269}
            position={[0, 2, 3]}
          />
          <CameraControl
            camPosDesk={[0, 2, 2]}
            camPosMob={[0, 2.25, 6]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
        </ScrollControls>
      ) : (
        <>
          <CameraControl
            camPosDesk={[0, 2, 2]}
            camPosMob={[0, 2.25, 6]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={30.269}
            position={[0, 1.75, 0]}
          />
        </>
      )}
      {colorsItems !== null && content()}
      <Environment blur={0.5}>
        <Lightformer
          form={"circle"}
          intensity={10}
          position={[-0.75, 0.4, -2]}
          rotation-y={Math.PI / 12}
          scale={0.5}
          color={"#ffc09b"}
        />
        <Lightformer
          intensity={10}
          position={[3, 2.25, 6]}
          rotation-y={Math.PI * 1.01}
          scale={4}
          color={"#fffcdb"}
        />
        <Lightformer
          intensity={2}
          rotation-y={Math.PI / 2}
          position={[-0, 1, -1]}
          scale={[20, 0.1, 1]}
          color={"#d99c7f"}
        />
        <Lightformer
          position={[-5, -1, -1]}
          scale={[20, 0.5, 1]}
          color={"#d99c7f"}
        />
        <Lightformer
          intensity={1.15}
          rotation-y={-Math.PI / 2}
          position={[2, 1.5, 0.25]}
          scale={5}
          color={"#d99c7f"}
        />

      </Environment>
    </group>
  );
}

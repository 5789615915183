import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./App.css";
import { Canvas } from "@react-three/fiber";
import { useExperienceStore } from "./store";
import styles from "./SceneManager.module.scss";
import { gsap } from "gsap";
import emitter from "./emitter";
import transitionsData from "./transitions.json";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { Scenes } from "./Scenes";
import MentionsLegales from "./components/MentionsLegales";

Splitting();

function Loader({ onGlobalLoadedEnd }) {
  useEffect(() => {
    return () => {
      onGlobalLoadedEnd();
    };
  });

  return <></>;
}

function SceneManager() {
  const currentRoom = useExperienceStore((state) => state.currentRoom);
  const experienceStarted = useExperienceStore(
    (state) => state.experienceStarted,
  );
  const [visibleRoom, setVisibleRoom] = useState(null);
  const transitionRef = useRef(null);
  const emitterRegistered = useRef(false);
  const [transContent, setTransContent] = useState();
  const btnRef = useRef(null);
  const loaderRef = useRef(null);
  const [showButton, setShowButton] = useState(true);
  const setCurrentRoom = useExperienceStore((state) => state.setCurrentRoom);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const [showMentionsLegales, setShowMentionsLegales] = useState(false);

  const toggleMentionsLegales = () => {
    setShowMentionsLegales(!showMentionsLegales);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: 'Mentions_Legales',
    });
  };

  const closeMentionsLegales = () => setShowMentionsLegales(false);

  useEffect(() => {
    if (!emitterRegistered.current) {
      emitterRegistered.current = true;
      emitter.on("scene:loaded", onSceneLoaded);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "page_view",
        page_chapter1: `Page - A vous de jouer`,
      });
    }
  });


  const goToRoom = () => {
    emitter.emit("sound:transition", "hall");
    emitter.emit(
      "buttonClickEvent",
      "redirection",
      "hall",

    );
    setCurrentRoom("hall");
  };


  useEffect(() => {

    const transtionSentences = transitionsData.data;
    const idx =
      currentRoom == null
        ? 0
        : Math.floor(Math.random() * (transtionSentences.length - 1)) + 1;
    const content = transtionSentences[idx];
    setTransContent(content);

    if (experienceStarted) {
      const tlTrans = gsap
        .timeline()
        .to(transitionRef.current, {
          autoAlpha: 1,
          duration: 0.5,
        })
        .add(() => {
          setVisibleRoom(currentRoom);
        }, "3")
        .play();

      tlTrans.eventCallback("onComplete", () => {
        tlTrans.kill();
      });
    } else {
      gsap
        .timeline()
        .to(transitionRef.current, { autoAlpha: 1, duration: 0.5 });
    }
  }, [currentRoom, experienceStarted]);

  const onGlobalLoadedEnd = () => {
    const tlBtn = gsap
      .timeline()
      .to(loaderRef.current, { autoAlpha: 0, duration: 0.5 })
      .to(btnRef.current, {
        autoAlpha: 1,
        duration: 1,
        pointerEvents: "auto",
        scale: 1.2,
      })
      .play();

    tlBtn.eventCallback("onComplete", () => {
      tlBtn.kill();
    });
  };

  const onSceneLoaded = useCallback(() => {
    const tlTrans = gsap.timeline().to(transitionRef.current, {
      autoAlpha: 0,
      duration: 0.5,
    });
    tlTrans.eventCallback("onComplete", () => {
      tlTrans.kill();
    });
  }, []);

  const inputHandler = () => {
    const tlTrans = gsap.timeline().to(transitionRef.current, {
      autoAlpha: 0,
      duration: 0.5,
    });
    tlTrans.eventCallback("onComplete", () => {
      tlTrans.kill();
    });

    gsap.to(loaderRef.current, { autoAlpha: 1, duration: 0.5 });
    gsap.to(btnRef.current, { autoAlpha: 0, duration: 0.5 });

    emitter.emit("experience:start");
    setShowButton(false); // Ajoutez cette ligne pour cacher le bouton
    setVisibleRoom(currentRoom);
  };

  const animateText = () => {
    gsap.killTweensOf(".word");
    const textElement = document.querySelector(".content__text");
    const words = Splitting({ target: textElement, by: "words" })[0].words;

    gsap.fromTo(
      words,
      {
        opacity: 0,
        rotationY: 90,
      },
      {
        duration: 3,
        ease: "expo",
        opacity: 1,
        rotationY: 0,
        stagger: {
          each: 0.05,
          from: "edges",
        },
      },
    );
  };

  useEffect(() => {
    if (transContent) {
      animateText();
    }
  }, [transContent]);

  const handlePdfClick = useCallback((url) => {
    // Pousser l'événement dans le Data Layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: 'pdf',
    });
    // Ouvrir le lien dans un nouvel onglet
    window.open(url, '_blank');
  }, []);

  const handleDataLayerEvent = useCallback((clickId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: clickId,
    });
    console.log(clickId)
  }, []);


  return (
    <>
      <div ref={transitionRef} className={styles.transition}>
        <div className={styles.nonaccess}>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Empêcher le comportement par défaut du lien
              handlePdfClick('/page-accessible.pdf', 'Nom de la ressource ou label'); // Remplacer 'Nom de la ressource ou label' par une description appropriée
            }}
            rel="noreferrer"
          >
            <span className={styles.underline}>Version accessible<br></br></span>
            aux outils d'assistance technologique
          </a>
        </div>
        <button
          onClick={goToRoom}
          style={{ background: "none", border: "none" }}
          aria-label={"Bouton Macif, revenir à la page d'accueil"}
        >
          <svg class="mcf-d--none mcf-d-xl--block" width="240" height="120" viewBox="0 0 183 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_2)">
              <path d="M154.453 37.3316H113.419V44.4173H154.453V37.3316Z" fill="#D3D62E"></path>
              <path d="M74.4223 15.4981H76.3872V24.5628H81.9276V26.2508H74.4223V15.4981Z" fill="#0A2D82"></path>
              <path d="M89.1984 18.8213C89.829 19.3981 90.1418 20.2712 90.1418 21.4407V26.2508H88.354V25.2507C88.1195 25.6105 87.7911 25.8857 87.3637 26.0709C86.9364 26.2614 86.4204 26.3567 85.8106 26.3567C85.206 26.3567 84.6743 26.2508 84.2209 26.0392C83.7674 25.8275 83.4182 25.5364 83.1681 25.166C82.9231 24.7903 82.798 24.3723 82.798 23.896C82.798 23.1605 83.069 22.5678 83.6059 22.1233C84.1479 21.6788 84.9975 21.4565 86.1546 21.4565H88.2446V21.3348C88.2446 20.7633 88.0778 20.3188 87.739 20.0119C87.4002 19.705 86.8999 19.5515 86.2327 19.5515C85.7793 19.5515 85.3311 19.6256 84.8932 19.7685C84.4554 19.9114 84.0854 20.1124 83.7831 20.3664L83.043 18.9694C83.4651 18.6414 83.9759 18.3926 84.5701 18.218C85.1643 18.0434 85.7949 17.9587 86.4621 17.9587C87.6608 17.9534 88.5677 18.2445 89.1984 18.8213ZM87.4732 24.6316C87.8433 24.4093 88.0987 24.0971 88.255 23.6843V22.7318H86.3005C85.2112 22.7318 84.6639 23.097 84.6639 23.8219C84.6639 24.1712 84.7994 24.4463 85.0705 24.6527C85.3415 24.8591 85.722 24.9596 86.2067 24.9596C86.6862 24.9596 87.1084 24.8485 87.4732 24.6316Z" fill="#0A2D82"></path>
              <path d="M106.653 26.2508L106.638 19.1229L103.156 25.0232H102.281L98.799 19.2129V26.2508H96.9226V15.4981H98.5436L102.75 22.626L106.883 15.4981H108.504L108.519 26.2508H106.653V26.2508Z" fill="#0A2D82"></path>
              <path d="M117.015 18.8213C117.646 19.3981 117.958 20.2712 117.958 21.4407V26.2508H116.171V25.2507C115.936 25.6105 115.608 25.8857 115.18 26.0709C114.753 26.2614 114.237 26.3567 113.627 26.3567C113.023 26.3567 112.491 26.2508 112.038 26.0392C111.584 25.8275 111.235 25.5364 110.985 25.166C110.74 24.7903 110.615 24.3723 110.615 23.896C110.615 23.1605 110.886 22.5678 111.422 22.1233C111.965 21.6788 112.814 21.4565 113.971 21.4565H116.061V21.3348C116.061 20.7633 115.894 20.3188 115.556 20.0119C115.217 19.705 114.716 19.5515 114.049 19.5515C113.596 19.5515 113.148 19.6256 112.71 19.7685C112.272 19.9114 111.902 20.1124 111.6 20.3664L110.86 18.9694C111.282 18.6414 111.793 18.3926 112.387 18.218C112.981 18.0434 113.612 17.9587 114.279 17.9587C115.472 17.9534 116.384 18.2445 117.015 18.8213ZM115.29 24.6316C115.66 24.4093 115.915 24.0971 116.072 23.6843V22.7318H114.117C113.028 22.7318 112.481 23.097 112.481 23.8219C112.481 24.1712 112.616 24.4463 112.887 24.6527C113.158 24.8591 113.539 24.9596 114.023 24.9596C114.498 24.9596 114.92 24.8485 115.29 24.6316Z" fill="#0A2D82"></path>
              <path d="M121.909 25.8222C121.242 25.4624 120.726 24.9649 120.351 24.3247C119.975 23.6844 119.793 22.9594 119.793 22.1498C119.793 21.3401 119.981 20.6205 120.351 19.9855C120.726 19.3505 121.242 18.853 121.904 18.4932C122.566 18.1334 123.316 17.9534 124.166 17.9534C124.963 17.9534 125.662 18.1175 126.261 18.4456C126.861 18.7737 127.314 19.2446 127.616 19.8585L126.162 20.721C125.928 20.34 125.641 20.0595 125.292 19.869C124.942 19.6785 124.562 19.5833 124.15 19.5833C123.441 19.5833 122.858 19.8161 122.394 20.2818C121.93 20.7475 121.695 21.3719 121.695 22.1498C121.695 22.9276 121.925 23.5521 122.383 24.0177C122.842 24.4834 123.431 24.7162 124.145 24.7162C124.557 24.7162 124.937 24.621 125.286 24.4305C125.636 24.24 125.922 23.9542 126.157 23.5785L127.611 24.4411C127.298 25.0549 126.84 25.5312 126.24 25.8645C125.641 26.1979 124.948 26.362 124.161 26.362C123.327 26.3567 122.576 26.182 121.909 25.8222Z" fill="#0A2D82"></path>
              <path d="M129.341 16.3659C129.107 16.1437 128.992 15.8738 128.992 15.5457C128.992 15.2176 129.107 14.9425 129.341 14.7255C129.576 14.5032 129.863 14.3974 130.207 14.3974C130.551 14.3974 130.837 14.5032 131.067 14.7149C131.301 14.9266 131.416 15.1912 131.416 15.5087C131.416 15.8473 131.301 16.1331 131.077 16.3606C130.848 16.5935 130.561 16.7046 130.207 16.7046C129.857 16.694 129.571 16.5829 129.341 16.3659ZM129.248 18.0487H131.14V26.2508H129.248V18.0487Z" fill="#0A2D82"></path>
              <path d="M135.711 17.6148V18.1069H137.936V19.6415H135.773V26.2508H133.881V19.6468H132.547V18.1122H133.881V17.5883C133.881 16.7099 134.131 16.0114 134.637 15.5087C135.142 15.0007 135.851 14.7467 136.769 14.7467C137.493 14.7467 138.066 14.8948 138.478 15.1912L137.947 16.6358C137.623 16.4083 137.274 16.2971 136.904 16.2971C136.112 16.2971 135.711 16.7363 135.711 17.6148Z" fill="#0A2D82"></path>
              <path d="M140.553 24.1606C140.787 24.3987 140.902 24.7056 140.902 25.0814C140.902 25.2454 140.881 25.4147 140.834 25.5947C140.787 25.7746 140.693 26.0445 140.553 26.399L139.781 28.5633H138.556L139.161 26.2138C138.937 26.1238 138.765 25.9757 138.64 25.7746C138.515 25.5735 138.452 25.3407 138.452 25.0761C138.452 24.6951 138.567 24.3881 138.801 24.1553C139.036 23.9172 139.328 23.8008 139.677 23.8008C140.026 23.8061 140.323 23.9278 140.553 24.1606Z" fill="#0A2D82"></path>
              <path d="M76.5436 41.0041C75.8765 40.6443 75.3605 40.1468 74.9852 39.5065C74.6099 38.8663 74.4275 38.1413 74.4275 37.3317C74.4275 36.522 74.6151 35.8024 74.9852 35.1674C75.3605 34.5324 75.8765 34.0349 76.5384 33.6751C77.2003 33.3153 77.9508 33.1353 78.8004 33.1353C79.5978 33.1353 80.2963 33.2994 80.8956 33.6275C81.495 33.9556 81.9485 34.4265 82.2508 35.0404L80.7966 35.9029C80.5621 35.5219 80.2754 35.2414 79.9262 35.0509C79.577 34.8604 79.1965 34.7652 78.7848 34.7652C78.0759 34.7652 77.4922 34.998 77.0283 35.4637C76.5644 35.9294 76.3299 36.5538 76.3299 37.3317C76.3299 38.1095 76.5592 38.734 77.0179 39.1996C77.4765 39.6653 78.0655 39.8981 78.7795 39.8981C79.1913 39.8981 79.5718 39.8029 79.921 39.6124C80.2702 39.4219 80.5568 39.1361 80.7914 38.7604L82.2455 39.623C81.9328 40.2368 81.4742 40.713 80.8748 41.0464C80.2754 41.3798 79.5822 41.5438 78.7952 41.5438C77.9613 41.5386 77.2107 41.3586 76.5436 41.0041Z" fill="#0A2D82"></path>
              <path d="M85.7585 30.2778C85.993 30.516 86.1077 30.8176 86.1077 31.1827C86.1077 31.3679 86.0868 31.5478 86.0399 31.7225C85.993 31.8971 85.9044 32.1564 85.7741 32.5056L84.9871 34.6858H83.7623L84.3668 32.3204C84.1427 32.241 83.9707 32.0982 83.8456 31.8971C83.7205 31.696 83.658 31.4579 83.658 31.1827C83.658 30.8123 83.7727 30.5107 84.0072 30.2778C84.2418 30.0397 84.5336 29.9233 84.8828 29.9233C85.232 29.9233 85.5239 30.0397 85.7585 30.2778Z" fill="#0A2D82"></path>
              <path d="M95.1401 37.9455H88.7971C88.9065 38.5487 89.1984 39.0303 89.6675 39.3795C90.1366 39.7341 90.7203 39.9087 91.4135 39.9087C92.2996 39.9087 93.0345 39.6124 93.6078 39.0197L94.6241 40.205C94.2593 40.6443 93.8006 40.9776 93.2481 41.2052C92.6957 41.4327 92.0702 41.5438 91.3718 41.5438C90.4858 41.5438 89.704 41.3639 89.0264 41.0041C88.3488 40.6443 87.8276 40.1468 87.4576 39.5065C87.0875 38.8663 86.9051 38.1413 86.9051 37.3317C86.9051 36.5326 87.0823 35.8129 87.4419 35.1727C87.8016 34.5324 88.2967 34.0349 88.9326 33.6751C89.5685 33.3153 90.2825 33.1353 91.0799 33.1353C91.867 33.1353 92.5706 33.31 93.1908 33.6645C93.811 34.0191 94.2958 34.5165 94.645 35.1568C94.9942 35.7971 95.1662 36.5379 95.1662 37.3793C95.1714 37.5116 95.161 37.6968 95.1401 37.9455ZM89.5476 35.215C89.1306 35.5801 88.8752 36.0617 88.7814 36.6649H93.368C93.2846 36.0722 93.0397 35.5907 92.6279 35.2203C92.2162 34.8499 91.7002 34.6646 91.0851 34.6646C90.4806 34.6699 89.9698 34.8551 89.5476 35.215Z" fill="#0A2D82"></path>
              <path d="M97.5637 41.2793C96.9383 41.1046 96.4379 40.8877 96.0678 40.6178L96.7923 39.1573C97.1572 39.4007 97.5898 39.6018 98.1005 39.75C98.6113 39.8981 99.1117 39.9722 99.6068 39.9722C100.738 39.9722 101.301 39.6706 101.301 39.0673C101.301 38.7816 101.155 38.5805 100.868 38.4694C100.581 38.3582 100.118 38.2471 99.4817 38.1466C98.8146 38.046 98.2725 37.9243 97.8556 37.792C97.4386 37.6597 97.0738 37.4269 96.7663 37.0935C96.4587 36.7602 96.3024 36.2998 96.3024 35.7018C96.3024 34.9239 96.6203 34.2995 97.2614 33.8338C97.9025 33.3682 98.7677 33.1353 99.857 33.1353C100.409 33.1353 100.967 33.1988 101.52 33.3258C102.072 33.4528 102.531 33.6275 102.88 33.8391L102.155 35.2997C101.467 34.8922 100.696 34.6858 99.8413 34.6858C99.2889 34.6858 98.8667 34.7705 98.58 34.9398C98.2934 35.1091 98.1474 35.3314 98.1474 35.6066C98.1474 35.9135 98.2986 36.1304 98.6113 36.2574C98.9188 36.3844 99.3931 36.5062 100.039 36.6173C100.686 36.7178 101.212 36.8395 101.629 36.9718C102.041 37.1041 102.4 37.3317 102.698 37.6492C102.995 37.9667 103.146 38.4165 103.146 39.0038C103.146 39.7711 102.817 40.385 102.161 40.8453C101.504 41.3057 100.613 41.5386 99.4817 41.5386C98.8302 41.5386 98.1891 41.4539 97.5637 41.2793Z" fill="#0A2D82"></path>
              <path d="M109.64 40.9882C109.416 41.1734 109.15 41.311 108.832 41.401C108.514 41.4909 108.181 41.5386 107.826 41.5386C106.94 41.5386 106.252 41.3004 105.767 40.8295C105.283 40.3585 105.043 39.6706 105.043 38.771V34.8234H103.709V33.2888H105.043V31.4155H106.935V33.2888H109.098V34.8234H106.935V38.7287C106.935 39.1255 107.029 39.4325 107.222 39.6441C107.414 39.8558 107.691 39.9616 108.056 39.9616C108.478 39.9616 108.832 39.8505 109.114 39.623L109.64 40.9882Z" fill="#0A2D82"></path>
              <path d="M122.941 33.2253L119.475 41.4274H117.521L114.055 33.2253H116.019L118.532 39.3108L121.122 33.2253H122.941V33.2253Z" fill="#0A2D82"></path>
              <path d="M125.318 41.0041C124.661 40.6443 124.15 40.1468 123.78 39.5065C123.41 38.8663 123.228 38.1413 123.228 37.3317C123.228 36.522 123.41 35.8024 123.78 35.1674C124.15 34.5324 124.661 34.0349 125.318 33.6751C125.974 33.3153 126.709 33.1353 127.528 33.1353C128.356 33.1353 129.096 33.3153 129.753 33.6751C130.41 34.0349 130.921 34.5324 131.291 35.1674C131.661 35.8024 131.843 36.5273 131.843 37.3317C131.843 38.1413 131.661 38.8663 131.291 39.5065C130.921 40.1468 130.41 40.6443 129.753 41.0041C129.096 41.3639 128.356 41.5438 127.528 41.5438C126.709 41.5386 125.974 41.3586 125.318 41.0041ZM129.253 39.189C129.706 38.7181 129.936 38.099 129.936 37.3317C129.936 36.5644 129.706 35.9452 129.253 35.4743C128.799 35.0033 128.226 34.7652 127.528 34.7652C126.829 34.7652 126.261 35.0033 125.808 35.4743C125.359 35.9452 125.135 36.5644 125.135 37.3317C125.135 38.099 125.359 38.7181 125.808 39.189C126.256 39.66 126.829 39.8981 127.528 39.8981C128.221 39.8981 128.799 39.66 129.253 39.189Z" fill="#0A2D82"></path>
              <path d="M141.48 33.2253V41.4274H139.682V40.385C139.38 40.7554 138.999 41.0359 138.546 41.2369C138.092 41.438 137.603 41.5386 137.076 41.5386C135.997 41.5386 135.148 41.2317 134.527 40.6231C133.907 40.0146 133.594 39.1097 133.594 37.9138V33.2253H135.486V37.6492C135.486 38.3847 135.648 38.9351 135.976 39.3002C136.305 39.6653 136.769 39.8452 137.378 39.8452C138.056 39.8452 138.593 39.6336 138.989 39.2102C139.385 38.7869 139.588 38.173 139.588 37.374V33.2253H141.48V33.2253Z" fill="#0A2D82"></path>
              <path d="M144.535 41.2793C143.909 41.1046 143.409 40.8877 143.039 40.6178L143.763 39.1573C144.128 39.4007 144.561 39.6018 145.071 39.75C145.582 39.8981 146.082 39.9722 146.578 39.9722C147.709 39.9722 148.272 39.6706 148.272 39.0673C148.272 38.7816 148.126 38.5805 147.839 38.4694C147.552 38.3582 147.088 38.2471 146.453 38.1466C145.785 38.046 145.243 37.9243 144.826 37.792C144.409 37.6597 144.045 37.4269 143.737 37.0935C143.43 36.7602 143.273 36.2998 143.273 35.7018C143.273 34.9239 143.591 34.2995 144.232 33.8338C144.873 33.3682 145.739 33.1353 146.828 33.1353C147.38 33.1353 147.938 33.1988 148.49 33.3258C149.043 33.4528 149.502 33.6275 149.851 33.8391L149.126 35.2997C148.438 34.8922 147.667 34.6858 146.812 34.6858C146.26 34.6858 145.838 34.7705 145.551 34.9398C145.264 35.1091 145.118 35.3314 145.118 35.6066C145.118 35.9135 145.269 36.1304 145.582 36.2574C145.89 36.3844 146.364 36.5062 147.01 36.6173C147.657 36.7178 148.183 36.8395 148.6 36.9718C149.012 37.1041 149.371 37.3317 149.668 37.6492C149.965 37.9667 150.117 38.4165 150.117 39.0038C150.117 39.7711 149.788 40.385 149.132 40.8453C148.475 41.3057 147.584 41.5386 146.453 41.5386C145.801 41.5386 145.16 41.4539 144.535 41.2793Z" fill="#0A2D82"></path>
              <path d="M151.482 41.1787C151.242 40.9406 151.117 40.6337 151.117 40.2633C151.117 39.8822 151.237 39.5753 151.472 39.3425C151.706 39.1044 152.003 38.9879 152.358 38.9879C152.712 38.9879 153.004 39.1044 153.244 39.3425C153.478 39.5806 153.598 39.8875 153.598 40.2633C153.598 40.6337 153.478 40.9353 153.233 41.1787C152.994 41.4221 152.697 41.5386 152.358 41.5386C152.019 41.5386 151.722 41.4221 151.482 41.1787Z" fill="#0A2D82"></path>
              <path d="M59.6462 3.95687V56.0537C50.2228 58.636 40.32 60.0066 30.094 60.0066C19.868 60.0066 9.96513 58.636 0.546997 56.0537V3.95687C9.96513 1.37981 19.868 0.00396718 30.094 0.00396718C40.32 -0.00132453 50.2228 1.37981 59.6462 3.95687Z" fill="#0A2D82"></path>
              <path d="M36.239 21.356C36.239 24.6845 33.5808 27.3833 30.3077 27.3833C27.0345 27.3833 24.3764 24.6845 24.3764 21.356C24.3764 18.0275 27.0345 15.3288 30.3077 15.3288C33.5808 15.3288 36.239 18.0275 36.239 21.356Z" fill="#D3D62E"></path>
              <path d="M43.5254 21.356H39.8874C39.5747 21.4089 39.3089 21.6471 39.2359 21.9593C38.9284 26.7006 35.0454 30.4577 30.2973 30.4577C25.351 30.4577 21.3326 26.3884 21.3326 21.356C21.3326 16.5935 24.9341 12.6882 29.5259 12.2913V12.286C29.9481 12.286 30.292 11.9103 30.292 11.4764V8.06324C30.292 7.62932 29.9481 7.28006 29.5259 7.28006V7.27477C22.2186 7.67694 16.4124 13.8312 16.4124 21.356C16.4124 29.1454 22.6304 35.4637 30.3025 35.4637C37.7244 35.4637 43.786 29.5476 44.1717 22.1074C44.156 21.7264 43.885 21.4142 43.5254 21.356Z" fill="#D3D62E"></path>
              <path d="M15.6984 47.7193C15.6984 47.1425 15.6984 46.4281 15.714 45.7931H15.6984C15.4013 46.1476 15.0729 46.5022 14.5934 46.9467L13.1601 48.3702H13.035L11.6226 46.9467C11.1639 46.5022 10.8147 46.1476 10.5385 45.7931H10.502C10.5228 46.4281 10.5385 47.1372 10.5385 47.7193V51.3176H8.0002V40.3426H8.33377L11.1431 42.9197C11.5861 43.3272 12.519 44.2426 13.181 44.9888H13.2174C13.8429 44.2955 14.8332 43.3272 15.2762 42.9197L17.9969 40.3426H18.3252V51.3176H15.6984V47.7193V47.7193Z" fill="white"></path>
              <path d="M23.4695 47.7881H25.9087L25.7836 47.5288C25.4657 46.862 25.0852 46.0153 24.6995 44.957H24.6787C24.293 46.0206 23.9073 46.862 23.5946 47.5288L23.4695 47.7881ZM26.8834 49.8836H22.4792L21.7964 51.3176H18.9663L24.4598 40.3426H25.0175L30.5057 51.3176H27.5818L26.8834 49.8836Z" fill="white"></path>
              <path d="M39.6529 50.2328C38.6626 51.1112 37.469 51.6351 35.9784 51.6351C32.6687 51.6351 30.5057 49.1692 30.5057 45.9836C30.5057 42.5863 32.8407 40.3426 35.8533 40.3426C37.4534 40.3426 38.6626 40.9036 39.6216 41.7449L38.1674 43.6235C37.6097 43.0996 36.9009 42.7451 35.9992 42.7451C34.274 42.7451 33.2264 44.1315 33.2264 45.9783C33.2264 47.878 34.274 49.2221 36.0513 49.2221C36.9165 49.2221 37.6514 48.9258 38.2143 48.3649L39.6529 50.2328Z" fill="white"></path>
              <path d="M43.5671 40.6654H40.9402V51.3229H43.5671V40.6654Z" fill="white"></path>
              <path d="M48.3986 43.0679V45.5708H52.4015V47.8251H48.3986V51.3229H45.7718V40.6654H53.5221L53.0061 43.0679H48.3986Z" fill="white"></path>
            </g>
            <defs>
              <clipPath id="clip0_2_2">
                <rect width="285" height="60" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
        </button>





        <div className={styles.content}>
          <p className="content__text" dangerouslySetInnerHTML={{ __html: transContent }}
          ></p>
        </div>


        {showButton && (
          <button className={styles.start} ref={btnRef} onClick={inputHandler}>
            C'est parti !
          </button>
        )}

        <svg
          ref={loaderRef}
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 40 40"
          enableBackground="new 0 0 40 40"
        >
          <path
            opacity="0.2"
            fill="#0A2D82"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            fill="#000"
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
        </svg>

        <div className={styles.mentions}>
          <a href="https://www.macif.fr/assurance/particuliers/nous-contacter" target="_blank" onClick={(e) => {
            e.preventDefault();
            handleDataLayerEvent('nous_contacter');
            window.open("https://www.macif.fr/assurance/particuliers/nous-contacter", "_blank");
          }}>Nous contacter</a> ｜
          <span onClick={toggleMentionsLegales}>Mentions légales</span> ｜
          <a href="https://www.macif.fr/assurance/particuliers/cookies" target="_blank" onClick={(e) => {
            e.preventDefault();
            handleDataLayerEvent('cookies');
            window.open("https://www.macif.fr/assurance/particuliers/cookies", "_blank");
          }}>Gestion des Cookies</a>
        </div>
        {showMentionsLegales && <MentionsLegales closeFn={closeMentionsLegales} />}

      </div>
      <Canvas flat={false}>
        <Suspense
          fallback={<Loader onGlobalLoadedEnd={onGlobalLoadedEnd}></Loader>}
        >
          <Scenes visibleRoom={visibleRoom}></Scenes>
        </Suspense>
      </Canvas>
    </>
  );
}

export default SceneManager;

import * as THREE from "three"
import React, { useRef, useEffect } from "react";
import { LayerMaterial, Depth } from "lamina";


export default function Glow ({ color, scale, near = -2, far = 1, rotation, position }) {
    return (
    <mesh rotation={rotation} position={position}>
      <circleGeometry args={[scale, 16]} 
/>
      <LayerMaterial
        transparent
        depthWrite={false}
        blending={THREE.CustomBlending}
        blendEquation={THREE.AddEquation}
        blendSrc={THREE.SrcAlphaFactor}
        blendDst={THREE.DstAlphaFactor}>
        <Depth colorA={color} colorB="black" alpha={1} mode="normal" near={near} far={far} origin={[0, 0, 0]} />
        <Depth colorA={color} colorB="black" alpha={0.1} mode="add" near={near} far={far} origin={[0, 0, 0]} />
      </LayerMaterial>
    </mesh>
    )
}
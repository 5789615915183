import { gsap } from "gsap";
import React, { useRef, useEffect } from "react";
import { useScroll } from "@react-three/drei";
import * as THREE from "three";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { easing } from "maath";
import emitter from "../emitter";

export function CameraControl({
  camPosDesk,
  camPosMob,
  camDeskCalc,
  camMobCalc,
}) {
  const pointer = useRef(null);
  const currentPos = useRef(null);
  const currentRot = useRef(null);
  const initialPos = useRef(null);
  const initialRot = useRef(null);
  const previousTouch = useRef(null);
  const infoUp = useRef(false);

  const { camera, gl } = useThree();
  const data = useScroll();
  const isMobile = window.innerWidth <= 768;

  const mouseMoveEvent = (event) => {
    if (!infoUp.current) {
      let elt = event;

      if (event.touches && event.touches.length > 0) {
        elt = event.touches[0];
      }

      pointer.current.x = (elt.clientX / window.innerWidth) * 2 - 1;
      pointer.current.y = -(elt.clientY / window.innerHeight) * 2 + 1;
    }
  };

  const firstTouchReset = () => {
    data.offset = 0.5;
    data.scroll.current = 0.5;
    data.el.scrollLeft = data.el.scrollWidth / 2;
    document.removeEventListener("touchstart", firstTouchReset);
  };

  useEffect(() => {
    pointer.current = { x: 0.5, y: 0.5 };
    if (isMobile) {
      data.offset = 0.5;
      data.scroll.current = 0.5;
      data.el.scrollLeft = data.el.scrollWidth / 2;
      document.addEventListener("touchstart", firstTouchReset);
    } else {
      document.addEventListener("mousemove", mouseMoveEvent);
    }

    if (isMobile) {
      currentPos.current = new THREE.Vector3(...camPosMob);
    } else {
      currentPos.current = new THREE.Vector3(...camPosDesk);
    }
    currentRot.current = new THREE.Vector3();
    initialPos.current = currentPos.current.clone();
    initialRot.current = currentRot.current.clone();

    const setInfoState = (infoState) => {
      infoUp.current = infoState;
    };

    emitter.on("setInfoState", setInfoState);

    //TODO plug animate out on scroll
    return () => {
      document.removeEventListener("mousemove", mouseMoveEvent);
      document.removeEventListener("touchstart", firstTouchReset);
    };
  }, []);

  // const zoomTo = (e) => {
  //   let objPos = new THREE.Vector3();
  //   e.object.getWorldPosition(objPos);
  //   const dir = objPos.sub(currentPos.current);
  //   let dist = objPos.distanceTo(currentPos.current);
  //   dist = (dist - 5) / dist;
  //   objPos = currentPos.current.add(dir.multiplyScalar(dist));
  
  //   let clone = new THREE.Object3D();
  //   clone.position.set(...camera.position.toArray());
  //   clone.lookAt(...e.object.position.toArray());
  
  //   gsap.to(currentPos.current, {
  //     x: objPos.x,
  //     y: objPos.y,
  //     z: objPos.z,
  //     duration: 1,
  //     ease: "back.in(6)",
  //   });
  //   // La rotation peut nécessiter des ajustements pour s'aligner correctement
  //   gsap.to(currentRot.current, {
  //     x: clone.rotation.x,
  //     y: clone.rotation.y,
  //     z: clone.rotation.z,
  //     duration: 1,
  //     ease: "back.in(6)",
  //   });
  // };
  


  useFrame((state, delta) => {
    if (pointer && pointer.current) {
      if (isMobile) {
        const camMob = camMobCalc(data, currentPos, currentRot);
        easing.damp3(state.camera.position, camMob.position, 0.15, delta);
        easing.damp3(state.camera.rotation, camMob.rotation, 0.15, delta);
      } else {
        const camDesk = camDeskCalc(pointer, currentPos, currentRot);
        // Logique pour les non-mobiles (comme les ordinateurs de bureau)
        easing.damp3(state.camera.position, camDesk.position, 0.5, delta);
        easing.damp3(state.camera.rotation, camDesk.rotation, 0.5, delta);
      }
    }
    // Autres logiques communes
  });

  return <></>;
}

import { Hall } from "./rooms/Hall";
import { ScrollControls, useGLTF, Environment } from "@react-three/drei";
import Maison from "./rooms/Maison";
import { Garage } from "./rooms/Garage";
import { Chambre } from "./rooms/Chambre";
import { Bureau } from "./rooms/Bureau";
import { Salon } from "./rooms/Salon";
import { Cuisine } from "./rooms/Cuisine";
import React, { useEffect } from "react";
import { useExperienceStore } from "./store";
import { useLoader } from "@react-three/fiber";
import {EquirectangularReflectionMapping, TextureLoader } from "three";
import { useThree } from '@react-three/fiber';
import * as THREE from "three"



export function Scenes({ visibleRoom }) {
  useGLTF.preload(process.env.PUBLIC_URL + "/models/maison.glb");
  useGLTF.preload(process.env.PUBLIC_URL + "/models/bureau.glb");
  useGLTF.preload(process.env.PUBLIC_URL + "/models/hall.glb");
  useGLTF.preload(process.env.PUBLIC_URL + "/models/chambre.glb");
  useGLTF.preload(process.env.PUBLIC_URL + "/models/salon.glb");
  useGLTF.preload(process.env.PUBLIC_URL + "/models/cuisine.glb");
  useGLTF.preload(process.env.PUBLIC_URL + "/models/garage.glb");

  const experienceStarted = useExperienceStore(
    (state) => state.experienceStarted,
  );
  const { scene } = useThree();
  const texture = useLoader(TextureLoader,  process.env.PUBLIC_URL + "/sky.jpg",
  
  );

  useEffect(() => {
    texture.mapping = EquirectangularReflectionMapping;

    scene.background = texture;
  }, [texture, scene]);

  return (
    <>
      {/* <Environment files="DaySkyHDRI027B_1K-HDR.exr" background={true} /> */}
      {/* <color attach="background" args={["#5598b7"]} /> */}
      {/* <mesh geometry={new THREE.SphereGeometry(100, 60, 40)} material={new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide })} /> */}


      <ambientLight intensity={1} />
      {experienceStarted && visibleRoom === "hall" && <Hall />}

      {visibleRoom === null && (
        <ScrollControls pages={3} damping={0.1}>
          <Maison />
        </ScrollControls>
      )}

      {/* <Scene /> */}
      {experienceStarted && visibleRoom === "garage" && <Garage />}
      {experienceStarted && visibleRoom === "chambre" && <Chambre />}
      {experienceStarted && visibleRoom === "bureau" && <Bureau />}
      {experienceStarted && visibleRoom === "salon" && <Salon />}
      {experienceStarted && visibleRoom === "cuisine" && <Cuisine />}
    </>
  );
}

import React from "react";
import styles from "./ScenarioSelector.module.scss";
import { useExperienceStore } from "../../store";
import emitter from "../../emitter.js";


function ScenarioSelector() {

  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const setCurrentRoom = useExperienceStore((state) => state.setCurrentRoom);
  const currentRoom = useExperienceStore((state) => state.currentRoom); 

  const goToRoom= () => {
    emitter.emit("sound:transition", "hall");
    emitter.emit(
      "buttonClickEvent",
      "redirection",
      "hall",
     
    );
    setCurrentRoom("hall");
  };

  return (
    <>
     {activeScenario && currentRoom !== "hall" && (
        <button
          className={styles.scenarioButton}
          aria-label="Bouton accès au hall"
          onClick={goToRoom}
        >
         <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 27 25" fill="none">
        <path d="M4.09091 10.9244L13.1818 1L25 15.8866H20.9091V23.8261H15V18.8639C13.2252 16.9761 12.2299 16.9103 10.4545 18.8639V23.8261H4.09091V15.8866H0" stroke="#CBE300"/>
        </svg>
        </button>
      )} 
    </>
  );
}

export default ScenarioSelector;

import React, { useCallback, useRef, useEffect, useState } from "react";
import styles from "./InfoBox.module.scss";
import { gsap } from "gsap";
import emitter from "../../emitter.js";

function InfoBox() {
  const wrapperRef = useRef();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    gsap.set(wrapperRef.current, {
      y: 40,
    });
  }, [wrapperRef]);

  const animateOut = useCallback(() => {
    gsap.killTweensOf(wrapperRef.current);
    gsap
      .to(wrapperRef.current, {
        autoAlpha: 0,
        y: 40,
        ease: "expo.out",
        duration: 0.5,
      })
      .then(() => {
        setInfo(null);
      });
  }, []);

  const animateIn = useCallback(() => {
    gsap.killTweensOf(wrapperRef.current);
    gsap.to(wrapperRef.current, {
      autoAlpha: 1,
      y: 0,
      ease: "expo.out",
      duration: 1,
    });
  }, []);

  const onOpen = useCallback(
    (info) => {
      setInfo(info);
      animateIn();
    },
    [setInfo, animateIn],
  );

  useEffect(() => {
    emitter.on("info:open", onOpen);
    emitter.on("info:close", animateOut);
    
    return () => {
      // Retirer les abonnements aux émetteurs ici
      emitter.off("info:open");
      emitter.off("info:close");
    };
  }, [animateOut, onOpen]);

  const hasContent = info && (info.title || info.text);


  return (
    <>
        <div ref={wrapperRef} className={styles.infoBox}>
        {info && (
          <>
            <p className={styles.title} aria-live={"polite"}>
              {info.title}
            </p>
            <p className={styles.text} aria-live={"polite"}>
              {info.text}
            </p>
            <div>

            </div>
          </>
        )}
      </div>
     
    </>
  );
}

export default InfoBox;

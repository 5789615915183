import { objects } from "./objects";

function getObjectsForScenario(scenario) {
  return objects.filter((obj) => obj.scenario.includes(scenario));
}
export const scenarios = (() => {
  return [
    {
      id: "engage-cause",
      title: "Je m'engage pour une cause !",
      text: "Bienvenue dans la quête “Je m'engage pour une cause” ! Que vous souhaitiez adopter une consommation plus responsable ou vous investir dans une association nous vous accompagnons dans vos engagements. Commencez par inspecter la cuisine ou le garage. ",
      image: process.env.PUBLIC_URL + "/images/scenario-engage-cause.webp",
      roomLinks: [
        {
          label: "Aller dans le garage",
          id: "garage",
        },
        {
          label: "Aller dans la cuisine",
          id: "cuisine",
        },
      ],
      objects: getObjectsForScenario("engage-cause"),
    },
    {
      id: "soin-proches",
      title: "Je prends soin de mes proches",
      text: "Bienvenue dans la quête “Je prends soin de mes proches”. Famille ou amis, nous vous aidons à en prendre soin !<br/>Inspectez la cuisine ou la chambre à la recherche d’indices.",
      image: process.env.PUBLIC_URL + "/images/scenario-soin-proches.webp",
      roomLinks: [
        {
          label: "Aller dans la cuisine",
          id: "cuisine",
        },
        {
          label: "Aller dans la chambre",
          id: "chambre",
        },
      ],
      objects: getObjectsForScenario("soin-proches"),
    },
    {
      id: "change-cap",
      title: "Je change de cap !",
      text: "Bienvenue dans la quête “Je change de cap” !<br/>À court ou moyen terme nous avons tous des envies de changement, qu'il soit personnel ou professionnel nous sommes à vos côtés pour vous aider à les concrétiser.<br/>Découvrez comment, en menant l’enquête dans la chambre et le salon.",
      image: process.env.PUBLIC_URL + "/images/scenario-change-cap.webp",
      roomLinks: [
        {
          label: "Aller dans la chambre",
          id: "chambre",
        },
        {
          label: "Aller dans le salon",
          id: "salon",
        },
      ],
      objects: getObjectsForScenario("change-cap"),
    },
    {
      id: "soin-maison",
      title: "Je prends soin de mon chez moi",
      text: "Bienvenue dans la quête “Je prends soin de mon chez moi” !<br/>Qui n’a jamais eu des envies de rénovation, de nouvelle déco ou simplement de changement ? <br/>Nous sommes là pour vous aider : commencez par inspecter le garage ou le salon et ouvrez l’oeil !",
      image: process.env.PUBLIC_URL + "/images/scenario-soin-maison.webp",
      roomLinks: [
        {
          label: "Aller dans le garage",
          id: "garage",
        },
        {
          label: "Aller dans le salon",
          id: "salon",
        },
      ],
      objects: getObjectsForScenario("soin-maison"),
    },
    {
      id: "change-horizon",
      title: "J'ai envie d'évasion",
      text: "Bienvenue dans la quête “J'ai envie d'évasion” ! <br/>Partir c’est toujours excitant, et pour le faire dans les meilleures conditions, nous vous proposons bons plans et conseils ! <br/>Rendez-vous dans le bureau ou la cuisine pour commencer à enquêter.",
      image: process.env.PUBLIC_URL + "/images/scenario-change-horizon.webp",
      roomLinks: [
        {
          label: "Aller dans le bureau",
          id: "bureau",
        },
        {
          label: "Aller dans la cuisine",
          id: "cuisine",
        },
      ],
      objects: getObjectsForScenario("change-horizon"),
    },
  ];
})();

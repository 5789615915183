import { TextureLoader } from "three";
import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  ScrollControls,
  Outlines, Environment, Lightformer
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import emitter from "../emitter.js";
import { useExperienceStore } from "../store/index.js";
import { CameraControl } from "../components/CameraControl";
import Glow from "../components/Glow.js";
import useInactivityTimer from "../components/useInactivityTimer.js";


const camMobCalc = (data, currentPos, currentRot) => {
  const pos = [
    Math.sin(((data.offset - 0.5) * 0.75) / 1) * 1 + currentPos.current.x,
    Math.sin(data.offset * 2) * 0.25 + currentPos.current.y,
    currentPos.current.z,
  ];
  const rot = [
    currentRot.current.x,
    Math.sin((data.offset - 0.5) * 0.5 * -2) + currentRot.current.y,
    currentRot.current.z,
  ];
  return { rotation: rot, position: pos };
};

const camDeskCalc = (pointer, currentPos, currentRot) => {
  const pos = [
    Math.sin(pointer.current.x / 1) * -0.2,
    pointer.current.y * 0.2 + currentPos.current.y,
    currentPos.current.z,
  ];
  const rot = [
    currentRot.current.x,
    Math.sin(pointer.current.x / -2) + currentRot.current.y,
    currentRot.current.z,
  ];
  return { rotation: rot, position: pos };
};

export function Salon(props) {
  const objectsInRoom = useExperienceStore((state) => state.objectsInRoom());
  const setObjectFound = useExperienceStore((state) => state.setObjectFound);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const objectsFound = useRef([]);
  const [colorsItems, setColorsItems] = useState(null);

  useInactivityTimer("parcourir");

  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/textures/salon/murs.webp",
    process.env.PUBLIC_URL + "/textures/salon/tapis.webp",
    process.env.PUBLIC_URL + "/textures/salon/livres.webp",
    process.env.PUBLIC_URL + "/textures/salon/joboard.webp",
    process.env.PUBLIC_URL + "/textures/salon/rangement.webp",
    process.env.PUBLIC_URL + "/textures/salon/arbre.webp",
    process.env.PUBLIC_URL + "/textures/salon/telephone.webp",
    process.env.PUBLIC_URL + "/textures/salon/etagere.webp",
    process.env.PUBLIC_URL + "/textures/salon/sofa.webp",
    process.env.PUBLIC_URL + "/textures/salon/cv.webp",
    process.env.PUBLIC_URL + "/textures/salon/arbrebase.webp",
    process.env.PUBLIC_URL + "/textures/salon/arbrealpha.webp",
  ], []);
  // Initialisation des textures
  const [
    murssalon,
    tapis,
    livres,
    jobboard,
    rangement,
    arbre,
    telephone,
    etagere,
    sofa,
    cv,
    arbrebase,
    arbrealpha,
  ] = useLoader(TextureLoader, texturePaths);


  // Set initial camera position

  useEffect(() => {
    // Émission des événements
    emitter.emit("info:open", {
      title: "Restez en alerte !",
      text: "On ne sait jamais ce que peut cacher un objet !",
    });

    emitter.emit("pageViewEvent", "salon", activeScenario.id);

    const items = {
      cv: "#D3D62E",
      telephone: "#D3D62E",
      tablet: "#D3D62E",
      rangement: "#D3D62E",
    };

    for (const [key] of Object.entries(items)) {
      const obj = activeScenario.objects.find((obj) => {
        let tmpKey = key;
        return obj.id === tmpKey;
      });
      if (obj && obj.hasOwnProperty("found")) {
        items[key] = "#FF0000";
      }
    }

    setColorsItems(items);

    const timeoutId = setTimeout(() => {
      emitter.emit("info:close");
    }, 4000);

    // Fonction de nettoyage
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Détection de l'appareil mobile basée sur la largeur de l'écran
  const isMobile = window.innerWidth <= 768;

  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "/models/salon.glb");

  useEffect(() => {
    emitter.emit("scene:loaded");
  }, [nodes]);



  const selectObject = useCallback(
    (id) => {
      const matchingObject = objectsInRoom.find((obj) => obj.id === id);
      if (matchingObject) {
        if (colorsItems !== null) {
          const copy = { ...colorsItems };
          copy[id] = "#FF0000";
          setColorsItems(copy);
        }
        setObjectFound(matchingObject.id);
        emitter.emit("popup:open", {
          type: "object",
          object: matchingObject,
        });
        if (!objectsFound.current.includes(matchingObject.id)) {
          emitter.emit("findObjectEvent", matchingObject.id, activeScenario.id, 'salon');
          objectsFound.current.push(matchingObject.id);
        }
      }
    },
    [setObjectFound, objectsInRoom, colorsItems, activeScenario.id],
  );

  const pointerEvents = (pointing) => {
    document.getElementsByTagName("body")[0].style.cursor = pointing
      ? "pointer"
      : "default";
  };

  const onPointerEnter = useCallback((id) => {
    const matchingObject = objectsInRoom.find((obj) => obj.id === id);
    if (matchingObject) {
      emitter.emit("sound:hover");
      pointerEvents(true);
    }
  }, [objectsInRoom]);



  const content = () => (
    <>
      <ambientLight intensity={2} />
      <mesh
        geometry={nodes.assise.geometry}
        material-map={sofa}
        material-map-flipY={false}
        position={[-0.309, -0.161, 0.102]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        geometry={nodes.sol.geometry}
        material-map={murssalon}
        material-map-flipY={false}
      />
      <mesh
        geometry={nodes.tapis.geometry}
        material-map={tapis}
        material-map-flipY={false}
        position={[0.163, 0, 0.51]}
      />

      <mesh

        geometry={nodes.tv.geometry}
        position={[0.912, 0.221, 1.602]}
        rotation={[Math.PI, -0.7, Math.PI]}
      >
        <meshStandardMaterial color="grey" metalness={1} roughness={0} envMapIntensity={1} />
      </mesh>
      <group position={[0.954, 0.35, 0.449]} rotation={[0, 0.194, 0]}>
        <mesh
          geometry={nodes.plante_1.geometry}
          material-color="#255933"
        />
        <mesh
          geometry={nodes.plante_2.geometry}
          material={materials["glass pot "]}
        />
      </group>
      <mesh
        geometry={nodes["cube-etagere-2"].geometry}
        material-map={etagere}
        material-map-flipY={false}
        position={[3.689, 1.612, -3.484]}
        rotation={[0, -0.288, 0]}
      />
      <mesh
        geometry={nodes.livres.geometry}
        material-map={livres}
        material-map-flipY={false}
        position={[3.59, 0.375, -1.767]}
      >
      </mesh>
      <mesh
        geometry={nodes.arbre.geometry}
        material-map={arbre}
        material-map-flipY={false}
        position={[-0.85, 2.462, -2.418]}
        rotation={[3.087, -0.782, 2.955]}
      />
      <mesh
        geometry={nodes.Circle003.geometry}
        material-color="#69384c"
        position={[3.556, 0.459, -0.799]}
      />

      <mesh
        geometry={nodes.tablette.geometry}
        material-color="#4f4f4f"
        position={[-0.071, 0.34, 0.389]}
        rotation={[0.473, -0.843, 0.365]}
        onClick={() => selectObject("tablet")}
        onPointerEnter={() => onPointerEnter("tablet")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "tablet") && (
          <>
            <Glow
              scale={0.24}
              near={-10}
              far={0.3}
              color={colorsItems["tablet"]}
              position={[0, 0.1, 0.1]}
              rotation={[0, 0.1, 0]}
            />
            <Outlines thickness={0.02} color={colorsItems["tablet"]} />
          </>
        )}
      </mesh>

      <mesh
        geometry={nodes.tablette001.geometry}
        material-map={jobboard}
        material-map-flipY={false}
        position={[-0.071, 0.33, 0.389]}
        rotation={[0.473, -0.843, 0.365]}
        onClick={() => selectObject("tablet")}
        onPointerEnter={() => onPointerEnter("tablet")}
        onPointerLeave={() => pointerEvents(false)}
      ></mesh>

      <mesh
        geometry={nodes.cv.geometry}
        material-map={cv}
        material-map-flipY={false}
        position={[0.339, 0.245, 0.461]}
        onClick={() => selectObject("cv")}
        onPointerEnter={() => onPointerEnter("cv")}
        onPointerLeave={() => pointerEvents(false)}
        renderOrder={10}
      >
        {objectsInRoom.find((obj) => obj.id === "cv") && (
          <>
            <Glow
              scale={0.4}
              near={-0.3}
              far={0.1}
              color={colorsItems["cv"]}
              position={[0, 0, 0.4]}
              rotation={[0, 0, 0]}
            />
            <Outlines thickness={0.02} color={colorsItems["cv"]} />
          </>
        )}
      </mesh>
      <mesh
        geometry={nodes.telephone.geometry}
        material-map={telephone}
        material-map-flipY={false}
        position={[-0.835, 0.498, -0.786]}
        rotation={[-0.41, 0.045, -1.569]}
        onClick={() => selectObject("telephone")}
        onPointerEnter={() => onPointerEnter("telephone")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "telephone") && (
          <>
            <Glow
              scale={0.14}
              near={-0.5}
              far={.14}
              color={colorsItems["telephone"]}
              position={[0.05, 0, 0.2]}
              rotation={[0, 0, 0]}
            />
            <Outlines thickness={0.009} color={colorsItems["telephone"]} />
          </>
        )}
      </mesh>
      <mesh
        geometry={nodes.rangement.geometry}
        material-map={rangement}
        material-map-flipY={false}
        position={[-2.083, 0.023, -1.571]}
        rotation={[0, 1.119, 0]}
        onClick={() => selectObject("rangement")}
        onPointerEnter={() => onPointerEnter("rangement")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "rangement") && (
          <>
            <Glow
              scale={1}
              near={-5}
              color={colorsItems["rangement"]}
              position={[0, 0, -0.1]}
              rotation={[0, 0, 0]}
            />
            <Outlines thickness={0.009} color={colorsItems["rangement"]} />
          </>
        )}
      </mesh>
      <mesh
        geometry={nodes.feuilles015.geometry}
        material={materials["Material.004"]}
        position={[-1.506, 3.627, -3.313]}
        rotation={[-0.155, 0.633, -0.149]}
        scale={[1.181, 1.072, 1.47]}
      >
        <meshBasicMaterial
          map={arbrebase}
          map-flipY={false}
          alphaMap={arbrealpha}
          alphaMap-flipY={false}
          transparent={true}
        />
      </mesh>
    </>
  );

  return (
    <group {...props} dispose={null}>
      {isMobile ? (
        <ScrollControls pages={8} damping={0.0001} horizontal={true}>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={45.292}
            position={[0, 0.75, 4]}
          />
          <CameraControl
            camPosDesk={[0, 1.25, 5]}
            camPosMob={[0, 1, 5]}
            camMobCalc={camMobCalc}
          ></CameraControl>
        </ScrollControls>
      ) : (
        <>
          <CameraControl
            camPosDesk={[0, 1, 5]}
            camPosMob={[0, 1.25, 6]}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={38.292}
            position={[0, 0.75, 3]}
          />
        </>
      )}
      {colorsItems && content()}
      <Environment blur={0.5}>
        <Lightformer
          form={"circle"}
          intensity={10}
          position={[-0.75, 0.4, -2]}
          rotation-y={Math.PI / 12}
          scale={0.5}
          color={"#bfe0dd"}
        />
        <Lightformer
          intensity={10}
          position={[3, 2.25, 6]}
          rotation-y={Math.PI * 1.01}
          scale={4}
          color={"#bfe0dd"}
        />

        <Lightformer
          position={[-5, -1, -1]}
          scale={[20, 0.5, 1]}
          color={"#d99c7f"}
        />
        <Lightformer
          intensity={.25}
          rotation-y={-Math.PI / 2}
          position={[2, 1.5, 0.25]}
          scale={5}
          color={"#d99c7f"}
        />

      </Environment>
    </group>
  );
}

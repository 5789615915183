import React, { useCallback, useState, useEffect, useRef } from "react";
import styles from "./ExperienceUi.module.scss";
import { useExperienceStore } from "../store";

import ScenarioSelector from "./ScenarioSelector";
import PopupInfo from "./PopupInfo";
import Ressources from "./Ressources";
import InfoBox from "./InfoBox";
import SoundManager from "./SoundManager";
import classNames from "classnames";
import emitter from "../emitter";
import { rooms } from "../store/data/rooms";
import TutoBox from "./TutoBox/TutoBox";
import IconBox from "./IconBox";
import MentionsLegales from "../components/MentionsLegales";

function ExperienceUi() {
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const currentRoom = useExperienceStore((state) => state.currentRoom);
  const totalObjectsFound = useExperienceStore((state) =>
    state.totalObjectsFound(),
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const buttonRef = useRef(null);


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const buttonMentionsRef = useRef(null);


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const [showMentionsLegales, setShowMentionsLegales] = useState(false);

  const toggleMentionsLegales = () => {
    setShowMentionsLegales(!showMentionsLegales);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: 'Mentions_Legales',
    });
  };

  const closeMentionsLegales = () => setShowMentionsLegales(false);


  const onClick = useCallback(() => {
    setPopupOpen(true);
  }, [setPopupOpen]);

  const onBlur = useCallback(() => {
    setPopupOpen(false);
  }, [setPopupOpen]);


  useEffect(() => {
    if (activeScenario && totalObjectsFound == activeScenario.objects.length) {
      emitter.emit("clickScenarioEvent", activeScenario.id, "fin");
    }
  }, [totalObjectsFound]);





  useEffect(() => {
    // Bind the event listener
    function handleClickOutside(event) {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target)) {
        setPopupOpen(false);
      }
      if (buttonMentionsRef.current && !buttonMentionsRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }

    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


  const handleDataLayerEvent = useCallback((clickId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: clickId,
    });
    console.log(clickId)
  }, []);


  return (
    <>
      <p aria-live={"polite"} style={{ opacity: "0", position: "absolute" }}>
        {currentRoom && rooms[currentRoom].description}
      </p>
      {activeScenario && (currentRoom !== "hall") && (
        <div className={styles.status}>
          <div className={styles.jauge}>

            <div
              tabIndex="0"
              onClick={() => onClick()}
              className={styles.iconStatusBar}
              aria-label={"Information sur l'état actuel de l'avancer des objets"}
            >
              <img
                src={activeScenario.image}
                alt={`Image de la quête : ${activeScenario.title}`}
              />
            </div>
            <button
              ref={buttonRef}
              onClick={() => onClick()}
              className={styles.statusBar}
              aria-label={`Jauge sur le nombre d'object trouver dans le scénario: ${totalObjectsFound} sur
              ${activeScenario.objects.length} objets`}
            >
              {[...activeScenario.objects]
                .sort((a, b) => (b.found === a.found ? 0 : b.found ? -1 : 1))
                .map((obj, i) => (
                  <div key={i} className={obj.found ? styles.found : ""}></div>
                ))}
            </button>
          </div>

          <button
            className={classNames(
              styles.statusWrap,
              popupOpen ? styles["is-open"] : "",
            )}

          >
            <p>
              Vous avez trouvez {totalObjectsFound}/
              {activeScenario.objects.length} objets dans la quête : <br />
              <br />
              <strong>"{activeScenario.title}"</strong>
            </p>
            <img
              src={activeScenario.image}
              alt={`Image de la quête : ${activeScenario.title}`}
            />
          </button>
        </div>
      )}
      <TutoBox></TutoBox>
      <PopupInfo />
      <Ressources />
      <InfoBox />
      <SoundManager />


      <div className={styles.buttonMentions} onClick={togglePopup} >
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18.4" cy="18.4" r="18.4" fill="#0A2D82" />
          <rect x="9" y="23" width="19" height="2" rx="1.5" fill="#CBE300" />
          <rect x="9" y="17" width="19" height="2" rx="1.5" fill="#CBE300" />
          <rect x="9" y="11" width="19" height="2" rx="1.5" fill="#CBE300" />
        </svg>
      </div>

      {isPopupOpen && (
        <div className={styles.popupMentions} ref={buttonMentionsRef}>
          {/* Contenu de la popup ici */}
          <a href="https://www.macif.fr/assurance/particuliers/nous-contacter" target="_blank" onClick={(e) => {
            e.preventDefault();
            handleDataLayerEvent('nous_contacter');
            window.open("https://www.macif.fr/assurance/particuliers/nous-contacter", "_blank");
          }}>Nous contacter</a>
          <p onClick={toggleMentionsLegales}>Mentions légales</p>
          <a href="https://www.macif.fr/assurance/particuliers/cookies" target="_blank" onClick={(e) => {
            e.preventDefault();
            handleDataLayerEvent('cookies');
            window.open("https://www.macif.fr/assurance/particuliers/cookies", "_blank");
          }}>Gestion des Cookies</a>
        </div>
      )}

      {showMentionsLegales && <MentionsLegales closeFn={closeMentionsLegales} />}
      <ScenarioSelector />
      <IconBox />
    </>
  );
}

export default ExperienceUi;

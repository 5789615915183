// all timeline are in percentage, start: 10 -> start 10% in the total scroll
export const Maison = [
  {
    type: "message",
    title: "Bienvenue ! ",
    text: "Que vous souhaitiez voyager, changer de déco ou donner un nouveau cap à votre avenir : venez découvrir des contenus inspirants en parcourant les pièces de cette maison spécialement imaginée pour vous.",
    start: 0,
    end: 6,
  },

  
];

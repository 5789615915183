import { RepeatWrapping, TextureLoader } from "three";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  ScrollControls,
  Html,
  Outlines,
  Sparkles,
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import emitter from "../emitter.js";
import { useExperienceStore } from "../store";
import { CameraControl } from "../components/CameraControl";
import styles from "./Room.module.scss";
import Glow from "../components/Glow.js";
import SunRays from "../components/SunRays.js";
import useInactivityTimer from "../components/useInactivityTimer.js";


export function Chambre(props) {
  const objectsInRoom = useExperienceStore((state) => state.objectsInRoom());
  const setObjectFound = useExperienceStore((state) => state.setObjectFound);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "/models/chambre.glb");
  const [colorsItems, setColorsItems] = useState(null);

  useInactivityTimer("parcourir");

  useEffect(() => {
    emitter.emit("info:open", {
      title: "Ouvrez l'œil",
      text: `Même l'objet le plus ordinaire peut cacher de belles histoires.`,
    });

    emitter.emit("pageViewEvent", "chambre", activeScenario.id);

    const items = {
      trousseSoin: "#D3D62E",
      berceau: "#D3D62E",
      canapeAnimal: "#D3D62E",
      blocknote: "#D3D62E",
      carton: "#D3D62E",
    };

    for (const [key, value] of Object.entries(items)) {
      const obj = activeScenario.objects.find((obj) => {
        let tmpKey = key;
        if (key === "trousseSoin") {
          tmpKey = "trousse-soin";
        } else if (key === "canapeAnimal") {
          tmpKey = "canape-animal";
        }
        return obj.id === tmpKey;
      });
      if (obj && obj.hasOwnProperty("found")) {
        items[key] = "#FF0000";
      }
    }

    setColorsItems(items);

    //TODO plug animate out on scroll
    setTimeout(() => {
      emitter.emit("info:close");
    }, 4000);
  }, []);

  // Set initial camera position

  // Set Texture


  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/lightmap/light-map-sol-chambre.webp",
    process.env.PUBLIC_URL + "/matieres/parquet.webp",
    process.env.PUBLIC_URL + "/textures/chambre/berceau.webp",
    process.env.PUBLIC_URL + "/textures/chambre/carton.webp",
    process.env.PUBLIC_URL + "/textures/chambre/lit.webp",
    process.env.PUBLIC_URL + "/textures/chambre/livres.webp",
    process.env.PUBLIC_URL + "/textures/chambre/livres2.webp",
    process.env.PUBLIC_URL + "/textures/chambre/murschambre.webp",
    process.env.PUBLIC_URL + "/textures/chambre/plante.webp",
    process.env.PUBLIC_URL + "/textures/chambre/pouf.webp",
    process.env.PUBLIC_URL + "/textures/chambre/siege.webp",
    process.env.PUBLIC_URL + "/textures/chambre/tablenuit.webp",
    process.env.PUBLIC_URL + "/textures/chambre/trousse.webp",
    process.env.PUBLIC_URL + "/textures/chambre/pot.webp",
    process.env.PUBLIC_URL + "/textures/chambre/lampes.webp",
  ], []);

  const [
    lightmapchambre,
    plancher,
    berceau,
    carton,
    lit,
    livres,
    livres2,
    murschambre,
    plante,
    pouf,
    siege,
    tablenuit,
    trousse,
    pot,
    lampes
  ] = useLoader(TextureLoader, texturePaths);


  useEffect(() => {
    [plancher].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(3, 3);
      t.offset.set(0, 0);
    });
  }, [plancher]);

  // End Texture

  const isMobile = window.innerWidth <= 768;

  // Détection de l'appareil mobile basée sur la largeur de l'écran

  useEffect(() => {
    emitter.emit("scene:loaded");
  }, [nodes]);

  const selectObject = useCallback(
    (id) => {
      const matchingObject = objectsInRoom.find((obj) => obj.id === id);
      if (matchingObject) {
        if (colorsItems !== null) {
          if (id === "canape-animal") {
            const copy = { ...colorsItems };
            copy["canapeAnimal"] = "#FF0000";
            setColorsItems(copy);
          } else if (id === "trousse-soin") {
            const copy = { ...colorsItems };
            copy["trousseSoin"] = "#FF0000";
            setColorsItems(copy);
          } else {
            const copy = { ...colorsItems };
            copy[id] = "#FF0000";
            setColorsItems(copy);
          }
        }
        setObjectFound(matchingObject.id);
        emitter.emit("popup:open", {
          type: "object",
          object: matchingObject,
        });
        emitter.emit("findObjectEvent", matchingObject.id, activeScenario.id, 'chambre');



      }
    },
    [setObjectFound, objectsInRoom, colorsItems],
  );

  const pointerEvents = (pointing) => {
    document.getElementsByTagName("body")[0].style.cursor = pointing
      ? "pointer"
      : "default";
  };

  const onPointerEnter = useCallback((id) => {
    const matchingObject = objectsInRoom.find((obj) => obj.id === id);
    if (matchingObject) {
      emitter.emit("sound:hover");
      pointerEvents(true);
    }
  }, [objectsInRoom]);

  const content = () => (
    <group scale={3}>
      <ambientLight intensity={1} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.chaise.geometry}
        material-map={siege}
        material-map-flipY={false}
        position={[1.71, 0.071, 2.248]}
        rotation={[Math.PI, -1.308, Math.PI]}
      />
      <group position={[-1.91, 0.185, 2.03]} rotation={[0, -0.041, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.plant.geometry}
          material-map={plante}
          material-map-flipY={false}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.plant_1.geometry}
          material-color="#1a3e01"
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pot.geometry}
        material-map={pot}
        material-map-flipY={false}
        position={[-1.923, 0.158, 2.045]}
      />

      <mesh
        castShadow
        receiveShadow
        geometry={nodes["table-de-nuit"].geometry}
        material-map={tablenuit}
        material-map-flipY={false}
        position={[1.047, 0.272, -0.792]}
      />

      <group position={[-1.091, 0.491, -0.787]} scale={0.618}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lamp_base_1.geometry}
          material-map={lampes}
          material-map-flipY={false}
        >
        </mesh>

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lamp_base_2.geometry}
          material-map={lampes}
          material-map-flipY={false}
        >
        </mesh>
      </group>

      <group position={[2.434, 0.606, 1.184]}>
        <mesh castShadow receiveShadow geometry={nodes.sol_1.geometry}>
          <meshStandardMaterial
            map={murschambre}
            map-flipY={false}
            metalness={0.1}
            roughness={0.8}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sol_2.geometry}
          material={materials.basicglass}
        />
      </group>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.plafonnier.geometry}
        material-map={trousse}
        material-map-flipY={false}
        position={[0, 2.316, 0.142]}
      >
        <Sparkles color="#93faa1" count={20} scale={1.5} size={9} speed={0.4} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lit.geometry}
        material-map={lit}
        material-map-flipY={false}
      >
        <meshStandardMaterial map={lit} metalness={0.1} roughness={0.5} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sol.geometry}
        material-map={plancher}
        material-lightMap={lightmapchambre}
        material-lightMapIntensity={10}
        material-lightMap-flipY={false}
        position={[2.434, 0.606, 1.184]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["livre-cote"].geometry}
        material-map={livres}
        material-map-flipY={false}
        position={[-2.126, 0.645, -0.168]}
        rotation={[0, 1.454, -0.303]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.livres001.geometry}
        material-map={livres2}
        material-map-flipY={false}
        position={[0.129, 0.863, -0.966]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["trousse-pharma"].geometry}
        material-map={trousse}
        material-map-flipY={false}
        position={[-1.917, 0.727, 0.11]}
        rotation={[0, 0.821, 0]}
        onClick={() => selectObject("trousse-soin")}
        onPointerEnter={() => onPointerEnter("trousse-soin")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "trousse-soin") && (
          <>
            <Glow
              scale={0.6}
              near={-3}
              far={1}
              color={colorsItems["trousseSoin"]}
            />
            <Outlines thickness={0.009} color={colorsItems["trousseSoin"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("trousse-soin");
                  }
                }}
                aria-label="Ouvrir la modale trousse de soins"
              >
                La trousse de soins
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["lit-bebe"].geometry}
        material-map={berceau}
        material-map-flipY={false}
        position={[1.062, 0.42, 0.31]}
        rotation={[Math.PI / 2, 0, 0]}
        onClick={() => selectObject("berceau")}
        onPointerEnter={() => onPointerEnter("berceau")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "berceau") && (
          <>
            <Glow
              rotation={[-1.6, 0, 0]}
              scale={0.4}
              near={-3}
              far={1}
              color={colorsItems["berceau"]}
            />
            <Outlines thickness={0.009} color={colorsItems["berceau"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("BERCEAU");
                  }
                }}
                aria-label="Ouvrir la modale berceau"
              >
                Le berceau
              </button>
            </Html>
          </>
        )}
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pouf.geometry}
        material-map={pouf}
        material-map-flipY={false}
        position={[2.028, 0.063, 0.777]}
        rotation={[0, -0.794, 0]}
        onClick={() => selectObject("canape-animal")}
        onPointerEnter={() => onPointerEnter("canape-animal")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "canape-animal") && (
          <>
            <Glow
              scale={0.4}
              near={-4}
              far={1}
              color={colorsItems["canapeAnimal"]}
              position={[-0.02, 0.04, -0.1]}
            />
            <Outlines thickness={0.009} color={colorsItems["canapeAnimal"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("canape-animal");
                  }
                }}
                aria-label="Ouvrir la modale panier"
              >
                Le panier
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.blocnote.geometry}
        material={materials.papier}
        position={[0.179, 0.458, 0.541]}
        rotation={[0, 0.268, 0]}
        onClick={() => selectObject("blocknote")}
        onPointerEnter={() => onPointerEnter("blocknote")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "blocknote") && (
          <>
            <Glow
              scale={0.2}
              far={0.4}
              near={-2}
              color={colorsItems["blocknote"]}
              position={[-0.15, 0.1, 0.6]}

            />
            <Outlines thickness={0.009} color={colorsItems["blocknote"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("blocknote");
                  }
                }}
                aria-label="Ouvrir la modale bloc note"
              >
                Le bloc note
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.carton.geometry}
        material-map={carton}
        material-map-flipY={false}
        position={[-1.292, 0.061, 0.96]}
        rotation={[0, 0.782, 0]}
        onClick={() => selectObject("carton")}
        onPointerEnter={() => onPointerEnter("carton")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "carton") && (
          <>
            <Glow
              scale={0.8}
              near={-7}
              color={colorsItems["carton"]}
              position={[0, 0.2, -0.15]}
            />
            <Outlines thickness={0.009} color={colorsItems["carton"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("carton");
                  }
                }}
                aria-label="Ouvrir la modale carton"
              >
                Le carton
              </button>
            </Html>
          </>
        )}
      </mesh>
    </group>
  );

  const camMobCalc = (data, currentPos, currentRot) => {
    const pos = [
      Math.sin(((data.offset - 0.5) * 0.75) / 1) * 2 + currentPos.current.x,
      data.offset - 0.5 + currentPos.current.y,
      Math.sin(((data.offset - 0.5) * 0.75) / 1) * 1.5 + currentPos.current.z,
    ];
    const rot = [
      currentRot.current.x,
      Math.sin(((data.offset - 0.5) * 0.5) / -0.2) + currentRot.current.y,
      currentRot.current.z,
    ];
    return { rotation: rot, position: pos };
  };

  const camDeskCalc = (pointer, currentPos, currentRot) => {
    const pos = [
      Math.sin(pointer.current.x / 1) * -2 + currentPos.current.x,
      pointer.current.y + currentPos.current.y,
      currentPos.current.z,
    ];
    const rot = [
      currentRot.current.x,
      Math.sin(pointer.current.x / -2) * 1.25 + currentRot.current.y,
      currentRot.current.z,
    ];
    return { rotation: rot, position: pos };
  };

  return (
    <group {...props} dispose={null}>
      {isMobile ? (
        <ScrollControls pages={8} damping={0.0001} horizontal={true}>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={50.269}
            position={[0, 3.5, 4]}
          />
          <CameraControl
            camPosDesk={[0, 3.25, 9]}
            camPosMob={[0, 3.25, 12]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
        </ScrollControls>
      ) : (
        <>
          <CameraControl
            camPosDesk={[0, 3.25, 12]}
            camPosMob={[0, 4.25, 6]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={45.269}
            position={[0, 2.75, 7]}
          />
        </>
      )}
      {colorsItems && content()}
      {/* <Effects /> */}
      <SunRays />
    </group>
  );
}

import { OrbitControls, PerspectiveCamera, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { folder, useControls } from 'leva'
import * as THREE from 'three'
import { useRef } from 'react';



const SunRays = () => {
  const noiseTexture = useTexture(process.env.PUBLIC_URL + '/textures/fbm-noise.jpg', (t) => {
    t.wrapS = t.wrapT = THREE.RepeatWrapping
    t.flipY = true
  })
  
    // Définition des uniforms
    const uniforms = useRef(
        {
            uTexture: { value: noiseTexture },
            uTime: { value: 0 },
            uBaseAlpha: { value: 0.25 },
            uGlowAlpha: { value: 0.9 },
            uTopFadeMin: { value: 0.75 },
            uRaysColor: { value: new THREE.Color('#ffffffff') },
            uGlowColor: { value: new THREE.Color('#ffffffff') },
            uRaysStepMin: { value: 0.2 },
            uRaysStepMax: { value: 0.8 },
            uGlowStepMin: { value: 0.9 }
          },
    {
     
    }
  )

  useFrame(({ clock }) => {
    uniforms.current.uTime.value = clock.getElapsedTime()
  })

  const position = [0, 4, 6]
  const cylinderArgs = [
    2,
    4,
    7,
    32,
    1,
    true,
    Math.PI / 2,
    Math.PI
  ]

  return (
    <>

      <mesh position={position} rotation={[-0.4, 0,0]}>
        <cylinderGeometry args={cylinderArgs} />
        <shaderMaterial
          vertexShader={/* glsl */`
            varying vec2 vUv;

            void main(){
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `
          }
          fragmentShader={/* glsl */`
            varying vec2 vUv;

            uniform sampler2D uTexture;
            uniform float uTime;
            uniform float uBaseAlpha;
            uniform float uGlowAlpha;
            uniform float uTopFadeMin;
            uniform vec3 uRaysColor;
            uniform vec3 uGlowColor;
            uniform float uRaysStepMin;
            uniform float uRaysStepMax;
            uniform float uGlowStepMin;

            void main() { 
              vec2 raysNoiseUv = vUv;

              // Pick a place on the noise texture based on the uv.y, we are only using a pixel row
              raysNoiseUv.y = 0.5;
              // Move the noise texture over time to animate the rays
              raysNoiseUv.x += uTime * 0.023;

              vec4 noise = texture2D(uTexture, raysNoiseUv);

              // Step the noise texture to only get the ray influence factors in the noise range we want
              // Near the min value, the rays are less strong so will fade out faster
              // Near the max value, the rays are stronger so will fade out slower
              float rayFactor = smoothstep(uRaysStepMin, uRaysStepMax, noise.r);
              // Fade out the rays based on the length of the cone multiplied by the rays factor
              float rayFade = vUv.y - rayFactor * 0.1;
              
              // uv.y = 1 is the top of the cone, the uTopFadeMin sets the point where the cone starts to fade out till uv.y = 1
              float coneTopFade = 1.0 - smoothstep(uTopFadeMin, 1.0, vUv.y);
              // Defines where the glow starts to influence the ray cone on the way to the top
              float sourceGlowFactor = smoothstep(uGlowStepMin, 1.0, vUv.y);

              vec3 color = uRaysColor * rayFactor;
              color = mix(color, uGlowColor, sourceGlowFactor);

              // Mix the base alpha rayFade alpha to get the final alpha, we aditionally multiply the rayFactor to the mix to
              // increase the rays alpha difference between the strong and weak rays, this gives a better result
              float alpha = uBaseAlpha * rayFade * rayFactor;
              // Mix between the ray based calculated alpha and the glow alpha based on the sourceGlowFactor
              alpha = mix(alpha, uGlowAlpha, sourceGlowFactor);
              // Overwrite take in account the cone top fade, this will fade out both the rays and glow on the top of the cone
              alpha *= coneTopFade;

              gl_FragColor.rgb = color;
              gl_FragColor.a = alpha;
            }
          `
          }
          uniforms={uniforms.current}
          transparent
          side={THREE.DoubleSide}
        />
        {/* <meshBasicMaterial map={debugTexture} /> */}
      </mesh>
      {/* <mesh position={position} >
        <cylinderGeometry args={cylinderArgs} />
        <meshBasicMaterial color="white" wireframe />
      </mesh> */}
    </>
  )
}


export default SunRays
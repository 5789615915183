import * as THREE from "three";
import React, { useEffect, useRef, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  PerspectiveCamera,
  useScroll,
  Environment,
  Lightformer,
} from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { RepeatWrapping, TextureLoader } from "three";
import { gsap } from "gsap";
import emitter from "../emitter.js";
import { Maison as InfoBox } from "../store/data/infoBox.js";
import { useExperienceStore } from "../store";
import useInactivityTimer from "../components/useInactivityTimer.js";

export default function Maison({ setScroll, ...props }) {
  const group = useRef();
  const setExperienceStart = useExperienceStore(
    (state) => state.setExperienceStart,
  );
  const setCurrentRoom = useExperienceStore((state) => state.setCurrentRoom);
  const experienceStarted = useExperienceStore(
    (state) => state.experienceStarted,
  );

  const { nodes, materials, animations } = useGLTF(process.env.PUBLIC_URL + "/models/maison.glb");
  const { actions, mixer } = useAnimations(animations, group);

  const infoAnimation = useRef();

  // Textures

  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/matieres/parquet.webp",
    process.env.PUBLIC_URL + "/matieres/grass4k.webp",
    process.env.PUBLIC_URL + "/textures/maison/mursbas.webp",
    process.env.PUBLIC_URL + "/textures/maison/feuilles.webp",
    process.env.PUBLIC_URL + "/textures/maison/feuilles-diffuse.webp",
    process.env.PUBLIC_URL + "/textures/maison/mursmilieu.webp",
    process.env.PUBLIC_URL + "/textures/maison/murshaut.webp",
    process.env.PUBLIC_URL + "/textures/maison/vaisselle.webp",
    process.env.PUBLIC_URL + "/textures/maison/fond.webp",
    process.env.PUBLIC_URL + "/textures/maison/photo.webp",
    process.env.PUBLIC_URL + "/textures/maison/arbre.webp",
    process.env.PUBLIC_URL + "/textures/maison/etageres.webp",
    process.env.PUBLIC_URL + "/textures/maison/chambre.webp",
    process.env.PUBLIC_URL + "/textures/maison/canape.webp",
    process.env.PUBLIC_URL + "/textures/maison/plante.webp",
  ], []);

  const [
    plancher,
    herbe,
    mursbas,
    feuilles,
    feuillescolor,
    mursmilieu,
    murshaut,
    vaisselle,
    fond,
    photo,
    arbre,
    etageres,
    chambremurs,
    canape,
    plante,
  ] = useLoader(TextureLoader, texturePaths);

  useEffect(() => {
    [plancher].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(10, 5);
      t.offset.set(0, 0);
    });

    [herbe].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(10, 10);
      t.offset.set(0, 0);
    });
    [feuilles, feuillescolor].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(5, 5);
      t.offset.set(0, 0);
    });
  }, [plancher, herbe, feuilles, feuillescolor]);

  // Fin du setup Textures



  const startAnimation = () => {
    const action = actions["CameraAction"];
    if (action) {
      action.reset();
      action.play();
      action.setLoop(THREE.LoopOnce, 1);
      action.timeScale = 0.85; // Ajuste la vitesse de l'animation

      let isEventEmitted = false; // Pour s'assurer que l'événement n'est émis qu'une fois

      const onProgress = () => {
        // Calcule le pourcentage de progression
        const progress = action.time / action.getClip().duration;
        if (progress >= 0.95 && !isEventEmitted) {
          emitter.emit("sound:transition", "hall");
          setCurrentRoom("hall");
          setExperienceStart(true)

          isEventEmitted = true; // Marque l'événement comme émis
        }
        if (progress < 1) {
          requestAnimationFrame(onProgress); // Continue à suivre la progression
        }
      };

      requestAnimationFrame(onProgress); // Commence à suivre la progression


    };

  };

  useEffect(() => {
    // Define the event listener callback function
    const handleExperienceStart = () => {
      startAnimation();
    };

    // Add event listener
    emitter.on('experience:start', handleExperienceStart);

    // Cleanup function to remove event listener
    return () => {
      emitter.off('experience:start', handleExperienceStart);
    };
  }, [actions, mixer]); // Add any other dependencies for your animation here




  return (
    <>
      <ambientLight intensity={1} />
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Plane001"
            position={[-6.385, -0.134, -20.565]}
            rotation={[0, -1.371, 0]}
          >
            <mesh
              name="Plane002_1"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_1.geometry}
              material-map={plancher}
            />
            <mesh
              name="Plane002_2"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_2.geometry}
              material={materials.aerial_grass_rock}
              material-map={herbe}
            ></mesh>
          </group>
          <mesh
            name="Plane002"
            castShadow
            receiveShadow
            geometry={nodes.Plane002.geometry}
            material={materials["Material.003"]}
            position={[-0.205, 11.9, -58.981]}
            scale={1.2}
            rotation={[Math.PI / 2, 0, 0]}
            material-map={fond}
            material-map-flipY={false}
          />
          <mesh
            name="Cylinder"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder.geometry}
            material={materials["meubles-orange"]}
            position={[-7.453, 0.111, -21.239]}
          />
          <group
            name="vaisselles"
            position={[-1.86, 1.178, -11.702]}
            rotation={[3.018, 0, Math.PI / 2]}
          >
            <mesh
              name="Mesh002"
              castShadow
              receiveShadow
              geometry={nodes.Mesh002.geometry}
              material={materials["Blanco 003"]}
              material-color="white"
            />
            <mesh
              name="Mesh002_1"
              castShadow
              receiveShadow
              geometry={nodes.Mesh002_1.geometry}
              material={materials["Metal 002"]}
              material-color="grey"
            />
            <mesh
              name="Mesh002_2"
              castShadow
              receiveShadow
              geometry={nodes.Mesh002_2.geometry}
              material={materials["Metal 007"]}
              material-color="grey"
            />
            <mesh
              name="Mesh002_3"
              castShadow
              receiveShadow
              geometry={nodes.Mesh002_3.geometry}
              material={materials["Blanco 003_2"]}
              material-color="white"
            />
            <mesh
              name="Mesh002_4"
              castShadow
              receiveShadow
              geometry={nodes.Mesh002_4.geometry}
              material={materials["Metal 002_1"]}
              material-color="grey"
            />
          </group>
          <group
            name="gaziniere"
            position={[0.53, 0.441, -7.312]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
          >
            <mesh
              name="Mesh071"
              castShadow
              receiveShadow
              geometry={nodes.Mesh071.geometry}
              material={materials.Metal}
            />
            <mesh
              name="Mesh071_1"
              castShadow
              receiveShadow
              geometry={nodes.Mesh071_1.geometry}
              material={materials["negro 001"]}
            />
          </group>
          <mesh
            name="etageres"
            castShadow
            receiveShadow
            geometry={nodes.etageres.geometry}
            material-map={etageres}
            material-map-flipY={false}
            position={[0.609, 1.786, -11.693]}
            rotation={[-Math.PI, 0, -Math.PI]}
          />
          <group
            name="lampe"
            position={[3.646, 4.243, -8.889]}
            rotation={[-Math.PI, 0, -Math.PI]}
          >
            <mesh
              name="Mesh164"
              castShadow
              receiveShadow
              geometry={nodes.Mesh164.geometry}
              material={materials.metal}
              material-color="grey"
            />
            <mesh
              name="Mesh164_1"
              castShadow
              receiveShadow
              geometry={nodes.Mesh164_1.geometry}
              material={materials["Material 009"]}
            />
          </group>
          <group
            name="cadre"
            position={[-0.898, 6.869, -5.668]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
          >
            <mesh
              name="Mesh168"
              castShadow
              receiveShadow
              geometry={nodes.Mesh168.geometry}
              material-map={plancher}
            />
            <mesh
              name="Mesh168_1"
              castShadow
              receiveShadow
              geometry={nodes.Mesh168_1.geometry}
              material={materials["Glow Glass 02"]}
            />
          </group>

          <group
            name="plantes"
            position={[-7.459, 0.01, -21.261]}
            rotation={[0, -0.041, 0]}
          >
            <mesh
              name="2"
              castShadow
              receiveShadow
              geometry={nodes["2"].geometry}
              material={materials["Material_Mat.002"]}
              material-map={plante}
            />
            <mesh
              name="2_1"
              castShadow
              receiveShadow
              geometry={nodes["2_1"].geometry}
              material-color="#4c6644"
            />
          </group>
          <group
            name="plantes001"
            position={[1.052, 0.977, -6.545]}
            rotation={[0, -0.041, 0]}
          >
            <mesh
              name="2001"
              castShadow
              receiveShadow
              geometry={nodes["2001"].geometry}
              material={materials["Material_Mat.002"]}
            />
            <mesh
              name="2001_1"
              castShadow
              receiveShadow
              geometry={nodes["2001_1"].geometry}
              material={materials["Material_2_Mat.002"]}
            />
          </group>
          <mesh
            name="COMPUTER"
            castShadow
            receiveShadow
            geometry={nodes.COMPUTER.geometry}
            material-color="black"
            position={[4.8, 1.367, -7.595]}
          />
          <mesh
            name="pot"
            castShadow
            receiveShadow
            geometry={nodes.pot.geometry}
            material-color="orange"
            position={[1.012, 0.95, -6.536]}
          />
          <mesh
            name="casserole"
            castShadow
            receiveShadow
            geometry={nodes.casserole.geometry}
            material={materials.metal}
            position={[3.718, 0.962, -7.909]}
            rotation={[0, 1.119, 0]}
          />
          <mesh
            name="Plane001_chair_0"
            castShadow
            receiveShadow
            geometry={nodes.Plane001_chair_0.geometry}
            material-map={plancher}
            position={[3.96, 0.954, -5.69]}
            rotation={[-Math.PI / 2, 0, 0]}
          />
          <mesh
            name="meubles"
            castShadow
            receiveShadow
            geometry={nodes.meubles.geometry}
            position={[1.244, 7.373, -7.715]}
            material-map={canape}
            material-map-flipY={false}
          />
          <mesh
            name="Plane"
            castShadow
            receiveShadow
            geometry={nodes.Plane.geometry}
            position={[-2.624, 6.412, -8.16]}
            rotation={[-Math.PI / 2, 0, -Math.PI]}
            material-map={chambremurs}
            material-map-flipY={false}
          />
          <mesh
            name="feuilles-arbres"
            castShadow
            receiveShadow
            geometry={nodes["feuilles-arbres"].geometry}
            material={materials.feuilles}
            position={[-7.92, 8.451, -16.35]}
            rotation={[0.313, -0.213, 0.706]}
            material-map={feuillescolor}
            material-alphaMap={feuilles}
            material-alphaMap-flipY={false}
            material-side={THREE.DoubleSide}
          >
            <meshPhysicalMaterial
              map={feuillescolor}
              map-flipY={false}
              alphaMap={feuilles}
              alphaMap-flipY={false}
              side={THREE.DoubleSide}
              transparent={true}
              color="#6b875b"
            />
          </mesh>
          <mesh
            name="tronc-arbre"
            castShadow
            receiveShadow
            geometry={nodes["tronc-arbre"].geometry}
            material-map={arbre}
            position={[-8.171, 5.767, -14.063]}
            rotation={[-Math.PI, 0.351, -Math.PI]}
          />
          <mesh
            name="Cube001"
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            position={[-0.677, 6.613, -8.807]}
            material-map={mursmilieu}
            material-map-flipY={false}
            material-color="#e6e2ca"
          />
          <mesh
            name="Cube005"
            castShadow
            receiveShadow
            geometry={nodes.Cube005.geometry}
            position={[-0.01, 8.388, -13.155]}
            material-map={murshaut}
            material-map-flipY={false}
            material-side={THREE.DoubleSide}
            material-color="#e6e2ca"
          />
          <mesh
            name="Cube006"
            castShadow
            receiveShadow
            geometry={nodes.Cube006.geometry}
            material={materials["Material.004"]}
            position={[0, 0.96, -19.436]}
            material-map={mursbas}
            material-map-flipY={false}
            material-color="#e6e2ca"
          />
          <mesh
            name="Cube002"
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials["meubles-blanc"]}
            position={[-2.832, 0.413, -12.006]}
          />
          <mesh
            name="Cube007"
            castShadow
            receiveShadow
            geometry={nodes.Cube007.geometry}
            material={materials.plancher}
            position={[-0.899, 8.674, -10.668]}
          />
          <group
            name="wallpictures001"
            position={[2.393, 2.861, -12.38]}
            rotation={[Math.PI / 2, 0, Math.PI / 2]}
          >
            <mesh
              name="WP_20180112_020g000"
              castShadow
              receiveShadow
              geometry={nodes.WP_20180112_020g000.geometry}
              material-map={photo}
              material-map-flipY={false}
            />
            <mesh
              name="WP_20180112_020g000_1"
              castShadow
              receiveShadow
              geometry={nodes.WP_20180112_020g000_1.geometry}
              material-color="brown"
            />
          </group>
          <mesh
            name="Cylinder003"
            castShadow
            receiveShadow
            geometry={nodes.Cylinder003.geometry}
            material-map={vaisselle}
            material-map-flipY={false}
            position={[-3.915, 1.265, -12.125]}
            rotation={[Math.PI / 2, 0, 0]}
          />
          <PerspectiveCamera
            name="Camera"
            makeDefault={true}
            far={171.6}
            near={0.1}
            fov={43.175}
            position={[0, 3.631, 19.471]}
            rotation={[0.061, -0.037, 0.002]}
          />
          <group
            name="NurbsPath"
            position={[0, 1.809, 17.564]}
            rotation={[-0.052, Math.PI / 2, 0]}
          />
          <group name="Empty" position={[1.26, 5.724, -14.944]} />
        </group>
        <Environment blur={0.5}>
          <Lightformer
            intensity={4.15}
            rotation-y={-Math.PI / 2}
            position={[2, 1.5, 0.25]}
            scale={5}
            color={"#fff8d4"}
          />
        </Environment>
      </group>
    </>
  );
}

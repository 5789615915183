import styles from "./TutoBox.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import emitter from "../../emitter";


export default function TutoBox() {
  const [slide, setSlide] = useState(0);
  const container = useRef();
  const wrapper = useRef();

  const content = [
    "Lancez une des 5 quêtes proposées dans le hall pour démarrer un parcours sur la thématique de votre choix.",
    "Laissez-vous guider dans les pièces de la maison et cliquez sur les objets en surbrillance pour découvrir des contenus inspirants.",
    "Collectez les conseils et accompagnements dans votre panier pour y revenir à tout moment.",
    "Consultez la jauge pour découvrir votre avancée au sein d'une quête.",
  ];

  // Fonction pour gérer le clic sur les flèches du tutoriel
  const handleTutorialArrowClick = (direction) => {
    // Assurez-vous que window.dataLayer existe
    window.dataLayer = window.dataLayer || [];

    // Identifier le tutoriel actuel en fonction du slide
    let tutorialStep = `tutoriel${slide + 1}`; // +1 car les slides commencent à 0
    console.log(tutorialStep)
    // Pousser l'événement dans le Data Layer
    window.dataLayer.push({
      event: 'click.action',
      click: tutorialStep,
    });
  };

  // Fonction pour gérer le clic sur la croix (fin du tutoriel)
  const handleTutorialCloseClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: 'tutoriel_fin'
    });

    // Fermer le tutoriel
    animOut();
  };



  useEffect(() => {
    // Animation pour aller au slide actuel
    gsap.to(container.current, {
      x: 0 * slide,
      duration: 0.5,
    });
  }, [slide]);

  const goToNextSlide = () => {
    handleTutorialArrowClick('next');

    setSlide((prevSlide) => {
      // Vérifier si la slide actuelle est la dernière
      if (prevSlide === content.length - 1) {
        // Si c'est la dernière slide, fermer la modale
        animOut();
        // Optionnellement, remettre la slide à la première (ou selon votre logique d'interface)
        return 0;
      } else {
        // Passer à la slide suivante
        return (prevSlide + 1) % content.length;
      }
    });
  };

  const goToPrevSlide = () => {
    handleTutorialArrowClick('prev');

    setSlide((prevSlide) => (prevSlide - 1 + content.length) % content.length);
  };

  const onChangeHandler = (e) => {
    const selectedSlide = parseInt(e.target.value, 10); // Convertir la valeur en nombre
    setSlide(selectedSlide); // Mettre à jour la diapositive en fonction du radio input cliqué

  };

  const animIn = () => {
    gsap.to(wrapper.current, {
      autoAlpha: 1,
      duration: 0.5,
      pointerEvents: "auto",
      ease: "power1.out",
    });
  };

  const animOut = () => {
    gsap.to(wrapper.current, {
      autoAlpha: 0,
      duration: 0.5,
      pointerEvents: "none",
      ease: "power1.out",
    });
  };

  useEffect(() => {
    emitter.on("tuto:open", animIn);
  });

  return (
    <>
      <div className={styles.tuto} ref={wrapper}>
        <div className={styles.tutobox} >


          <button
            className={styles.popupClose}
            aria-label={"Bouton fermer la modale du tutoriel"}
            onClick={handleTutorialCloseClick} // Utiliser ici la fonction de fermeture
          >
            <svg width="30" height="30" viewBox="0 0 182 182" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="91" cy="91" r="86" stroke="#0A2D82" stroke-width="13" />
              <path d="M129.891 52.1084L52.1089 129.89" stroke="#0A2D82" stroke-width="13" />
              <path d="M129.891 129.891L52.1089 52.1089" stroke="#0A2D82" stroke-width="13" />
            </svg>
          </button>
          <h3>Tutoriel</h3>

          <div className={styles.content} ref={container} onClick={goToNextSlide}>
            {/* Flèche gauche */}


            <p>{content[slide]}</p>
            {slide === 2 && (
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="25" cy="25" r="25" fill="#0A2D82" />
                <path d="M36.2512 19.3841C35.6636 18.7151 34.8558 18.2691 33.9744 18.2691H22.6642C22.297 18.2691 21.9298 18.5664 21.9298 19.0125C21.9298 19.4585 22.297 19.7558 22.6642 19.7558H33.9744C34.4151 19.7558 34.8558 19.9788 35.1495 20.2762C35.4433 20.6479 35.5902 21.0939 35.4433 21.5399L34.7823 25.2567H26.7036C26.3364 25.2567 25.9692 25.554 25.9692 26C25.9692 26.4461 26.3364 26.7434 26.7036 26.7434H34.562L33.901 30.3115C33.8276 30.8319 33.3135 31.2036 32.7993 31.2036H18.6983C18.1842 31.2036 17.7435 30.8319 17.5966 30.3115L14.7324 14.1806C14.6589 13.8089 14.3652 13.5859 13.9979 13.5859H8.34283C7.97561 13.5859 7.6084 13.8833 7.6084 14.3293C7.6084 14.7753 7.97561 15.0727 8.34283 15.0727H13.4104L16.2012 30.6832C16.3481 31.7239 17.0825 32.4673 18.0373 32.7646C17.6701 33.285 17.3763 33.954 17.3763 34.6973C17.3763 36.5557 18.8452 38.0425 20.6813 38.0425C22.5173 38.0425 23.9862 36.5557 23.9862 34.6973C23.9862 34.0283 23.7659 33.3593 23.3986 32.8389H27.8787C27.5115 33.3593 27.2911 34.0283 27.2911 34.6973C27.2911 36.5557 28.76 38.0425 30.5961 38.0425C32.4321 38.0425 33.901 36.5557 33.901 34.6973C33.901 33.954 33.6807 33.285 33.3135 32.7646C34.2682 32.5416 35.0761 31.7239 35.2964 30.6832L36.9122 21.9116C37.059 21.0195 36.8387 20.1275 36.2512 19.3841ZM22.5173 34.623C22.5173 35.6637 21.7095 36.4814 20.6813 36.4814C19.6531 36.4814 18.8452 35.6637 18.8452 34.623C18.8452 33.5823 19.6531 32.7646 20.6813 32.7646C21.7095 32.7646 22.5173 33.5823 22.5173 34.623ZM30.6695 36.4814C29.6413 36.4814 28.8334 35.6637 28.8334 34.623C28.8334 33.5823 29.6413 32.7646 30.6695 32.7646C31.6977 32.7646 32.5056 33.5823 32.5056 34.623C32.5056 35.6637 31.6977 36.4814 30.6695 36.4814Z" fill="#CBE300" />
                <defs>
                  <clipPath id="clip0_1_311">
                    <rect
                      width="28.6356"
                      height="22.0842"
                      fill="white"
                      transform="translate(10.1201 18.8776)"
                    />
                  </clipPath>
                </defs>
              </svg>
            )}
            {slide === 3 && (
              <svg width="10" height="13" viewBox="-7 0 10 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 3.83826L1 29.1617C1 30.7293 2.79086 32 5 32C7.20914 32 9 30.7293 9 29.1617L9 3.83826C9 2.27073 7.20914 1 5 1C2.79086 1 1 2.27073 1 3.83826Z" stroke="#0A2D82" stroke-miterlimit="10" />
                <path d="M2 28.788L2 24H8V28.788C8 30.0086 6.65538 31 5 31C3.34462 31 2 30.0086 2 28.788Z" fill="#0A2D82" />
                <path d="M2 16L2 23H8V16H2Z" fill="#0A2D82" />
              </svg>

            )}

          </div>

          <div className={styles.radioInputs}>
            <div className={styles.arrowLeft} onClick={goToPrevSlide}>&lt;</div>
            <div>
              <input
                type="radio"
                name="tutorial"
                id="slide0"
                onChange={onChangeHandler}
                checked={slide === 0}
                value={0}
              />
              <input
                type="radio"
                name="tutorial"
                id="slide1"
                onChange={onChangeHandler}
                checked={slide === 1}
                value={1}
              />
              <input
                type="radio"
                name="tutorial"
                id="slide2"
                onChange={onChangeHandler}
                checked={slide === 2}
                value={2}
              />
              <input
                type="radio"
                id="slide3"
                name="tutorial"
                onChange={onChangeHandler}
                checked={slide === 3}
                value={3}
              />
            </div>
            <div className={styles.arrowRight} onClick={goToNextSlide}>&gt;</div>

          </div>

        </div>
      </div>
    </>

  );
}

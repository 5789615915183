import { TextureLoader } from "three";
import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  ScrollControls,
  Outlines,
  Html, Environment, Lightformer
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import emitter from "../emitter";
import { useExperienceStore } from "../store";
import { CameraControl } from "../components/CameraControl";
import styles from "./Room.module.scss";
import Glow from "../components/Glow";
import useInactivityTimer from "../components/useInactivityTimer.js";


export function Garage(props) {
  const objectsInRoom = useExperienceStore((state) => state.objectsInRoom());
  const setObjectFound = useExperienceStore((state) => state.setObjectFound);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const objectsFound = useRef([]);
  const [colorsItems, setColorsItems] = useState(null);
  const isMobile = window.innerWidth <= 768;

  useInactivityTimer("parcourir");

  // set texture

  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/textures/garage/carton.webp",
    process.env.PUBLIC_URL + "/textures/garage/conteneur.webp",
    process.env.PUBLIC_URL + "/textures/garage/etablis.webp",
    process.env.PUBLIC_URL + "/textures/garage/mursgarage.webp",
    process.env.PUBLIC_URL + "/textures/garage/posters.webp",
    process.env.PUBLIC_URL + "/textures/garage/radio.webp",
    process.env.PUBLIC_URL + "/textures/garage/roue.webp",
    process.env.PUBLIC_URL + "/textures/garage/velo.webp",
    process.env.PUBLIC_URL + "/textures/garage/voiture.webp",
    process.env.PUBLIC_URL + "/textures/garage/fenetre.webp",
    process.env.PUBLIC_URL + "/textures/garage/cle.webp",
  ], []);

  const [
    carton,
    conteneur,
    etablis,
    mursgarage,
    posters,
    radio,
    roue,
    velo,
    voiture,
    fenetre,
    cle,
  ] = useLoader(TextureLoader, texturePaths);


  // end texture

  useEffect(() => {
    emitter.emit("info:open", {
      title: "Ouvrez l'œil !",
      text: `On ne sait jamais ce que peut cacher un objet !`,
    });
    const timeoutId = setTimeout(() => {
      emitter.emit("info:close");
    }, 4000);

    const items = {
      plante: "#D3D62E",
      cle: "#D3D62E",
      papierpeint: "#D3D62E",
      boiteoutils: "#D3D62E",
    };

    for (const [key] of Object.entries(items)) {
      const obj = activeScenario.objects.find((obj) => {
        let tmpKey = key;
        return obj.id === tmpKey;
      });
      if (obj && obj.found) {
        items[key] = "#FF0000";
      }
    }

    setColorsItems(items);

    // Fonction de nettoyage
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "/models/garage.glb");

  useEffect(() => {
    emitter.emit("pageViewEvent", "garage", activeScenario.id);
  }, []);

  useEffect(() => {
    emitter.emit("scene:loaded");
  }, [nodes]);

  const selectObject = useCallback(
    (id) => {
      const matchingObject = objectsInRoom.find((obj) => obj.id === id);
      if (matchingObject) {
        if (colorsItems !== null) {
          const copy = { ...colorsItems };
          copy[id] = "#FF0000";
          setColorsItems(copy);
        }
        setObjectFound(matchingObject.id);
        emitter.emit("popup:open", {
          type: "object",
          object: matchingObject,
        });
        if (!objectsFound.current.includes(matchingObject.id)) {
          emitter.emit("findObjectEvent", matchingObject.id, activeScenario.id, 'garage');
          objectsFound.current.push(matchingObject.id);
        }
      }
    },
    [setObjectFound, objectsInRoom],
  );

  const pointerEvents = (pointing) => {
    document.getElementsByTagName("body")[0].style.cursor = pointing
      ? "pointer"
      : "default";
  };

  const onPointerEnter = useCallback((id) => {
    const matchingObject = objectsInRoom.find((obj) => obj.id === id);
    if (matchingObject) {
      emitter.emit("sound:hover");
      pointerEvents(true);
    }
  }, []);

  const content = () => (
    <>
      <mesh
        geometry={nodes.neon.geometry}
        material={materials["Material.018"]}
        position={[0.095, 7.536, 5.074]}
        rotation={[0, 0, -Math.PI / 2]}
      />
      <group position={[-6.686, 2.833, -6.947]} rotation={[0, -1.571, 0]}>
        <mesh
          geometry={nodes.fenetre_1.geometry}
          material-map={fenetre}
          material-map-flipY={false}
        />
        <mesh
          geometry={nodes.fenetre_2.geometry}
          material={materials.Glass_material}
        />
      </group>
      <mesh
        position={[-5.625, 0.179, -3.484]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color="grey" />
      </mesh>
      <mesh
        geometry={nodes.cartons.geometry}
        material-map={carton}
        material-map-flipY={false}
        position={[-5.836, 0.556, -9.797]}
        rotation={[Math.PI / 2, 0, -0.544]}
      />
      <group
        position={[-0.949, 2.095, -9.749]}
        rotation={[0.987, 1.499, -1.008]}
      >
        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials.bark_i_dunno_i_guess_thats_how_its_called}
        />
        <mesh
          geometry={nodes.Object_4_1.geometry}
          material={materials.leaf}
          onClick={() => selectObject("plante")}
          onPointerEnter={() => onPointerEnter("plante")}
          onPointerLeave={() => pointerEvents(false)}
        >
          {objectsInRoom.find((obj) => obj.id === "plante") && (
            <>
              <Glow
                scale={3}
                far={1}
                near={-5}
                color={colorsItems["plante"]}
              />
              <Outlines thickness={0.02} color={colorsItems["plante"]} />
              <Html>
                <button
                  className={styles.objetsscene}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      selectObject("plante");
                    }
                  }}
                  aria-label="Ouvrir la modale plante"
                >
                  La plante
                </button>
              </Html>
            </>
          )}
        </mesh>

        <mesh
          geometry={nodes.Object_4_2.geometry}
          material={materials.ceramique}
          onClick={() => selectObject("plante")}
          onPointerEnter={() => onPointerEnter("plante")}
          onPointerLeave={() => pointerEvents(false)}
        >
          {objectsInRoom.find((obj) => obj.id === "plante") && (
            <Outlines thickness={0.05} color={colorsItems["plante"]} />
          )}
        </mesh>
      </group>
      <mesh
        geometry={nodes.murs.geometry}
        position={[0.045, 2.902, -1.945]}
        rotation={[Math.PI / 2, 0, 0]}
        material-map={mursgarage}
        material-map-flipY={false}
      />
      <mesh
        geometry={nodes.SOL.geometry}
        position={[3.189, 0.186, -0.627]}
        rotation={[Math.PI / 2, 0, 0]}
        material-map={mursgarage}
        material-map-flipY={false}
      />
      <mesh
        geometry={nodes.Voiture.geometry}
        position={[2.429, 1.902, -2.603]}
        rotation={[-1.57, 0.006, 1.418]}
        material-map={voiture}
        material-map-flipY={false}
      >
      </mesh>
      <mesh
        geometry={nodes.armoire.geometry}
        position={[-6.194, 2.234, 0.727]}
        material-map={etablis}
        material-map-flipY={false}
      />
      <mesh
        geometry={nodes.etabli.geometry}
        position={[4.399, 0.916, -9.476]}
        rotation={[0, Math.PI / 2, 0]}
        material-map={etablis}
        material-map-flipY={false}
      />
      <mesh
        geometry={nodes.Cube.geometry}
        material-map={cle}
        material-map-flipY={false}
        position={[5.962, 1.856, -0.026]}
        rotation={[-2.78, 0.572, 1.369]}
        scale={[0.036, 0.134, 0.094]}
        onClick={() => selectObject("cle")}
        onPointerEnter={() => onPointerEnter("cle")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "cle") && (
          <>
            <Glow
              scale={8}
              near={-15}
              far={1}
              color={colorsItems["cle"]}
              position={[-5, 0, 0]}
              rotation={[4.8, -6.9, 4.69]} />

            <Outlines thickness={0.1} color={colorsItems["cle"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("cle");
                  }
                }}
                aria-label="Ouvrir la modale clé"
              >
                les clefs
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        geometry={nodes.etagere.geometry}
        material-map={etablis}
        material-map-flipY={false}
        position={[6.789, 2.44, -4.315]}
      />
      <mesh
        geometry={nodes.tableau.geometry}
        material-map={etablis}
        material-map-flipY={false}
        position={[5.105, 0.186, -0.381]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group
        position={[4.556, 0.186, 0.006]}
        rotation={[Math.PI / 2, 0, 0.058]}
      >
        <mesh
          geometry={nodes.Mesh178.geometry}
          material={materials["Material.002"]}
        />
        <mesh
          geometry={nodes.Mesh178_1.geometry}
          material-map={radio}
          material-map-flipY={false}
        />
      </group>
      <mesh
        geometry={nodes.ROUE.geometry}
        material-map={roue}
        material-map-flipY={false}
        position={[6.267, 0.722, 1.009]}
        rotation={[-1.86, -0.002, 2.41]}
      />
      <mesh
        geometry={nodes.outils.geometry}
        material={materials["Frozen white metal"]}
        position={[-1.457, 1.894, -10.527]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.carton.geometry}
        material-map={carton}
        material-map-flipY={false}
        position={[-3.44, 0.155, -0.218]}
        rotation={[-Math.PI, 1.047, 0]}
      />
      <mesh
        geometry={nodes["caisse-outils"].geometry}
        material-map={conteneur}
        material-map-flipY={false}
        position={[-3.381, 0.611, 0.785]}
        onClick={() => selectObject("boiteoutils")}
        onPointerEnter={() => onPointerEnter("boiteoutils")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "boiteoutils") && (
          <>
            <Glow
              scale={0.4}
              near={-1}
              far={0.4}
              color={colorsItems["boiteoutils"]}
              position={[0.4, 0.01, 0.4]}
              rotation={[0, 1, 1]}
            />

            <Outlines thickness={0.02} color={colorsItems["boiteoutils"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("boiteoutils");
                  }
                }}
                aria-label="Ouvrir la modale boite à outils"
              >
                La boite à outils
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        geometry={nodes.pots.geometry}
        material-map={conteneur}
        material-map-flipY={false}
        position={[6.659, 3.665, -1.349]}
      />
      <mesh
        geometry={nodes.palettes.geometry}
        material-map={conteneur}
        material-map-flipY={false}
        position={[-3.72, 0.957, -10.379]}
        rotation={[2.793, 0, 0]}
      />
      <mesh
        geometry={nodes["PAPIER-PEINT-2001"].geometry}
        material-map={posters}
        material-map-flipY={false}
        position={[-5.495, 0.984, 0.364]}
        rotation={[0, 0, 0.201]}
      />
      <mesh
        geometry={nodes["PAPIER-PEINT-2"].geometry}
        material-map={posters}
        material-map-flipY={false}
        position={[-4.422, 0.921, -0.228]}
        rotation={[0, 0, -0.195]}
        onClick={() => selectObject("papierpeint")}
        onPointerEnter={() => onPointerEnter("papierpeint")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "papierpeint") && (
          <>
            <Outlines thickness={0.02} color={colorsItems["papierpeint"]} />
            <Glow
              scale={0.5}
              near={-1}
              far={0.5}

              color={colorsItems["papierpeint"]}
              position={[0, 0, 0]}
            />

            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("papierpeint");
                  }
                }}
                aria-label="Ouvrir la modale papier peint"
              >
                Le papier peint
              </button>
            </Html>
          </>
        )}
      </mesh>
      <mesh
        geometry={nodes.posters.geometry}
        material-map={posters}
        material-map-flipY={false}
        position={[-4.076, 2.767, -10.593]}
        rotation={[0.208, -0.088, -1.74]}
      />
      <mesh
        geometry={nodes.VELO.geometry}
        material-map={velo}
        material-map-flipY={false}
        position={[-5.964, 0.658, -5.747]}
        rotation={[0, 1.012, 0]}
      />
    </>
  );

  const camMobCalc = (data, currentPos, currentRot) => {
    const pos = [
      Math.sin(((data.offset - 0.5) * 0.75) / 1) * 2 + currentPos.current.x,
      Math.sin(((data.offset - 0.5) * 0.75) / 1) + currentPos.current.y,
      currentPos.current.z,
    ];
    const rot = [
      currentRot.current.x,
      Math.sin(((data.offset - 0.5) * 0.5) / -0.7) + currentRot.current.y,
      currentRot.current.z,
    ];
    return { rotation: rot, position: pos };
  };

  const camDeskCalc = (pointer, currentPos, currentRot) => {
    const pos = [
      Math.sin(pointer.current.x / 1) * -2 + currentPos.current.x,
      pointer.current.y + currentPos.current.y,
      currentPos.current.z,
    ];
    const rot = [
      currentRot.current.x,
      Math.sin(pointer.current.x / -2) + currentRot.current.y,
      currentRot.current.z,
    ];
    return { rotation: rot, position: pos };
  };

  return (
    <group {...props} dispose={null}>
      {isMobile ? (
        <ScrollControls pages={8} damping={0.0001} horizontal={true}>
          <PerspectiveCamera
            makeDefault={true}
            far={100}
            near={0.1}
            fov={72.115}
            position={[0, 3, 3]}
          />
          <CameraControl
            camPosDesk={[0, 3.25, 10]}
            camPosMob={[0, 3.25, 8]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
        </ScrollControls>
      ) : (
        <>
          <CameraControl
            camPosDesk={[0, 3.25, 10]}
            camPosMob={[0, 4.25, 6]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
          <PerspectiveCamera
            makeDefault={true}
            far={300}
            near={0.1}
            fov={40.115}
            position={[0, 2, 6]}
          />
        </>
      )}
      {colorsItems != null && content()}
      <Environment blur={0.5}>

        <Lightformer
          intensity={4}
          position={[3, 2.25, 6]}
          rotation-y={Math.PI * 1.01}
          scale={4}
          color={"#bfe0dd"}
        />



      </Environment>
    </group>
  );
}

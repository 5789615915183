import { create } from 'zustand'
import { produce } from 'immer'
import { scenarios } from './data/scenarios.js'

export const useExperienceStore = create((set, get) => ({
  activeScenario: null,
  experienceStarted: false,
  currentRoom: null,
  ressourcesSaved:[],
  visitedRooms:[],
  completedScenarios: [], // Nouvelle propriété pour stocker les scénarios terminés
  scenarios: scenarios,
  redirectObject: null,
  setRedirectObject: (object)=>{
    set({redirectObject:object})
  },
  totalObjectsFound: () => { return get().activeScenario ? get().activeScenario.objects.filter((obj)=>obj.found).length : 0 },
  resetObjectsFound:()=> set({objectsFound:[]}),
  reseRessources:()=>set({ressourcesSaved:[]}),
  setExperienceStart: (bool)=>set({experienceStarted:bool}),
  addRessource: (link)=>{
    if(get().ressourcesSaved.length > 0){
      set((state)=>({
        ressourcesSaved: state.ressourcesSaved.find((ressource)=> ressource.url === link.url) ? [state.ressourcesSaved] : [...state.ressourcesSaved, link] 
      }))
    } else{
      set((state)=>({
        ressourcesSaved: [...state.ressourcesSaved, link]
      }))
    }
  },
  objectsInRoom: ()=>{
    if(!get().activeScenario){
      return []
    }
    const objects = get().activeScenario.objects.filter((obj)=> obj.room === get().currentRoom)
    return  objects
  },
  setObjectFound: (id)=>{
    set(
      produce((state)=>{
        state.activeScenario.objects.find((obj)=>obj.id === id).found = true
      })
    )
  },
  resetAll:()=>{
    set({activeScenario:null,currentRoom:'null',visitedRooms:[]})
  },
  resetExperience:()=>{
    set({activeScenario:null,currentRoom:'hall',visitedRooms:[]})
  },
  setCurrentRoom: (id)=>{
    set({visitedRooms:[...get().visitedRooms,id]})
    set({currentRoom:id})
  },
  completeScenario: (id) => {
    set((state) => ({
      completedScenarios: [...state.completedScenarios, id],
    }));
  },
  setActiveScenario: (id) => {
    const activeScenario =  scenarios.find((scenario)=> scenario.id === id)
    set({activeScenario,visitedRooms:[]})
  }
}))
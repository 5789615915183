import React from "react";
import emitter from "../emitter";
import { useExperienceStore } from "../store";
import styles from "./Logo.module.scss"; //


const Logo = () => {
  const setCurrentRoom = useExperienceStore((state) => state.setCurrentRoom);
  const currentRoom = useExperienceStore((state) => state.currentRoom);
  
  const goToRoom= () => {
    emitter.emit("sound:transition", "hall");
    emitter.emit(
      "buttonClickEvent",
      "redirection",
      "hall",  
      );
    setCurrentRoom("hall");
  };

  const logoClass = (currentRoom !== "hall") ? `${styles.logo} ${styles.logoScenarioActive}` : styles.logo;

  return (
    <button
      onClick={goToRoom}
      style={{ background: "none", border: "none", padding: 0}} className={logoClass}
      aria-label={"Bouton Macif, revenir à la page d'accueil"}
    >
      <svg
        height="69"
        viewBox="0 0 59 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2_2)">
          <path
            d="M59.6462 3.95687V56.0537C50.2228 58.636 40.32 60.0066 30.094 60.0066C19.868 60.0066 9.96513 58.636 0.546997 56.0537V3.95687C9.96513 1.37981 19.868 0.00396718 30.094 0.00396718C40.32 -0.00132453 50.2228 1.37981 59.6462 3.95687Z"
            fill="#0A2D82"
          ></path>
          <path
            d="M36.239 21.356C36.239 24.6845 33.5808 27.3833 30.3077 27.3833C27.0345 27.3833 24.3764 24.6845 24.3764 21.356C24.3764 18.0275 27.0345 15.3288 30.3077 15.3288C33.5808 15.3288 36.239 18.0275 36.239 21.356Z"
            fill="#D3D62E"
          ></path>
          <path
            d="M43.5254 21.356H39.8874C39.5747 21.4089 39.3089 21.6471 39.2359 21.9593C38.9284 26.7006 35.0454 30.4577 30.2973 30.4577C25.351 30.4577 21.3326 26.3884 21.3326 21.356C21.3326 16.5935 24.9341 12.6882 29.5259 12.2913V12.286C29.9481 12.286 30.292 11.9103 30.292 11.4764V8.06324C30.292 7.62932 29.9481 7.28006 29.5259 7.28006V7.27477C22.2186 7.67694 16.4124 13.8312 16.4124 21.356C16.4124 29.1454 22.6304 35.4637 30.3025 35.4637C37.7244 35.4637 43.786 29.5476 44.1717 22.1074C44.156 21.7264 43.885 21.4142 43.5254 21.356Z"
            fill="#D3D62E"
          ></path>
          <path
            d="M15.6984 47.7193C15.6984 47.1425 15.6984 46.4281 15.714 45.7931H15.6984C15.4013 46.1476 15.0729 46.5022 14.5934 46.9467L13.1601 48.3702H13.035L11.6226 46.9467C11.1639 46.5022 10.8147 46.1476 10.5385 45.7931H10.502C10.5228 46.4281 10.5385 47.1372 10.5385 47.7193V51.3176H8.0002V40.3426H8.33377L11.1431 42.9197C11.5861 43.3272 12.519 44.2426 13.181 44.9888H13.2174C13.8429 44.2955 14.8332 43.3272 15.2762 42.9197L17.9969 40.3426H18.3252V51.3176H15.6984V47.7193V47.7193Z"
            fill="white"
          ></path>
          <path
            d="M23.4695 47.7881H25.9087L25.7836 47.5288C25.4657 46.862 25.0852 46.0153 24.6995 44.957H24.6787C24.293 46.0206 23.9073 46.862 23.5946 47.5288L23.4695 47.7881ZM26.8834 49.8836H22.4792L21.7964 51.3176H18.9663L24.4598 40.3426H25.0175L30.5057 51.3176H27.5818L26.8834 49.8836Z"
            fill="white"
          ></path>
          <path
            d="M39.6529 50.2328C38.6626 51.1112 37.469 51.6351 35.9784 51.6351C32.6687 51.6351 30.5057 49.1692 30.5057 45.9836C30.5057 42.5863 32.8407 40.3426 35.8533 40.3426C37.4534 40.3426 38.6626 40.9036 39.6216 41.7449L38.1674 43.6235C37.6097 43.0996 36.9009 42.7451 35.9992 42.7451C34.274 42.7451 33.2264 44.1315 33.2264 45.9783C33.2264 47.878 34.274 49.2221 36.0513 49.2221C36.9165 49.2221 37.6514 48.9258 38.2143 48.3649L39.6529 50.2328Z"
            fill="white"
          ></path>
          <path
            d="M43.5671 40.6654H40.9402V51.3229H43.5671V40.6654Z"
            fill="white"
          ></path>
          <path
            d="M48.3986 43.0679V45.5708H52.4015V47.8251H48.3986V51.3229H45.7718V40.6654H53.5221L53.0061 43.0679H48.3986Z"
            fill="white"
          ></path>
        </g>{" "}
      </svg>
    </button>
  );
};

export default Logo;

import { Howl } from "howler";
import React, { useCallback, useRef, useEffect, useState } from "react";
import styles from "./SoundManager.module.scss";
import { ReactComponent as SoundON } from "./soundOn.svg";
import { ReactComponent as SoundOFF } from "./soundOff.svg";
import emitter from "../../emitter.js";
import { useExperienceStore } from "../../store/index.js";

function SoundManager() {
  const sound = useRef();
  const soundsList = useRef();
  const currentSound = useRef();
  const soundHover = useRef();
  const soundLink = useRef();
  const hasStarted = useRef(false);
  const isPlaying = useRef(false);
  const emitterRegistered = useRef(false);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const currentRoom = useExperienceStore((state) => state.currentRoom);
  const [soundEnabledSvg, setSoundEnabledSvg] = useState(true);

  const loadSound = useCallback(async () => {
    hasStarted.current = true;

    const tmp = {};

    const loadAudio = async (key, src, volume) => {
      tmp[key] = new Howl({
        src: [src],
        loop: true,
        volume: volume,
      });
    };

    await Promise.all([
      loadAudio("jardin", "./sounds/jardin.mp3", 0),
      loadAudio("cuisine", "./sounds/cuisine.mp3", 0),
      loadAudio("garage", "./sounds/garage.mp3", 0),
      loadAudio("chambre", "./sounds/chambre.mp3", 0),
      loadAudio("salon", "./sounds/salon.mp3", 0),
      loadAudio("hall", "./sounds/hall.mp3", 0),
      loadAudio("bureau", "./sounds/bureau.mp3", 0),
    ]);

    soundHover.current = new Howl({
      src: ["./sounds/hover.mp3"],
      loop: false,
      volume: 0.5,
    });
    soundLink.current = new Howl({
      src: ["./sounds/addLink.mp3"],
      loop: false,
      volume: 0.1,
    });

    return tmp; // Renvoie l'objet tmp contenant les sons
  }, [hasStarted]);

  const startExp = async () => {
    if (!hasStarted.current) {
      const snds = await loadSound();
      soundsList.current = snds; // Définit l'état des sons
      soundsList.current.jardin.volume(0);
      soundsList.current.jardin.play();
      soundsList.current.jardin.fade(0, 0.2, 500);
      currentSound.current = soundsList.current.jardin;
      isPlaying.current = true;
    }
  };

  const transitionMusic = (room) => {
    if (currentSound.current) {
      currentSound.current.fade(0.2, 0, 1000);
      currentSound.current.stop(); // Arrête l'instance audio actuelle
    }
    if (soundsList.current[room]) {
      soundsList.current[room].play();
      if (isPlaying.current) {
        soundsList.current[room].fade(0, 0.2, 1000);
      }
       else {
        // Si les sons sont désactivés, assurez-vous que le son de la room est arrêté
        soundsList.current[room].stop();
      }
      currentSound.current = soundsList.current[room];
    }
  };

  const toggleSound = useCallback(() => {
    if (soundsList.current && currentSound.current) {
      if (isPlaying.current) {
        currentSound.current.fade(0.2, 0, 500);
      } else {
        currentSound.current.play(); 
        currentSound.current.fade(0, 0.2, 500);
      }
      setSoundEnabledSvg(!isPlaying.current);
      isPlaying.current = !isPlaying.current;
    }
  }, [isPlaying, setSoundEnabledSvg, loadSound]);

  const onSoundHover = useCallback(() => {
    if (soundHover.current && isPlaying.current) {
      soundHover.current.play();
    }
  }, [soundHover]);

  const onSoundAddLink = useCallback(() => {
    if (soundLink.current && isPlaying.current) {
      soundLink.current.play();
    }
  }, [soundLink]);

  useEffect(() => {
    if (!emitterRegistered.current) {
      emitterRegistered.current = true;
      emitter.on("sound:hover", onSoundHover);
      emitter.on("sound:addLink", onSoundAddLink);
      emitter.on("experience:start", startExp);
      emitter.on("popup:open", startExp);
      emitter.on("sound:transition", transitionMusic);
    }
  }, [onSoundHover, startExp, emitterRegistered]);

  const soundClass = (currentRoom !== "hall") && activeScenario == null   ? `${styles.soundPlayer} ${styles.soundPlayerStart}`
    : styles.soundPlayer;
    console.log(activeScenario)


  return (
    <>
      <button
        className={soundClass}
        onClick={toggleSound}
        aria-label={"Bouton activation et désactivation du son"}
      >
        {soundEnabledSvg ? <SoundON /> : <SoundOFF />}
      </button>
    </>
  );
}

export default SoundManager;

export const objects = [
  {
    id: "produit-bio",
    title: "Le produit bio",
    room: "cuisine",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Bravo, vous avez trouvé le produit bio ! Consommer moins mais mieux, c'est déjà s'engager. La protection de l'océan et de la biodiversité est une autre manière de s'engager pour l'environnement. Si cette cause vous tient à coeur, passez dans le bureau pour découvrir un joli court métrage.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-produit-bio",
      title: "Ordinateur",
    },
  },
  {
    id: "ordinateur-produit-bio",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Voilà, vous serez mieux sur grand écran :",
      },
      {
        type: "youtube",
        urlIframe:
          "https://www.youtube.com/embed/uioPckVvYAw?si=YfZ8EUGB6erREsnR",
      },
      {
        type: "ressource",
        text: "Vidéo - Protéger les océans",
        label: "Vidéo - Protéger les océans",
        url: "https://www.youtube.com/watch?v=uioPckVvYAw",
      },
    ],
  },
  {
    id: "poubelle",
    title: "La poubelle",
    room: "cuisine",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: `🤗, vous avez trouvé la poubelle ! Vous avez envie de passer à l'action sans savoir par où commencer ? Connectez-vous à la plateforme diffuz.com pour découvrir, dans toute la France, comment vous pouvez être utile et relever votre 1er défi solidaire tel que le défi "Adieu les bouteilles en plastique, bonjour la gourde"`,
      },
      {
        type: "ressource",
        text: "Connexion - Plateforme Diffuz Macif",
        label: "Connexion - Plateforme Diffuz Macif",
        url: "https://www.diffuz.com/defi/defi--15---adieu-les-bouteilles-plastic--bonjour-la-gourde-9450672",
      },
    ],
  },
  {
    id: "cle",
    title: "Les clés",
    room: "garage",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Bravo, vous avez trouvé les clés de voiture ! Vous souhaitez vous lancer dans le covoiturage mais sans trop savoir par où commencer ? Découvrez Mobicoop, le partenaire de co-voiturage que la Macif vous propose : pas de commission sur les trajets ! Mettez l'article dans votre panier pour y revenir après le jeu",
      },
      {
        type: "ressource",
        text: "Page - Mobicoop, le covoiturage libre",
        label: "Page - Mobicoop, le covoiturage libre",
        url: "https://www.macif.fr/assurance/particuliers/assurance-auto-moto-scooter/assurance-automobile/covoiturage-et-location-entre-particuliers",
      },
    ],
  },
  {
    id: "plante",
    title: "La plante",
    room: "garage",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Bien joué, vous avez trouvé la plante ! Avez-vous déjà entendu parler de la mobilité douce ou de l'écomobilité ? La Macif vous accompagne et vous conseille sur toutes les mobilités douces : vélo électrique, trottinette, ... quel que soit votre choix, découvrez en plus ici.",
      },
      {
        type: "ressource",
        text: "Article - L'écomobilité",
        label: "Article - L'écomobilité",
        url: "https://vousparmacif.macif.fr/ecomobilite-c-est-quoi",
      },
    ],
  },
  {
    id: "canette",
    title: "La canette",
    room: "cuisine",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Quand les enfants deviennent des ados, les rapports changent et ce n'est pas toujours évident de savoir comment leur parler. Y a-t-il une solution miracle ? Réponses de parents dans cet article.",
      },
      {
        type: "ressource",
        text: "Article - Parler aux ados",
        label: "Article - Parler aux ados",
        url: "https://vousparmacif.macif.fr/choses-faire-ni-dire-ado",
      },
    ],
  },
  {
    id: "berceau",
    title: "Le berceau",
    room: "chambre",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Bravo !<br>Devenir parent c'est une grande aventure qui met parfois le sommeil à rude épreuve. Mais ce n’est pas une fatalité et des astuces existent.<br>Direction le bureau pour consulter 1mn30 de vidéo.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-berceau",
      title: "Ordinateur",
    },
  },
  {
    id: "ordinateur-berceau",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Voilà, vous serez mieux sur grand écran :",
      },
      {
        type: "youtube",
        urlIframe: "https://www.youtube.com/embed/a3s35v3r40E",
      },
      {
        type: "text",
        text: "Ça vous a plu ? Alors mettez cette vidéo dans votre panier pour pouvoir la retrouver en fin d'expérience.",
      },
      {
        type: "ressource",
        label: "Vidéo - La parentalité à l'honneur",
        url: "https://www.macif.fr/assurance/particuliers/les-moments-cles/la-famille-sagrandit/la-parentalite-a-lhonneur",
      },
    ],
  },
  {
    id: "trousse-soin",
    title: "Trousse de soin",
    room: "chambre",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "👏Parce qu’on ne sait jamais ce qui peut arriver à ses proches, c’est toujours mieux d’être préparé !<br>Contribuez à sauver des vies et protégez vos proches en vous formant aux gestes qui sauvent grâce à l'application Salvum, la Macif vous en offre l'accès.",
      },
      {
        type: "ressource",
        text: "Application - Salvum",
        url: "https://formation.salvum.org/forms/#/register?organization=macifsocietaires&promocode=MERCIMACIF",
        cg: "Offre réservée aux sociétaires MACIF ayant reçu le présent e-mailing, contenant un lien personnel donnant accès gratuitement à SALVUM, formation multimédia au secourisme, pendant un an à compter de toute inscription au service réalisée avant le 31/12/2024 inclus. Salvum est une application multi support (ordinateur, tablette et smartphone). Le support technique est assuré par Salvum à l'adresse suivante : support@salvum.org.  Salvum SAS - 1 avenue Christian Doppler - bâtiment 3 - 77700 SERRIS - RCS Meaux 801 777 541 - SAS au capital de 451 520 € - TVA Intracommunautaire FR 64 801777541.",
      },
    ],
  },
  {
    id: "canape-animal",
    title: "Le panier",
    room: "chambre",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Un chien ou un chat c’est bien plus qu’un animal, c’est un membre de la famille. Veiller sur sa santé est primordial et ça passe par une bonne alimentation, des soins réguliers, des activités adaptées et bien plus encore. Découvrez les conseils d’un vétérinaire.",
      },
      {
        type: "ressource",
        text: "Article - Santé et bien-être animal",
        url: "https://vousparmacif.macif.fr/pour-veiller-sante-et-au-etre-animal",
      },
    ],
  },
  {
    id: "blocknote",
    title: "Le bloc note",
    room: "chambre",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Vous avez trouvé le bloc note, vous avez l'oeil ! Bien préparer son déménagement c'est être serein le jour J. Notre pro de l'organisation vous donne ses meilleures astuces. Soufflez 1 mn, le temps de regarder cette vidéo.",
      },
      {
        type: "youtube",
        urlIframe:
          "https://www.youtube.com/embed/sJQscaq1Q1c?si=Vfwgw6DEUVjWyYVV",
      },
      {
        type: "ressource",
        text: "Vidéo - Préparer son déménagement",
        url: "https://www.youtube.com/watch?v=sJQscaq1Q1c&t=0s",
      },
    ],
  },
  {
    id: "carton",
    title: "Le carton",
    room: "chambre",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Bravo ! Un carton c’est très utile, surtout pour un déménagement. Et ce n’est pas le seul objet qui peut vous rendre service dans cette situation. Rendez-vous dans la cuisine pour trouver la checklist !",
      },
    ],
    redirectObject: {
      room: "cuisine",
      id: "checklist",
      title: "La checklist",
    },
  },
  {
    id: "cv",
    title: "Le CV",
    room: "salon",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Bien vu, vous avez trouvé le CV ! <br> Saviez-vous que 36% des français sont insatisfaits de leur travail ? Parfois changer de job c'est nécessaire mais c'est aussi courageux. Et quand on doit refaire son CV ce n'est pas toujours évident de savoir quoi mettre dedans. Justement : rendez-vous dans le bureau, on vous aide à y voir plus clair.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-cv",
      title: "Ordinateur",
    },
  },
  {
    id: "tablet",
    title: "La tablette",
    room: "salon",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: `Bien joué ! Dans le parcours professionnel, de plus en plus de personnes choisissent la voie de l'entrepreuneuriat. Simplifiez-vous les démarches en découvrant nos conseils sur la création d'entreprise.`,
      },
      {
        type: "ressource",
        text: `Conseils - La création d'entreprise`,
        url: "https://mon-entreprise.macif.fr/",
      },
      {
        type: "text",
        text: `Entre l’immatriculation, les déclarations, la domiciliation, le choix du statut, il faut penser à tout ! Et s’il existait un espace où tout est prévu pour répondre à vos questions sur la création d’entreprise ?`,
      },
    ],
  },
  {
    id: "boiteoutils",
    title: "Boîte à outils",
    room: "garage",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Super !<br/>Se lancer dans des travaux c’est chouette mais quand on peut prétendre à des aides financières c’est encore mieux. Vous ne savez pas à qui vous adresser ? Klaro vous aide à y voir plus clair et vous permet de faire une simulation d’aides en ligne pour vérifier votre éligibilité.<br/>À retrouver en fin d’expérience<br/>",
      },
      {
        type: "ressource",
        text: `Site - KLARO Aides financières`,
        url: "https://app.klaro.fr/bienvenue/v1/company/Macif_la_porte_de_vos_envies/88e6a292-c3d2-4055-91fb-831e64892c4d",
        cg: "Ce lien est à usage personnel. Offre réservée aux 500 premiers sociétaires destinataires des envois emails et sms ayant activé leur compte Klaro. Une fois ce nombre atteint, l'activation par ce lien ne sera plus possible. Le site Klaro est édité par la société TOUS MES DROITS, SAS au capital social de 1 000 €. RCS Bobigny 838571206. Siège social : 12 rue Anselme, 93400 Saint-Ouen.",
      },
    ],
  },
  {
    id: "papierpeint",
    title: "Le papier peint",
    room: "garage",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Vous avez l’oeil !<br/>Tri, désencombrement, méthode feng shui ou AIBI : voici quelques méthodes pour aménager votre intérieur sans envisager de travaux. <br/>Retrouvez plus de détails ici.",
      },
      {
        type: "ressource",
        text: `Article - Aménager son intérieur`,
        url: "https://vousparmacif.macif.fr/amenager-interieur-logement",
      },
    ],
  },
  {
    id: "rangement",
    title: "Le rangement",
    room: "salon",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Un grand bravo ! Se sentir bien chez soi, ça passe aussi par de petits changements comme une maison bien organisée et qui respire. Justement : respirez 1 min le temps de découvrir nos 4 conseils de pro en vidéo pour bien organiser votre intérieur. <br/>Direction le bureau, vous y serez plus à l'aise pour la regarder.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-rangement",
      title: "Ordinateur",
    },
  },
  {
    id: "telephone",
    title: "Le téléphone",
    room: "salon",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: `👌 Besoin d’une prestation de service ou de louer du matériel ? Bricolage, travaux, déménagement, garde d'animaux... Avec Allovoisins, trouvez des professionnels et/ou des particuliers proches de chez vous pour répondre à tout type de besoins. C'est pratique, économique et ça vous facilite le quotidien ! Apprenez en plus ici :`,
      },
      {
        type: "ressource",
        text: `Page - Service entre particuliers`,
        url: "https://www.macif.fr/assurance/particuliers/assurance-habitation-et-vie-quotidienne/services-entre-particuliers",
        cg: "Voir détail et tarifs des prestations dans les Conditions Générales de vente et d’utilisation disponibles sur le site allovoisins.com. Le site Internet www.allovoisins.com est édité par la Société 2CED, SAS au capital de 286.020 euros, RCS Nantes 792 209 967, siège social 1 Rue Victor Hugo, Immeuble Agora, 44400 REZE.",
      },
    ],
  },
  {
    id: "carte-assurance",
    title: "La carte",
    room: "bureau",
    scenario: ["change-horizon"],
    content: [
      {
        type: "text",
        text: "😊 Vous avez trouvé la carte européenne d’assurance maladie ! Savez-vous comment vous faire rembourser vos frais médicaux si vous avez besoin d’un médecin à l’étranger ?",
      },
      {
        type: "ressource",
        text: `FAQ - Actes médicaux à l'étranger`,
        url: "https://www.macif.fr/assurance/particuliers/faq?question=remboursez-vous-actes-medicaux-letranger",
      },
    ],
  },
  {
    id: "lunettes",
    title: "Les lunettes",
    room: "cuisine",
    scenario: ["change-horizon"],
    content: [
      {
        type: "text",
        text: "Vous avez trouvé les lunettes de soleil ! <br> Si vous partez en vacances avec des ados, sachez qu’ils sont très réceptifs au principe du donnant-donnant : je te fais plaisir et, en retour, tu me fais plaisir. Découvrez des conseils détaillés en mettant l'article dans votre panier, pour que toute la famille profite du séjour !",
      },
      {
        type: "ressource",
        text: `Article - Partir avec des ados`,
        url: "https://vousparmacif.macif.fr/partir-avec-ados-3-conseils-pour-vacances-reussies",
      },
    ],
  },
  {
    id: "checklist",
    title: "La checklist",
    room: "cuisine",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Vous l'avez trouvée ! Rien de plus pratique qu'une checklist pour penser à toutes les démarches comme l'autorisation de stationnement ou les ouvertures de compteurs. Pour vous simplifier la tâche, on a listé toutes les principales formalités à effectuer. On vous montre ?",
      },
      {
        type: "ressource",
        text: "Checklist - Déménagement",
        url: "https://www.macif.fr/files/live/sites/maciffr/files/maciffr/Particuliers/Momentscles/Demenagement/CHECKLIST_LOCATAIRE.pdf",
      },
    ],
  },
  {
    id: "ordinateur-cv",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "On a tous des compétences à mettre en avant dans un CV mais savez-vous que 60% des recruteurs disent privilégier les compétences comportementales aux compétences techniques ? Si vous ne savez pas trop comment identifier vos compétences comportementales, Jobready est fait pour vous. Pour en savoir plus, vous pouvez mettre le lien dans votre panier",
      },
      {
        type: "ressource",
        text: "Page - compétences comportementales Jobready",
        url: "https://www.macif.fr/assurance/particuliers/les-moments-cles/entree-dans-la-vie-active/te-former/jobready",
        cg: "Jobready est un programme porté par l'association Article 1 (fusion des associations Frateli et Passeport Avenir), association d'intérêt général située au 29 Boulevard Bourdon, 75004 PARIS.",
      },
    ],
  },
  {
    id: "fontaine",
    title: "La fontaine zen",
    room: "bureau",
    scenario: ["change-horizon"],
    content: [
      {
        type: "text",
        text: `Super, vous avez trouvé la fontaine zen ! Les vacances vont parfois de pair avec de longues heures de route. Stress, distraction ou encore fatigue peuvent avoir des conséquences sur notre conduite. Il existe des techniques pour nous aider à agir. L'entraînement attentionnel consiste à focaliser son attention et à rester plus attentif au moment présent, une bonne manière d'apprendre à rester plus calme et attentif au volant. N'hésitez pas à mettre la vidéo dans votre panier pour la consulter en fin d'expérience.`,
      },
      {
        type: "ressource",
        text: `Vidéo - Entraînement attentionnel`,
        url: "https://youtu.be/kWJiQEjLU98",
      },
    ],
  },
  {
    id: "ordinateur-rangement",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Installez-vous confortablement et ouvrez grands les yeux",
      },
      {
        type: "youtube",
        urlIframe:
          "https://www.youtube.com/embed/NKAGq1CbRoQ?si=XZ3PC0np27q8w7is",
      },
      {
        type: "ressource",
        text: "Vidéo - Organiser son intérieur",
        url: "https://www.youtube.com/watch?v=NKAGq1CbRoQ&t=5s",
      },
    ],
  },
];
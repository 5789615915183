import React from 'react';
import styles from "./Logo.module.scss"

function MentionsLegales({ closeFn }) {
    return (
    <div className={styles.MentionsLegales}> <h1>Mentions Légales</h1>
          <button onClick={closeFn} className={styles.closeButton}>Fermer</button>




          <h2>Présentation générale du site</h2>

<p>
L’expérience immersive développée par Vokode pour la Macif est destinée à être utilisée par les sociétaires de la Macif : "il s agit d une navigation au coeur d'une maison permettant de découvrir des conseils, informations et services...
Les informations proposées sur le présent site sont fournies à titre indicatif et n'ont pas de valeur contractuelle. Elles ne peuvent être assimilées à des conseils.
Tous les droits de reproduction sont réservés, pour tous les éléments du site qu'ils soient textes, images ou sons, y compris pour les documents téléchargeables. La reproduction de tout ou partie de ce site sur un support quel qu'il soit est formellement interdite sauf autorisation expresse de la Macif. Les liens hypertextes dirigés vers ce site de la Macif doivent faire l’objet d’une autorisation expresse et préalable de la Macif.
</p>

<h2>Editeur</h2>

<p>
Editeur : MACIF (Mutuelle Assurance des Commerçants et Industriels de France et des cadres et des salariés de l'Industrie et du Commerce. Société d'assurance mutuelle à cotisations variables. Entreprise régie par le code des Assurances)

Siège social MACIF : 1 rue Jacques Vandier - 79000 Niort

Directeur de la publication : Jean-Philippe DOGNETON, Directeur général Macif

Hébergement

L'hébergement du site MACIF et le stockage des informations sont situés à :
MACIF 1 rue Jacques Vandier 79000 Niort
</p>

<h2>Crédits</h2>

<p>
Le présent site a été développé par Vokode SAS au capital de 3000 euros, RCS Paris 848 101 945. Siège social : 14 rue Milton – 75009 Paris - N° de TVA : FR18848101945
</p>
    </div>
  );
}

export default MentionsLegales;
import { useEffect } from 'react';
import emitter from '../emitter';

const useInactivityTimer = (iconName) => {
  useEffect(() => {

    let inactivityTimer;

    const handleActivity = () => {
      emitter.emit("info:helpclose");

      clearTimeout(inactivityTimer);

      inactivityTimer = setTimeout(() => {
        emitter.emit("info:helpopen", { icon: iconName });
      }, 2000);
    };

    // inactivityTimer = setTimeout(() => {
    //   emitter.emit("info:helpopen", { /* Votre contenu */ });
    // }, 1000);

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
      clearTimeout(inactivityTimer);
    };
  }, [iconName]);
};

export default useInactivityTimer;

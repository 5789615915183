import React, { useCallback, useEffect } from "react";
import "./App.css";
import ExperienceUi from "./ExperienceUi";
import "./styles/base.scss";
import "./styles/text.scss";
import Logo from "./components/Logo";
import SceneManager from "./SceneManager";
import emitter from "./emitter";

function App() {
  const pageViewEvent = useCallback((room, scenario) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "page_view",
      page_chapter1: `${room}`,
      challenge_type: `${scenario}`,
    });
  }, []);

  const findObjectEvent = useCallback((product, scenario, room) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.action",
      click: `object_trouve`,
      product_id: `${product.id}`,
      product: `${product}`,
      challenge_type: `${scenario}`,
      page_chapter1: `${room}`,

    });
  }, []);

  const buttonClickEvent = useCallback((libelle, room, scenario) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.navigation",
      click: `${libelle}`,
      page_chapter1: `${room}`,
      challenge_type: `${scenario}`,

    });
  }, []);

  const cartClickEvent = useCallback(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.action",
      click: `bouton`,
    });
  }, []);

  const objectCartClickEvent = useCallback((product) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.action",
      click: `contenu_panier`,
      click_product: `${product}`,
    });
  }, []);

  const clickScenarioEvent = useCallback((scenario, clicktime) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.action",
      click: `clickScenarioEvent`,
      challenge_type: `${scenario}`,
      click_type: `${clicktime}`

    });
  }, []);

  const clickRdvEvent = useCallback((click) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.action",
      click: `clickRdvEvent`,
      click_type: `${click}`

    });
  }, []);

  const clickAvousDeJouer = useCallback(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "click.action",
      click: `a_vous_de_jouer`,
    });
  }, []);





  useEffect(() => {
    emitter.on("pageViewEvent", pageViewEvent);
    emitter.on("findObjectEvent", findObjectEvent);
    emitter.on("buttonClickEvent", buttonClickEvent);
    emitter.on("cartClickEvent", cartClickEvent);
    emitter.on("objectCartClickEvent", objectCartClickEvent);
    emitter.on("clickScenarioEvent", clickScenarioEvent);
    emitter.on("RDV", clickRdvEvent);
    emitter.on("experience:start", clickAvousDeJouer);



  }, []);

  return (
    <div className="App">
      <Logo />
      <ExperienceUi />
      <SceneManager />
    </div>
  );
}

export default App;

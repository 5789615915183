import React, { useCallback, useRef, useEffect, useState } from "react";
import styles from "./PopupInfo.module.scss";
import { gsap } from "gsap";
import { useExperienceStore } from "../../store";
import { rooms } from "../../store/data/rooms";
import emitter from "../../emitter.js";
import Button from "../Shared/Button";

function PopupInfo() {
  const wrapperRef = useRef();
  const setScenario = useExperienceStore((state) => state.setActiveScenario);
  const addRessource = useExperienceStore((state) => state.addRessource);
  const setCurrentRoom = useExperienceStore((state) => state.setCurrentRoom);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const currentRoom = useExperienceStore((state) => state.currentRoom);
  const visitedRooms = useExperienceStore((state) => state.visitedRooms);
  const objectsInRoom = useExperienceStore((state) => state.objectsInRoom());
  const ressourcesSaved = useExperienceStore((state) => state.ressourcesSaved);
  const setRedirectObject = useExperienceStore(
    (state) => state.setRedirectObject,
  );
  const [isAnimating, setIsAnimating] = useState(false);
  const emitterRegistered = useRef(false);
  const completedScenarios = useExperienceStore((state) => state.completedScenarios)

  const [info, setInfo] = useState(null);

  useEffect(() => {
    // Bind the event listener
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        wrapperRef.current.style.opacity == 1
      ) {
        animateOut();
      }

    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    gsap.set(wrapperRef.current, {
      y: 40,
    });

  }, [wrapperRef]);

  const animateOut = useCallback(() => {
    setIsAnimating(true);
    wrapperRef.current.setAttribute("aria-hidden", "true");
    return gsap
      .to(wrapperRef.current, {
        autoAlpha: 0,
        y: 40,
        ease: "expo.out",
        duration: 0.5,
      })
      .then(() => {
        setInfo(null);
        setIsAnimating(false);
        emitter.emit("setInfoState", false);
      });
  }, [setIsAnimating]);

  const animateIn = useCallback(() => {
    setIsAnimating(true);
    wrapperRef.current.setAttribute("aria-hidden", "false");
    // Définir le point d'origine de la transformation au coin inférieur droit
    gsap.set(wrapperRef.current, {
      transformOrigin: "bottom right",
    });

    return gsap.timeline({ onComplete: () => setIsAnimating(false) }).fromTo(
      wrapperRef.current,
      {
        autoAlpha: 0,
        scale: 1, // Commencez réduit à un point
        y: 100,
        x: 0,
      },
      {
        autoAlpha: 1,
        scale: 1, // Étendre à la taille réelle
        y: 0, // Déplacer à la position Y finale
        x: 0, // Déplacer à la position X finale
        ease: "expo.out",
        duration: 1,
      },
    );
  }, [setIsAnimating]);

  const saveRessource = useCallback(
    (ressource) => {
      addRessource(ressource);
      emitter.emit("cartClickEvent");
      emitter.emit("sound:addLink");
    },
    [addRessource],
  );

  const handleLinkClick = useCallback((url, text) => {
    // Pousser l'événement dans le Data Layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click.action',
      click: 'objet_lien',
      click_product: text,
    });
    // Ouvrir le lien dans un nouvel onglet
    window.open(url, '_blank');
  }, []);



  const redirectTo = useCallback((redirectObject) => {
    setRedirectObject(redirectObject);

    goToRoom(redirectObject.room);
  });

  const initScenario = useCallback(
    (id) => {
      if (activeScenario && activeScenario.id) {
        emitter.emit("clickScenarioEvent", activeScenario.id, "fin");
      }
      emitter.emit("clickScenarioEvent", id, "debut");
      setScenario(id);
      animateOut();
    },
    [setScenario, animateOut],
  );

  const goToRoom = useCallback(
    (id) => {
      emitter.emit("sound:transition", id);

      animateOut();
      if (info.scenario) {
        initScenario(info.scenario.id);
      }
      emitter.emit(
        "buttonClickEvent",
        "redirection",
        id,
        info.scenario ? info.scenario.id : null,
      );
      setCurrentRoom(id);
    },
    [info, initScenario, setCurrentRoom, animateOut],
  );

  const onPopupOpen = useCallback(
    (infoIn) => {
      console.warn("onPopupOpen", infoIn);
      animateOut().then(() => {
        setInfo(infoIn);
        emitter.emit("setInfoState", true);
        animateIn();


      });
    },
    [setInfo, animateIn, animateOut],
  );

  useEffect(() => {
    if (!emitterRegistered.current) {
      emitterRegistered.current = true;
      console.warn("Register emitter");
      emitter.on("popup:open", onPopupOpen);
    }
  }, [onPopupOpen]);

  const handleClickRdv = () => {
    // Émettre un événement personnalisé
    emitter.emit('RDV', 'Click RDV');

    // Ouvrir le lien dans un nouvel onglet
    window.open("https://agence.macif.fr/assurance/#pf:CODE_TRACKING#", "_blank");
  };

  return (
    <>

      {info && (
        <div className={styles.overlay}></div>
      )}

      <div
        ref={wrapperRef}
        className={`${styles.popupInfo} ${info && (info.type === "finish" || (info && info.scenario && completedScenarios.includes(info.scenario.id))) ? styles.popupInfoFinish : ""
          } ${info && (info.type === "finish" || (info && info.scenario && completedScenarios.length === 5)) ? styles.popupInfoCompleted : ""
          }`}
        aria-hidden={true}
      >

        <button
          className={styles.popupClose}
          aria-label={"Bouton fermer la modale du contenu de context"}
          onClick={() => animateOut()}
        >
          <svg width="40" height="40" viewBox="0 0 182 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="91" cy="91" r="86" stroke="#0A2D82" stroke-width="13" />
            <path d="M129.891 52.1084L52.1089 129.89" stroke="#0A2D82" stroke-width="13" />
            <path d="M129.891 129.891L52.1089 52.1089" stroke="#0A2D82" stroke-width="13" />
          </svg>
        </button>
        {info && info.type === "object" && (
          <>
            <p className={styles.popupInfoTitle} aria-live={"polite"}>
              {info.object.title}
            </p>
            {info.object.content &&
              info.object.content.map((content, i) => (
                <>

                  {content.type === "text" && (
                    <p
                      aria-live={"polite"}
                      key={`${content.type}-${i}`}
                      className={styles.popupInfoText}
                      dangerouslySetInnerHTML={{ __html: content.text }}
                    ></p>
                  )}
                  {content.type === "youtube" && (
                    <div className={styles.popupIframe}>
                      <iframe
                        key={`${content.type}-${i}`}
                        title="Video"
                        src={content.urlIframe}
                        allowFullScreen={true}
                      ></iframe>
                    </div>
                  )}
                  {content.type === "ressource" && (
                    <>
                      <div
                        key={`${content.type}-${i}`}
                        className={styles.popupRessource}
                      >
                        <p>{content.label || content.text}</p>

                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault(); // Empêcher le comportement par défaut du lien
                            handleLinkClick(content.url, content.text); // Remplacer 'Nom de la ressource ou label' par une description appropriée
                          }}
                          aria-label={content.label}
                          rel="noreferrer"
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_728_6678)">
                              <path
                                d="M14 8.39844V12.247C14 12.5654 13.932 12.8559 13.7898 13.1218C13.6476 13.3876 13.459 13.6009 13.221 13.7586C12.983 13.9162 12.7233 13.9966 12.4451 13.9966H1.55487C1.27357 13.9966 1.017 13.9162 0.77898 13.7586C0.540958 13.6009 0.352396 13.3876 0.210201 13.1218C0.0680062 12.8559 0 12.5654 0 12.247V1.74929C0 1.43399 0.0680062 1.14032 0.210201 0.87448C0.352396 0.608638 0.537867 0.395346 0.775889 0.237695C1.01391 0.0800442 1.27357 -0.000326779 1.55178 -0.000326779H5.59815C5.79289 -0.000326779 5.95981 0.0676794 6.09583 0.203692C6.23184 0.339704 6.29985 0.503537 6.29985 0.701373C6.29985 0.899209 6.23184 1.06304 6.09583 1.19905C5.96291 1.33507 5.79598 1.40307 5.59815 1.40307H1.80526C1.69397 1.40307 1.59815 1.44635 1.51777 1.53599C1.44049 1.62564 1.40031 1.73383 1.40031 1.86675V12.1419C1.40031 12.2717 1.44049 12.383 1.51777 12.4695C1.59815 12.5592 1.69397 12.6025 1.80526 12.6025H12.2071C12.3091 12.6025 12.4019 12.5561 12.4791 12.4634C12.5564 12.3706 12.5966 12.2624 12.5966 12.1419V8.40462C12.5966 8.20987 12.6646 8.04295 12.8006 7.90694C12.9366 7.77092 13.1036 7.70292 13.2983 7.70292C13.493 7.70292 13.66 7.77092 13.796 7.90694C13.9289 8.04295 14 8.20678 14 8.40462V8.39844ZM11.5085 1.39998L6.39876 6.50972C6.2473 6.6581 6.17311 6.84048 6.17311 7.05686C6.17311 7.27324 6.2473 7.45253 6.39876 7.604C6.54714 7.75238 6.72952 7.82657 6.9459 7.82657C7.16229 7.82657 7.34158 7.75238 7.49304 7.604L12.6028 2.49426V4.2006C12.6028 4.39535 12.6708 4.56227 12.8068 4.69828C12.9428 4.83429 13.1097 4.9023 13.3045 4.9023C13.4992 4.9023 13.6662 4.83429 13.8022 4.69828C13.9351 4.56227 14.0062 4.39844 14.0062 4.2006V0.698282C14.0062 0.503537 13.9382 0.336613 13.8022 0.200601C13.6662 0.0645882 13.5023 -0.00341797 13.3045 -0.00341797H9.80525C9.61051 -0.00341797 9.44359 0.0645882 9.30757 0.200601C9.17465 0.336613 9.10356 0.500446 9.10356 0.698282C9.10356 0.896118 9.17156 1.05995 9.30757 1.19596C9.44359 1.33198 9.61051 1.39998 9.80525 1.39998H11.5116H11.5085Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_728_6678">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <button onClick={() => saveRessource(content)}>
                          {ressourcesSaved &&
                            !ressourcesSaved.find(
                              (r) => r.url === content.url,
                            ) && (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 16 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M15.6156 3.07078C15.2953 2.71646 14.8549 2.48024 14.3744 2.48024H8.20821C8.00801 2.48024 7.80781 2.63772 7.80781 2.87393C7.80781 3.11015 8.00801 3.26762 8.20821 3.26762H14.3744C14.6146 3.26762 14.8549 3.38573 15.015 3.5432C15.1752 3.74005 15.2553 3.97626 15.1752 4.21248L14.8148 6.18092H10.4104C10.2102 6.18092 10.01 6.3384 10.01 6.57461C10.01 6.81083 10.2102 6.9683 10.4104 6.9683H14.6947L14.3343 8.85801C14.2943 9.13359 14.014 9.33044 13.7337 9.33044H6.04605C5.76577 9.33044 5.52553 9.13359 5.44545 8.85801L3.88388 0.314952C3.84384 0.118107 3.68368 0 3.48348 0H0.4004C0.2002 0 0 0.157476 0 0.393689C0 0.629903 0.2002 0.787379 0.4004 0.787379H3.16316L4.68469 9.05486C4.76477 9.60602 5.16517 9.99971 5.68569 10.1572C5.48549 10.4328 5.32533 10.7871 5.32533 11.1808C5.32533 12.165 6.12613 12.9524 7.12713 12.9524C8.12813 12.9524 8.92893 12.165 8.92893 11.1808C8.92893 10.8265 8.80881 10.4721 8.60861 10.1966H11.0511C10.8509 10.4721 10.7307 10.8265 10.7307 11.1808C10.7307 12.165 11.5315 12.9524 12.5325 12.9524C13.5335 12.9524 14.3343 12.165 14.3343 11.1808C14.3343 10.7871 14.2142 10.4328 14.014 10.1572C14.5345 10.0391 14.975 9.60602 15.0951 9.05486L15.976 4.40932C16.0561 3.93689 15.9359 3.46447 15.6156 3.07078ZM8.12813 11.1414C8.12813 11.6926 7.68769 12.1256 7.12713 12.1256C6.56657 12.1256 6.12613 11.6926 6.12613 11.1414C6.12613 10.5902 6.56657 10.1572 7.12713 10.1572C7.68769 10.1572 8.12813 10.5902 8.12813 11.1414ZM12.5726 12.1256C12.012 12.1256 11.5716 11.6926 11.5716 11.1414C11.5716 10.5902 12.012 10.1572 12.5726 10.1572C13.1331 10.1572 13.5736 10.5902 13.5736 11.1414C13.5736 11.6926 13.1331 12.1256 12.5726 12.1256Z" fill="#0A2D82" />

                                <defs>
                                  <clipPath id="clip0_739_2">
                                    <rect
                                      width="15.3061"
                                      height="12.9643"
                                      fill="white"
                                      transform="translate(0.102051 7.08936)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            )}
                        </button>
                      </div>
                      {content.cg && (
                        <>
                          <input
                            type="checkbox"
                            id="showCG"
                            name="showCG"
                            className={styles.showCG}
                          />
                          <label for="showCG" className={styles.showCGLabel}>
                            Voir conditions de l'offre et mentions légales
                          </label>
                          <p className={styles.popupCg}>{content.cg}</p>
                        </>
                      )}
                    </>
                  )}
                </>
              ))}

            <div className={styles.popupActions}>
              <p className={styles.popupInfoText}>
                {!info.object.redirectObject && (
                  <>
                    {objectsInRoom.find((obj) => !obj.found) &&
                      `Il vous reste d’autres objets à découvrir dans ${rooms[currentRoom]
                        ? rooms[currentRoom].title
                        : "cette pièce"
                      },  restez en alerte ;)`}
                    {objectsInRoom.every((obj) => obj.found) &&
                      activeScenario &&
                      activeScenario.objects.find((obj) => !obj.found) &&
                      `Vous avez trouvé tous les indices qui se cachaient ici, mais il vous reste des objets à découvrir dans ${rooms[
                        activeScenario.objects.find((obj) => !obj.found).room
                      ]
                        ? rooms[
                          activeScenario.objects.find((obj) => !obj.found)
                            .room
                        ].title
                        : "les autres pièces"
                      }...`}
                    {objectsInRoom.every((obj) => obj.found) &&
                      activeScenario &&
                      activeScenario.objects.every((obj) => obj.found) &&
                      "Il ne reste plus d’indice à découvrir ici mais le hall d’entrée en cache d’autres qui n’attendent que vous pour être trouvés..."}
                  </>
                )}
              </p>
              <div className={styles.scenarioCtas}>
                {info.object.redirectObject ? (
                  <>
                    {objectsInRoom.find((obj) => !obj.found) &&
                      <Button
                        label={`Poursuivre dans ${rooms[currentRoom].title}`}
                        primary={true}
                        side={"left"}
                        onClick={() => animateOut()}
                      />}
                    <Button
                      label={`Aller dans ${rooms[info.object.redirectObject.room].title
                        }`}
                      primary={false}
                      side={"right"}
                      onClick={() => redirectTo(info.object.redirectObject)}
                    />
                  </>
                ) : (
                  <>
                    {objectsInRoom.find((obj) => !obj.found) && (
                      <Button
                        label={`Poursuivre dans ${rooms[currentRoom].title}`}
                        primary={true}
                        side={"left"}
                        onClick={() => animateOut()}
                      />
                    )}
                    {objectsInRoom.every((obj) => obj.found) &&
                      activeScenario &&
                      activeScenario.objects.find((obj) => !obj.found) && (
                        <Button
                          label={`Aller dans ${rooms[
                            activeScenario.objects.find((obj) => !obj.found)
                              .room
                          ]
                            ? rooms[
                              activeScenario.objects.find(
                                (obj) => !obj.found,
                              ).room
                            ].title
                            : ""
                            }`}
                          primary={true}
                          side={"right"}
                          onClick={() =>
                            goToRoom(
                              activeScenario.objects.find((obj) => !obj.found)
                                .room,
                            )
                          }
                        />
                      )}
                    {objectsInRoom.every((obj) => obj.found) &&
                      activeScenario &&
                      activeScenario.objects.every((obj) => obj.found) && (
                        <Button
                          label={`Aller dans le hall`}
                          primary={true}
                          side={"right"}
                          onClick={() => goToRoom("hall")}
                        />
                      )}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {info && info.type === "scenario" && (
          <>
            {(completedScenarios.includes(info.scenario.id)
            ) ? (
              <>
                <div className={styles.scenarioImage}>
                  <img
                    src={info.scenario.image}
                    alt={`Image de la quête : ${info.scenario.title}`}
                  />
                  <div className={styles.scenarioHead}>
                    <p className={styles.questLabel}>Quête</p>

                    <p className={styles.popupInfoTitle} aria-live={"polite"}>
                      {info.scenario.title}
                    </p>
                  </div>
                </div>
                <div className={styles.separationLine}></div>

                <p className={styles.popupInfoTitle}>Félicitations !</p>

                <p className={styles.popupInfoText} aria-live={"polite"}>
                  🥳 🥳 🥳 : vous avez terminé la quête "{info.scenario.title}" !
                  <br />
                  <br />À vous de choisir la suite de votre parcours :
                  <br />- découvrir les contenus collectés dans votre panier,
                  <br />- cliquer sur d'autres objets dans ce hall pour lancer une
                  nouvelle quête.
                </p>
                <p className={styles.popupInfoText}>
                  Dans tous les cas, nous restons à vos côtés. Quels que soient vos
                  projets, nous sommes à votre écoute : contactez votre conseiller
                  Macif.
                </p>

                <button
                  className={styles.endCta}
                  onClick={handleClickRdv}
                  target="_blank"
                >
                  Je prends rendez-vous
                </button>
              </>
            ) : (<>  <div className={styles.scenarioImage}>
              <img
                src={info.scenario.image}
                alt={`Image de la quête : ${info.scenario.title}`}
              />
              <div className={styles.scenarioHead}>
                <p className={styles.questLabel}>Quête</p>

                <p className={styles.popupInfoTitle} aria-live={"polite"}>
                  {info.scenario.title}
                </p>
              </div>

            </div>

              <p
                className={styles.popupInfoText}
                aria-live={"polite"}
                dangerouslySetInnerHTML={{ __html: info.scenario.text }}
              ></p>

              <div className={styles.scenarioCtas}>
                {info.scenario.roomLinks &&
                  info.scenario.roomLinks.map((link, i) => (
                    <Button
                      label={link.label}
                      primary={i === 0 ? true : false}
                      key={i}
                      side={i === 1 ? "right" : "left"}
                      onClick={() => goToRoom(link.id)}
                    />
                  ))}
              </div>
            </>
            )}

          </>
        )}




        {info && info.type === "finish" && (
          <>
            {(completedScenarios.length === 5) ? (<>
              <p className={styles.popupInfoTitle}>Félicitations !</p>


              <p className={styles.popupInfoText} aria-live={"polite"}>
                🥳 🥳 🥳 : vous avez terminé toutes les quêtes !
                <br />
                N'hésitez pas à consulter les sujets que vous avez stockés dans votre panier.
              </p>


              <p className={styles.popupInfoText}>
                Dans tous les cas, nous restons à vos côtés. Quels que soient vos
                projets, nous sommes à votre écoute : contactez votre conseiller
                Macif.
              </p>

              <button
                className={styles.endCta}
                onClick={handleClickRdv}
                target="_blank"
              >
                Je prends rendez-vous
              </button>
            </>) : (<>
              <div className={styles.scenarioImage}>
                <img
                  src={info.scenario.image}
                  alt={`Image de la quête : ${info.scenario.title}`}
                />
                <div className={styles.scenarioHead}>
                  <p className={styles.questLabel}>Quête</p>

                  <p className={styles.popupInfoTitle} aria-live={"polite"}>
                    {info.scenario.title}
                  </p>
                </div>
              </div>
              <div className={styles.separationLine}></div>
              <p className={styles.popupInfoTitle}>Félicitations !</p>


              <p className={styles.popupInfoText} aria-live={"polite"}>
                🥳 🥳 🥳 : vous avez terminé la quête "{info.scenario.title}" !
                <br />
                <br />À vous de choisir la suite de votre parcours :
                <br />- découvrir les contenus collectés dans votre panier,
                <br />- cliquer sur d'autres objets dans ce hall pour lancer une
                nouvelle quête.
              </p>
              <p className={styles.popupInfoText}>
                Dans tous les cas, nous restons à vos côtés. Quels que soient vos
                projets, nous sommes à votre écoute : contactez votre conseiller
                Macif.
              </p>

              <button
                className={styles.endCta}
                onClick={handleClickRdv}
                target="_blank"
              >
                Je prends rendez-vous
              </button>
            </>)}
          </>
        )}
      </div>
    </>
  );
}

export default PopupInfo;

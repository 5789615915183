import { MeshStandardMaterial, RepeatWrapping, TextureLoader } from "three";
import React, { useState, useRef, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  ScrollControls,
  Environment,
  Lightformer,
} from "@react-three/drei";
import { Outlines, Html } from "@react-three/drei";
import { useLoader, useThree } from "@react-three/fiber";
import emitter from "../emitter";
import { useExperienceStore } from "../store";
import styles from "./Room.module.scss";
import { CameraControl } from "../components/CameraControl";
import SunRays from "../components/SunRays.js";
import useInactivityTimer from "../components/useInactivityTimer.js";

const camMobCalc = (data, currentPos, currentRot) => {
  const pos = [
    Math.sin(((data.offset - 0.5) * 0.75) / 1) * 2 + currentPos.current.x,
    data.offset - 0.5 + currentPos.current.y,
    currentPos.current.z,
  ];
  const rot = [
    currentRot.current.x,
    Math.sin(((data.offset - 0.5) * 0.75) / -0.2) + currentRot.current.y,
    currentRot.current.z,
  ];
  return { rotation: rot, position: pos };
};

const camDeskCalc = (pointer, currentPos, currentRot) => {
  const pos = [
    Math.sin(pointer.current.x / 1) * -2 + currentPos.current.x,
    pointer.current.y + currentPos.current.y,
    currentPos.current.z,
  ];
  const rot = [
    currentRot.current.x,
    Math.sin(pointer.current.x / -2) + currentRot.current.y,
    currentRot.current.z,
  ];
  return { rotation: rot, position: pos };
};

export function Hall(props) {
  const scenarios = useExperienceStore((state) => state.scenarios);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const completeScenario = useExperienceStore((state) => state.completeScenario);
  const group = useRef();
  const { gl } = useThree();
  const isMobile = window.innerWidth <= 768;
  const [tutorialShown, setTutorialShown] = useState(false); // Add this line

  useInactivityTimer("parcourir");

  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/textures/hall/lightmap-sol-hall.webp",
    process.env.PUBLIC_URL + "/matieres/parquet.webp",
    process.env.PUBLIC_URL + "/textures/hall/cap.webp",
    process.env.PUBLIC_URL + "/textures/hall/eco.webp",
    process.env.PUBLIC_URL + "/textures/hall/etagere.webp",
    process.env.PUBLIC_URL + "/textures/hall/feuille.webp",
    process.env.PUBLIC_URL + "/textures/hall/globe.webp",
    process.env.PUBLIC_URL + "/textures/hall/murshall.webp",
    process.env.PUBLIC_URL + "/textures/hall/tableau.webp",
    process.env.PUBLIC_URL + "/textures/hall/boussole.webp",
    process.env.PUBLIC_URL + "/textures/hall/ball.webp",
  ], []);

  const [
    lightmaphall,
    plancher,
    cap,
    eco,
    etagere,
    feuille,
    globe,
    murshall,
    tableau,
    boussole,
    ball,
  ] = useLoader(TextureLoader, texturePaths); 

  useEffect(() => {
    [plancher].forEach((t) => {
      t.wrapS = RepeatWrapping;
      t.wrapT = RepeatWrapping;
      t.repeat.set(5, 3);
      t.offset.set(0, 0);
    });
  }, [plancher]);




  useEffect(() => {
    if (activeScenario === null && !tutorialShown) { // Check if the tutorial hasn't been shown yet
      emitter.emit("tuto:open");
      setTutorialShown(true); // Set the flag to true after showing the tutorial
    }

    if (activeScenario && activeScenario.objects.every((obj) => obj.found)) {
      emitter.emit("popup:open", {
        type: "finish",
        scenario: activeScenario,
      });

      if (activeScenario.id) {
        completeScenario(activeScenario.id);
      }
    }

    emitter.emit(
      "pageViewEvent",
      "hall",
      activeScenario ? activeScenario.id : null,
    );
  }, []);


  const displayScenario = useCallback(
    (e, id) => {
      // zoomTo(e);
      const matchingScenario = scenarios.find((scenario) => scenario.id === id);
      if (matchingScenario) {
        console.warn("display-scenario:", id);
        emitter.emit("popup:open", {
          type: "scenario",
          scenario: matchingScenario,
        });

      }
    },
    [scenarios],
  );

  const onPointerEnter = useCallback(() => {
    emitter.emit("sound:hover");
    pointerEvents(true);
  }, []);

  // Détection de l'appareil mobile basée sur la largeur de l'écran

  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "/models/hall.glb");
  useEffect(() => {
    emitter.emit("scene:loaded");
  }, [nodes]);

  useLayoutEffect(() => {
    Object.values(nodes).forEach(
      (node) => node.isMesh && (node.receiveShadow = node.castShadow = true),
    );
    // applyProps(materials.rubber, { color: '#222', roughness: 0.6, roughnessMap: null, normalScale: [4, 4] })
    // applyProps(materials.window, { color: 'black', roughness: 0, clearcoat: 0.1 })
    // applyProps(materials.coat, { envMapIntensity: 4, roughness: 0.5, metalness: 1 })
    materials["murs.001"] = new MeshStandardMaterial({
      map: murshall,
      envMapIntensity: 2.5,
      roughness: 0.25,
      metalness: 0.5,
    });

    materials.meubles = new MeshStandardMaterial({
      map: etagere,
      envMapIntensity: 2.5,
      roughness: 0.25,
      metalness: 0.5,
    });

    materials.cadre = new MeshStandardMaterial({
      map: tableau,
      envMapIntensity: 2.5,
      roughness: 0.25,
      metalness: 0.5,
    });

    materials["pins-logo"] = new MeshStandardMaterial({
      map: materials["pins-logo"].map,
      envMapIntensity: 2.5,
      roughness: 0.25,
      metalness: 0.5,
    });

    materials["globe"] = new MeshStandardMaterial({
      map: materials["globe"].map,
      envMapIntensity: 2.5,
      roughness: 0.5,
      metalness: 0.25,
    });

    materials["cadre-noir"] = new MeshStandardMaterial({
      color: materials["cadre-noir"].color,
      envMapIntensity: 2.5,
      roughness: 0.5,
      metalness: 0.25,
    });

    materials.cadran = new MeshStandardMaterial({
      map: materials.cadran.map,
      envMapIntensity: 2.5,
      roughness: 0.25,
      metalness: 0.2,
    });

    materials.vetements = new MeshStandardMaterial({
      map: cap,
      envMapIntensity: 1.5,
      roughness: 1,
      metalness: 0.25,
    });

    materials.sol = new MeshStandardMaterial({
      map: materials.sol.map,
      envMapIntensity: 1,
      roughness: 0.25,
      metalness: 0.5,
    });
  }, [nodes, materials, cap, etagere, murshall, tableau]);

  const pointerEvents = (pointing) => {
    document.getElementsByTagName("body")[0].style.cursor = pointing
      ? "pointer"
      : "default";
  };

  const content = () => (
    <group scale={3}>
      <group
        position={[-3.242, 1.242, -0.142]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      >
        <mesh
          geometry={nodes.Cube094.geometry}
          material={materials.cadre}
          material-map={tableau}
          material-map-flipY={false}
        />
        <mesh
          geometry={nodes.Cube094_1.geometry}
          material={materials["White Cotton.001"]}
        />
      </group>
      <mesh
        geometry={nodes["tableau-gauche"].geometry}
        material={materials.cadre}
        material-map={tableau}
        material-map-flipY={false}
        position={[-3.24, 1.245, 1.075]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
      />
      <mesh
        geometry={nodes.murs.geometry}
        material={materials["murs.001"]}
        material-map={murshall}
        material-map-flipY={false}
        position={[0, 3.98, -10.357]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-2.421, -1, -1]}
      />
      <mesh
        geometry={nodes.sol.geometry}
        material={materials.sol}
        material-map={plancher}
        material-lightMap={lightmaphall}
        material-lightMapIntensity={2}
        material-lightMap-flipY={false}
        position={[-0.48, 3.98, -8.29]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-1.377, -0.844, -1]}
      />
      <mesh
        geometry={nodes.murs002.geometry}
        material={materials.cadre}
        material-map={tableau}
        material-map-flipY={false}
        position={[-1.14, 1.155, -2.1]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-1.404, -0.861, -1.086]}
        onClick={(e) => displayScenario(e, "soin-proches")}
        onPointerEnter={() => onPointerEnter()}
        onPointerLeave={() => pointerEvents(false)}
      >
        <Outlines thickness={0.03} color="#D3D62E" />
        <Html
          style={{ zIndex: -1 }}
          portal={{ current: gl.domElement.parentNode }}
        >
          <button
            className={styles.etiquette}
            onClick={(e) => displayScenario(e, "soin-proches")}
            onPointerEnter={() => onPointerEnter()}
            onPointerLeave={() => pointerEvents(false)}
          >
            Je prends soin de mes proches
          </button>
        </Html>
      </mesh>
      <group
        position={[-1.048, 1.905, -2.114]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-1.052, -0.645, -0.814]}
      >
        <mesh
          geometry={nodes.murs003_1.geometry}
          material={materials["cadre-noir"]}
        />
        <mesh
          geometry={nodes.murs003_2.geometry}
          material={materials.cadre}
          material-map={tableau}
        />
      </group>
      <mesh
        geometry={nodes.murs004.geometry}
        material={materials.cadre}
        material-map={tableau}
        position={[2.64, 1.419, -2.114]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-1.447, -0.887, -1.119]}
      />
      <mesh
        geometry={nodes.plante.geometry}
        material-map={feuille}
        material-map-flipY={false}
        position={[-2.897, 0.779, -0.058]}
        rotation={[0, 1.571, 0]}
        scale={1.139}
      />
      <mesh
        geometry={nodes.Cap_White.geometry}
        material={materials.vetements}
        material-map={cap}
        material-map-flipY={false}
        position={[1.541, 1.562, -1.977]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.939}
      />
      <mesh
        geometry={nodes.Nike_Air_Max_90_R.geometry}
        material-color="black"
        position={[-2.476, 0.024, 0.778]}
        rotation={[Math.PI, -1.266, Math.PI]}
        scale={1.453}
      />
      <mesh
        geometry={nodes.placard.geometry}
        material={materials.meubles}
        material-map={etagere}
        material-map-flipY={false}
        position={[-2.255, 1.038, -1.702]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
      />
      <mesh
        geometry={nodes.banc.geometry}
        material={materials.meubles}
        material-map={etagere}
        material-map-flipY={false}
        position={[1.38, 0.154, -1.282]}
        rotation={[Math.PI, -0.019, Math.PI]}
      />
      <mesh
        geometry={nodes["porte-manteau"].geometry}
        material={materials.meubles}
        material-map={etagere}
        material-map-flipY={false}
        position={[1.669, 0.421, -2.056]}
      />
      <mesh
        geometry={nodes.Cube040.geometry}
        material={materials.meubles}
        material-map={etagere}
        material-map-flipY={false}
        position={[-2.712, 0.163, 0.424]}
        rotation={[0, 1.571, 0]}
      />
      <mesh
        geometry={nodes["lampe-gauche"].geometry}
        material-map={globe}
        material-map-flipY={false}
        position={[-3.165, 1.759, -0.051]}
        rotation={[0, 1.571, 0]}
      />
      <mesh
        geometry={nodes.escalier.geometry}
        material={materials.wood}
        position={[-0.859, 0.826, -7.816]}
        scale={0.924}
      />
      <mesh
        geometry={nodes["etagere-droite"].geometry}
        material={materials.meubles}
        material-map={etagere}
        material-map-flipY={false}
        position={[3.043, 0.695, 0.156]}
      />
      <group
        position={[3.054, 0.737, -0.263]}
        rotation={[1.654, 0.008, 1.666]}
        scale={0.04}
        onClick={(e) => displayScenario(e, "soin-maison")}
        onPointerEnter={() => onPointerEnter()}
        onPointerLeave={() => pointerEvents(false)}
      >
        <mesh geometry={nodes.rouleau_1.geometry} material={materials.Material}>
          <Outlines thickness={0.3} color="#D3D62E" />
          <Html
            position={[-4, 0, 2]}

            style={{ zIndex: -1 }}
            as={"div"}
            portal={{ current: gl.domElement.parentNode }}
          >
            <button
              className={styles.etiquette}
              onClick={(e) => displayScenario(e, "soin-maison")}
              onPointerEnter={() => onPointerEnter()}
              onPointerLeave={() => pointerEvents(false)}
            >
              Je prends soin de mon chez moi
            </button>
          </Html>
        </mesh>
        <mesh
          geometry={nodes.rouleau_2.geometry}
          material={materials["Material.005"]}
        >
          <Outlines thickness={0.6} color="#D3D62E" />
        </mesh>
        <mesh
          geometry={nodes.rouleau_3.geometry}
          material={materials["Material.006"]}
        >
          <Outlines thickness={0.6} color="#D3D62E" />
        </mesh>
      </group>
      <mesh
        geometry={nodes.ballon.geometry}
        material-map={ball}
        material-map-flipY={false}
        position={[-0.682, 0.142, 0.769]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <group
        position={[2.95, 1.239, 0.274]}
        rotation={[0, -0.873, Math.PI / 2]}
        scale={0.069}
        onClick={(e) => displayScenario(e, "change-cap")}
        onPointerEnter={() => onPointerEnter()}
        onPointerLeave={() => pointerEvents(false)}
      >
        <Html
          scale={4}
          rotation={[0, 0, Math.PI / -2]}
          style={{ zIndex: -1 }}
          portal={{ current: gl.domElement.parentNode }}
        >
          <button
            className={styles.etiquette}
            onClick={(e) => displayScenario(e, "change-cap")}
            onPointerEnter={() => onPointerEnter()}
            onPointerLeave={() => pointerEvents(false)}
          >
            Je change de cap
          </button>
        </Html>

        <mesh
          geometry={nodes.boussole_1.geometry}
          material={materials["cadre-noir"]}
        >
          <Outlines thickness={0.2} color="#D3D62E" />
        </mesh>
        <mesh
          geometry={nodes.boussole_2.geometry}
          material-map={boussole}
          material-map-flipY={false}
        ></mesh>
      </group>
      <mesh
        geometry={nodes.globe.geometry}
        material-map={globe}
        material-map-flipY={false}
        position={[-2.952, 0.847, 0.541]}
        rotation={[0, 1.267, 0]}
        scale={0.24}
        onClick={(e) => displayScenario(e, "change-horizon")}
        onPointerEnter={() => onPointerEnter()}
        onPointerLeave={() => pointerEvents(false)}
      >
        <Outlines thickness={0.07} color="#D3D62E" />
        <Html
          position={[0, 0, 0]}
          scale={0.24}
          style={{ zIndex: -1 }}
          portal={{ current: gl.domElement.parentNode }}
        >
          <button
            className={styles.etiquette}
            onClick={(e) => displayScenario(e, "change-horizon")}
            onPointerEnter={() => onPointerEnter()}
            onPointerLeave={() => pointerEvents(false)}
          >
            J'ai envie d'évasion
          </button>
        </Html>
      </mesh>
      <group
        position={[1.331, 1.081, -1.727]}
        rotation={[Math.PI / 2, 0, 0.042]}
        scale={[0.102, 0.013, 0.102]}
      >
        <mesh
          geometry={nodes.pins_1.geometry}
          material={materials.pins}
          onClick={(e) => displayScenario(e, "engage-cause")}
          onPointerEnter={() => onPointerEnter()}
          onPointerLeave={() => pointerEvents(false)}
        >
          <Outlines thickness={0.5} color="#D3D62E" />
          <Html
            style={{ zIndex: -1 }}
            portal={{ current: gl.domElement.parentNode }}
          >
            <button
              className={styles.etiquette}
              onClick={(e) => displayScenario(e, "engage-cause")}
              onPointerEnter={() => onPointerEnter()}
              onPointerLeave={() => pointerEvents(false)}
            >
              Je m'engage pour une cause
            </button>
          </Html>
        </mesh>
        <mesh
          geometry={nodes.pins_2.geometry}
          material-map={eco}
          material-map-flipY={false}
          onClick={(e) => displayScenario(e, "engage-cause")}
          onPointerEnter={() => onPointerEnter()}
          onPointerLeave={() => pointerEvents(false)}
        />
      </group>
    </group>
  );

  return (
    <group {...props} dispose={null} ref={group}>
      {isMobile ? (
        <ScrollControls pages={8} damping={0.0001} horizontal={true}>
          <PerspectiveCamera
            makeDefault
            far={416.6}
            near={0.1}
            fov={60.115}
            position={[0, 2.25, 2]}
          />
          <CameraControl
            camPosDesk={[0, 3.25, 9]}
            camPosMob={[0, 4.25, 9]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
        </ScrollControls>
      ) : (
        <>
          <CameraControl
            camPosDesk={[0, 3.25, 9]}
            camPosMob={[0, 4.25, 6]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
          <PerspectiveCamera
            makeDefault
            far={416.6}
            near={0.1}
            fov={60.115}
            position={[0, 2, 6]}
          />
        </>
      )}
      {content()}
      <Environment blur={0.5}>
        <Lightformer
          intensity={0.1}
          position={[-1.242, 1.5, 2.25]}
          // rotation-y={Math.PI / 2}
          scale={10}
          color={"#ffece3"}
        />

        <Lightformer
          intensity={4}
          position={[-1.242, -2.5, 1.12]}
          rotation-y={Math.PI * 1.01}
          scale={4}
          color={"#ffcfba"}
        />

        {/* Ceiling */}
        <Lightformer
          intensity={0.66}
          rotation-x={Math.PI / 2}
          position={[0, 5, -9]}
          scale={[10, 10, 1]}
          color={"#94cff8"}
        />
        {/* Sides */}
        <Lightformer
          intensity={2}
          rotation-y={Math.PI / 2}
          position={[-0, 1, -1]}
          scale={[20, 0.1, 1]}
          color={"#d99c7f"}
        />
        <Lightformer
          position={[-5, -1, -1]}
          scale={[20, 0.5, 1]}
          color={"#d99c7f"}
        />
        <Lightformer
          intensity={1.15}
          rotation-y={-Math.PI / 2}
          position={[2, 1.5, 0.25]}
          scale={5}
          color={"#d99c7f"}
        />
        {/* Accent (red) */}
        <Lightformer
          form="ring"
          color={"#ff7c4f"}
          intensity={1}
          scale={10}
          position={[-15, 4, -18]}
          target={[0, 0, 0]}
        />
        {/*Background */}
      </Environment>
      {/* <Effects /> */}
      <SunRays />
    </group>
  );
}

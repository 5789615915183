import React, { useCallback, useRef, useEffect } from "react";
import styles from "./Ressources.module.scss";
import { gsap } from "gsap";
import { useExperienceStore } from "../../store";
import emitter from "../../emitter";

function Ressources() {
  const wrapperRef = useRef();
  const opened = useRef(false);
  const ressourcesSaved = useExperienceStore((state) => state.ressourcesSaved);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const currentRoom = useExperienceStore((state) => state.currentRoom);

  const modalRef = useRef();

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    // Bind the event listener
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        wrapperRef.current.style.opacity == 1
      ) {
        animateOut();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const animateOut = useCallback(() => {
    gsap.to(wrapperRef.current, {
      autoAlpha: 0,
      y: -10,
      ease: "expo.out",
      duration: 0.5,
    });
  }, []);

  const animateIn = useCallback(() => {
    gsap.to(wrapperRef.current, {
      autoAlpha: 1,
      y: 0,
      ease: "expo.out",
      duration: 0.5,
    });
  }, []);

  const toggleState = useCallback(() => {
    if (opened.current) {
      opened.current = false;
      wrapperRef.current.setAttribute("aria-hidden", "true");
      animateOut();
    } else {
      opened.current = true;
      wrapperRef.current.setAttribute("aria-hidden", "false");
      animateIn();
    }
  }, [opened, animateIn, animateOut]);

  const onBlur = useCallback(() => {
    opened.current = false;
    animateOut();
  }, [opened, animateOut]);

  const onClickObject = (e) => {
    emitter.emit("objectCartClickEvent", e.target.innerText);
  };

  const copyClickHandler = (e) => {
    // Get the text field
    const copyText = ressourcesSaved.map((ressource) => ressource.url).join("\n");

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText);

    opened.current = false;
    animateOut();

    // Alert the copied text
    // if (isMobile) {
    //   const tl = gsap
    //     .timeline()
    //     .to(modalRef.current, { bottom: "5%", opacity: 1, duration: 0.5 })
    //     .to(
    //       modalRef.current,
    //       { bottom: "10%", opacity: 0, duration: 0.5 },
    //       "2",
    //     );
    //   tl.eventCallback("onComplete", () => {
    //     tl.kill();
    //   });
    // } else {
    const tl = gsap
      .timeline()
      .to(modalRef.current, { top: "17.5%", opacity: 1, duration: 0.5 })
      .to(modalRef.current, { top: "15%", opacity: 0, duration: 0.5 }, "2");
    tl.eventCallback("onComplete", () => {
      tl.kill();
    });
    // }

    // alert("Liste des liens: \n" + copyText.toString().replaceAll(",", "\n"));
  };

  return (
    <>
      <div className={styles.ressource}>
        {(activeScenario || currentRoom == "hall") && (

          <button
            className={styles.ressourceButton}
            onBlur={() => onBlur()}
            onClick={() => toggleState()}
            aria-label={"Bouton Accès à la modale : liste des ressources"}
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="25" cy="25" r="25" fill="#0A2D82" />
              <path d="M36.2512 19.3841C35.6636 18.7151 34.8558 18.2691 33.9744 18.2691H22.6642C22.297 18.2691 21.9298 18.5664 21.9298 19.0125C21.9298 19.4585 22.297 19.7558 22.6642 19.7558H33.9744C34.4151 19.7558 34.8558 19.9788 35.1495 20.2762C35.4433 20.6479 35.5902 21.0939 35.4433 21.5399L34.7823 25.2567H26.7036C26.3364 25.2567 25.9692 25.554 25.9692 26C25.9692 26.4461 26.3364 26.7434 26.7036 26.7434H34.562L33.901 30.3115C33.8276 30.8319 33.3135 31.2036 32.7993 31.2036H18.6983C18.1842 31.2036 17.7435 30.8319 17.5966 30.3115L14.7324 14.1806C14.6589 13.8089 14.3652 13.5859 13.9979 13.5859H8.34283C7.97561 13.5859 7.6084 13.8833 7.6084 14.3293C7.6084 14.7753 7.97561 15.0727 8.34283 15.0727H13.4104L16.2012 30.6832C16.3481 31.7239 17.0825 32.4673 18.0373 32.7646C17.6701 33.285 17.3763 33.954 17.3763 34.6973C17.3763 36.5557 18.8452 38.0425 20.6813 38.0425C22.5173 38.0425 23.9862 36.5557 23.9862 34.6973C23.9862 34.0283 23.7659 33.3593 23.3986 32.8389H27.8787C27.5115 33.3593 27.2911 34.0283 27.2911 34.6973C27.2911 36.5557 28.76 38.0425 30.5961 38.0425C32.4321 38.0425 33.901 36.5557 33.901 34.6973C33.901 33.954 33.6807 33.285 33.3135 32.7646C34.2682 32.5416 35.0761 31.7239 35.2964 30.6832L36.9122 21.9116C37.059 21.0195 36.8387 20.1275 36.2512 19.3841ZM22.5173 34.623C22.5173 35.6637 21.7095 36.4814 20.6813 36.4814C19.6531 36.4814 18.8452 35.6637 18.8452 34.623C18.8452 33.5823 19.6531 32.7646 20.6813 32.7646C21.7095 32.7646 22.5173 33.5823 22.5173 34.623ZM30.6695 36.4814C29.6413 36.4814 28.8334 35.6637 28.8334 34.623C28.8334 33.5823 29.6413 32.7646 30.6695 32.7646C31.6977 32.7646 32.5056 33.5823 32.5056 34.623C32.5056 35.6637 31.6977 36.4814 30.6695 36.4814Z" fill="#D3D62E" />
              <defs>
                <clipPath id="clip0_1_311">
                  <rect
                    width="28.6356"
                    height="22.0842"
                    fill="white"
                    transform="translate(10.1201 18.8776)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div className={styles.counter}>
              <p>{ressourcesSaved.length}</p>
            </div>
          </button>
        )}
        <div
          aria-hidden="true"
          ref={wrapperRef}
          className={styles.ressourceContent}
        >
          {(!ressourcesSaved || ressourcesSaved.length === 0) && (
            <p>
              Voici votre panier, ajoutez les sujets que vous trouverez. Vous
              pouvez les consulter à tout moment pendant votre quête.
            </p>
          )}
          {ressourcesSaved && ressourcesSaved.length > 0 && (
            <>
              <p>Voici vos sujets collectés :</p>
              <ul>
                {ressourcesSaved.map((ressource) => (
                  <li>
                    <a
                      href={ressource.url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => onClickObject(e)}
                    >
                      {ressource.label || ressource.text}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_728_6678)">
                          <path
                            d="M14 8.39844V12.247C14 12.5654 13.932 12.8559 13.7898 13.1218C13.6476 13.3876 13.459 13.6009 13.221 13.7586C12.983 13.9162 12.7233 13.9966 12.4451 13.9966H1.55487C1.27357 13.9966 1.017 13.9162 0.77898 13.7586C0.540958 13.6009 0.352396 13.3876 0.210201 13.1218C0.0680062 12.8559 0 12.5654 0 12.247V1.74929C0 1.43399 0.0680062 1.14032 0.210201 0.87448C0.352396 0.608638 0.537867 0.395346 0.775889 0.237695C1.01391 0.0800442 1.27357 -0.000326779 1.55178 -0.000326779H5.59815C5.79289 -0.000326779 5.95981 0.0676794 6.09583 0.203692C6.23184 0.339704 6.29985 0.503537 6.29985 0.701373C6.29985 0.899209 6.23184 1.06304 6.09583 1.19905C5.96291 1.33507 5.79598 1.40307 5.59815 1.40307H1.80526C1.69397 1.40307 1.59815 1.44635 1.51777 1.53599C1.44049 1.62564 1.40031 1.73383 1.40031 1.86675V12.1419C1.40031 12.2717 1.44049 12.383 1.51777 12.4695C1.59815 12.5592 1.69397 12.6025 1.80526 12.6025H12.2071C12.3091 12.6025 12.4019 12.5561 12.4791 12.4634C12.5564 12.3706 12.5966 12.2624 12.5966 12.1419V8.40462C12.5966 8.20987 12.6646 8.04295 12.8006 7.90694C12.9366 7.77092 13.1036 7.70292 13.2983 7.70292C13.493 7.70292 13.66 7.77092 13.796 7.90694C13.9289 8.04295 14 8.20678 14 8.40462V8.39844ZM11.5085 1.39998L6.39876 6.50972C6.2473 6.6581 6.17311 6.84048 6.17311 7.05686C6.17311 7.27324 6.2473 7.45253 6.39876 7.604C6.54714 7.75238 6.72952 7.82657 6.9459 7.82657C7.16229 7.82657 7.34158 7.75238 7.49304 7.604L12.6028 2.49426V4.2006C12.6028 4.39535 12.6708 4.56227 12.8068 4.69828C12.9428 4.83429 13.1097 4.9023 13.3045 4.9023C13.4992 4.9023 13.6662 4.83429 13.8022 4.69828C13.9351 4.56227 14.0062 4.39844 14.0062 4.2006V0.698282C14.0062 0.503537 13.9382 0.336613 13.8022 0.200601C13.6662 0.0645882 13.5023 -0.00341797 13.3045 -0.00341797H9.80525C9.61051 -0.00341797 9.44359 0.0645882 9.30757 0.200601C9.17465 0.336613 9.10356 0.500446 9.10356 0.698282C9.10356 0.896118 9.17156 1.05995 9.30757 1.19596C9.44359 1.33198 9.61051 1.39998 9.80525 1.39998H11.5116H11.5085Z"
                            fill="#0A2D82"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_728_6678">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </li>
                ))}
              </ul>
              <button onClick={copyClickHandler} className={styles.copyBtn}>
                Copier les liens pour accéder aux contenus collectés
              </button>
            </>
          )}
        </div>
      </div>
      <p className={styles.modalCopy} ref={modalRef} aria-hidden={true}>
        Sujets copiés !
      </p>
    </>
  );
}

export default Ressources;

import { TextureLoader } from "three";

import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import {
  useGLTF,
  PerspectiveCamera,
  ScrollControls,
  Outlines,
  Html,
} from "@react-three/drei";
import emitter from "../emitter";
import { useExperienceStore } from "../store";
import { CameraControl } from "../components/CameraControl";
import { useLoader } from "@react-three/fiber";
import styles from "./Room.module.scss";
import Glow from "../components/Glow";
import useInactivityTimer from "../components/useInactivityTimer.js";


export function Cuisine(props) {
  const objectsInRoom = useExperienceStore((state) => state.objectsInRoom());
  const setObjectFound = useExperienceStore((state) => state.setObjectFound);
  const activeScenario = useExperienceStore((state) => state.activeScenario);
  const objectsFound = useRef([]);
  const [colorsItems, setColorsItems] = useState(null);

  useInactivityTimer("parcourir");

  const texturePaths = useMemo(() => [
    process.env.PUBLIC_URL + "/textures/cuisine/plafond.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/bol.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/cylindre.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/frigidere.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/Plane.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/poubelles.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/table.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/tabouret.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/cylindre107.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/liste.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/merge.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/fruits.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/murscuisine.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/meubles.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/lavabo.webp",
    process.env.PUBLIC_URL + "/textures/cuisine/meubles2.webp",
  ], []);

  const [
    sol,
    bol,
    cylindre,
    frigidere,
    Plane,
    rubbish,
    table,
    tabouret,
    cylindre107,
    liste,
    objets,
    fruits,
    murscuisine,
    meubles,
    lavabo,
    meubles2,
  ] = useLoader(TextureLoader, texturePaths);

  // End Load Texture

  // Message intro
  useEffect(() => {
    emitter.emit("info:open", {
      title: "Restez en alerte !",
      text: `On ne sait jamais ce que peut cacher un objet !`,
    });

    const timeoutId = setTimeout(() => {
      emitter.emit("info:close");
    }, 4000);

    const items = {
      poubelle: "#D3D62E",
      canette: "#D3D62E",
      lunettes: "#D3D62E",
      checklist: "#D3D62E",
      produitBio: "#D3D62E",
    };

    for (const [key2] of Object.entries(items)) {
      const obj = activeScenario.objects.find((obj) => {
        let tmpKey = key2;
        if (key2 == "produitBio") {
          tmpKey = "produit-bio";
        }
        return obj.id === tmpKey;
      });
      if (obj && obj.hasOwnProperty("found")) {
        items[key2] = "#FF0000";
      }
    }

    setColorsItems(items);

    // Fonction de nettoyage
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  // message intro

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    emitter.emit("pageViewEvent", "cuisine", activeScenario.id);
  }, []);

  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL + "/models/cuisine.glb");

  useEffect(() => {
    emitter.emit("scene:loaded");
  }, [nodes]);

  const pointerEvents = (pointing = false) => {
    document.getElementsByTagName("body")[0].style.cursor = pointing
      ? "pointer"
      : "default";
  };

  const selectObject = useCallback(
    (id) => {
      const matchingObject = objectsInRoom.find((obj) => obj.id === id);
      if (matchingObject) {
        if (colorsItems !== null) {
          if (id === "produit-bio") {
            const copy = { ...colorsItems };
            copy["produitBio"] = "#FF0000";
            setColorsItems(copy);
          } else {
            const copy = { ...colorsItems };
            copy[id] = "#FF0000";
            setColorsItems(copy);
          }
        }
        setObjectFound(matchingObject.id);
        emitter.emit("popup:open", {
          type: "object",
          object: matchingObject,
        });
        if (!objectsFound.current.includes(matchingObject.id)) {
          emitter.emit("findObjectEvent", matchingObject.id, activeScenario.id, 'Cuisine');
          objectsFound.current.push(matchingObject.id);
        }
      }
    },
    [setObjectFound, objectsInRoom],
  );

  const onPointerEnter = useCallback((id) => {
    const matchingObject = objectsInRoom.find((obj) => obj.id === id);
    if (matchingObject) {
      emitter.emit("sound:hover");
      pointerEvents(true);
    }
  }, []);

  const content = () => (
    <group scale={3} rotation={[0, 0.15, 0]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material-map={cylindre}
        material-map-flipY={false}
        position={[-3.915, 1.252, -12.125]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={[-0.153, -0.008, -0.153]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.poubelle.geometry}
        material-map={rubbish}
        material-map-flipY={false}
        position={[0.364, 0.529, -11.627]}
        rotation={[Math.PI, -1.549, Math.PI]}
        scale={0.275}
        onClick={() => selectObject("poubelle")}
        onPointerEnter={() => onPointerEnter("poubelle")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "poubelle") && (
          <>
            <Glow
              scale={2}
              near={-5}
              far={1.5}
              color={colorsItems["poubelle"]}
              position={[2, 0, 0.4]}
              rotation={[0, 96, 0]}
            />
            <Outlines thickness={0.03} color={colorsItems["poubelle"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("poubelle");
                  }
                }}
                aria-label="Ouvrir la poubelle"
              >
                La poubelle
              </button>
            </Html>
          </>
        )}
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.canettes.geometry}
        material-map={objets}
        material-map-flipY={false}
        position={[1, 1.07, -7.087]}
        scale={[0.05, 0.14, 0.088]}

        onClick={() => selectObject("canette")}
        onPointerEnter={() => onPointerEnter("canette")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "canette") && (
          <>
            <Glow
              scale={2.5}
              near={-2}
              far={0.8}
              color={colorsItems["canette"]}
              position={[-4, 0, 4]}

              rotation={[0, Math.PI / -2, 0]}
            />
            <Outlines thickness={0.09} color={colorsItems["canette"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("canette");
                  }
                }}
                aria-label="Ouvrir la modale canette"
              >
                La canette
              </button>
            </Html>
          </>
        )}
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lunettes.geometry}
        material-map={objets}
        material-map-flipY={false}
        position={[-3.857, 0.975, -8.779]}
        onClick={() => selectObject("lunettes")}
        onPointerEnter={() => onPointerEnter("lunettes")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "lunettes") && (
          <>
            <Glow
              scale={0.6}
              near={-3}
              far={1}
              color={colorsItems["lunettes"]}
            />
            <Outlines thickness={0.009} color={colorsItems["lunettes"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("lunettes");
                  }
                }}
                aria-label="Ouvrir la modale lunettes"
              >
                Les lunettes
              </button>
            </Html>
          </>
        )}
      </mesh>

      <group
        onClick={() => selectObject("checklist")}
        onPointerEnter={() => onPointerEnter("checklist")}
        onPointerLeave={() => pointerEvents(false)}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.listeBois.geometry}
          position={[0.828, 1.594, -10.309]}
          rotation={[Math.PI / 2, 0, 1.577]}
          scale={2.872}
        >
          {objectsInRoom.find((obj) => obj.id === "checklist") && (
            <>
              <Glow
                scale={0.1}
                near={-5}
                far={1}
                rotation={[2.9, 1.6, 0]}
                position={[0.2, 0.1, 0]}
                color={colorsItems["checklist"]}
              />

              <Outlines thickness={0.009} color={colorsItems["checklist"]} />
              <Html>
                <button
                  className={styles.objetsscene}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      selectObject("checklist");
                    }
                  }}
                  aria-label="Ouvrir la modale checklist"
                >
                  La checklist
                </button>
              </Html>
            </>
          )}
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.listePapier.geometry}
          material-map={liste}
          material-map-flipY={false}
          position={[0.824, 1.915, -10.311]}
          rotation={[Math.PI, -1.565, Math.PI]}
          scale={2.872}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.packbio.geometry}
        material-map={objets}
        material-map-flipY={false}
        position={[-1.739, 1.169, -8.68]}
        rotation={[Math.PI / 2, 0, 0.621]}
        scale={[0.061, 0.062, 0.062]}
        onClick={() => selectObject("produit-bio")}
        onPointerEnter={() => onPointerEnter("produit-bio")}
        onPointerLeave={() => pointerEvents(false)}
      >
        {objectsInRoom.find((obj) => obj.id === "produit-bio") && (
          <>
            <Glow
              scale={5.6}
              near={-2}
              far={1}
              color={colorsItems["produitBio"]}
              position={[0, 0, 0]}
              rotation={[5, 0, 0]}
            />
            <Outlines thickness={0.2} color={colorsItems["produitBio"]} />
            <Html>
              <button
                className={styles.objetsscene}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    selectObject("produit-bio");
                  }
                }}
                aria-label="Ouvrir la modale produit bio"
              >
                Le produit bio
              </button>
            </Html>
          </>
        )}
      </mesh>
      <group
        position={[-2.54, 2.081, -11.892]}
        rotation={[0, -1.571, 0]}
        scale={0.601}
      >
        <group position={[0.058, -0.161, 0.662]}>
          <group position={[-0.09, -1.267, -0.456]} rotation={[0, 1.052, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Mesh003.geometry}
              material={materials["Material  hojas planta.001"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Mesh003_1.geometry}
              material={materials["Material rama.001"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Mesh003_2.geometry}
              material={materials["Oro patas.001"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Mesh003_3.geometry}
              material={materials["Clay.001"]}
            />
          </group>
        </group>
      </group>
      <group
        position={[-3.085, 0.987, -8.523]}
        rotation={[1.579, 0.001, 0.119]}
        scale={0.601}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh005.geometry}
          material={materials["Metal utensilios_0.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh005_1.geometry}
          material-map={bol}
          material-map-flipY={false}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.frigidere.geometry}
        material-map={frigidere}
        material-map-flipY={false}
        position={[0.785, 0.59, -10.828]}
        scale={[0.601, 0.679, 0.601]}
      />

      <group
        position={[-2.259, 1.256, -11.539]}
        rotation={[-2.704, 0.529, -0.232]}
        scale={0.601}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh002.geometry}
          material={materials["Blanco 003.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh002_1.geometry}
          material={materials["Metal 002.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh002_2.geometry}
          material={materials["Metal 007.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh002_3.geometry}
          material={materials["Metal 001.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material-map={Plane}
        material-map-flipY={false}
        position={[-4.142, 1.978, -12.312]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[1.424, 1.104, 1.965]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.egoutoir.geometry}
        material={materials.egoutoir}
        position={[-2.259, 1.256, -11.539]}
        rotation={[-2.704, 0.529, -0.232]}
        scale={0.601}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube_139.geometry}
        material-map={meubles}
        material-map-flipY={false}
        position={[0.565, 2.163, -11.979]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={0.601}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder_107.geometry}
        material-map={cylindre107}
        material-map-flipY={false}
        position={[0.748, 1.45, -12.138]}
        rotation={[0, 0, -0.004]}
        scale={0.601}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.plafond.geometry}
        material-map={sol}
        material-map-flipY={false}
        position={[2.3, 4.866, -7.4]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.009, 0.01, 0.01]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tabouret.geometry}
        material-map={tabouret}
        material-map-flipY={false}
        position={[-2.253, 0.757, -7.696]}
        scale={[0.234, 0.024, 0.234]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.table001.geometry}
        material-map={table}
        material-map-flipY={false}
        position={[-2.986, 0.447, -8.726]}
        scale={[1.758, 0.533, 0.512]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.corbeille.geometry}
        material-color="#2d4533"
        position={[-4.123, 1.022, -8.711]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.12, 0.056, 0.056]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fruits.geometry}
        material-map={fruits}
        material-map-flipY={false}
        position={[-4.369, 1.038, -8.809]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.023}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material-map={murscuisine}
        material-map-flipY={false}
        position={[-2.545, 2.42, -12.388]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[3.194, 1, 2.45]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material-map={lavabo}
        material-map-flipY={false}
        position={[1.257, 1.056, -8.306]}
        scale={[0.154, 0.11, 0.154]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lampe.geometry}
        material-map={meubles2}
        material-map-flipY={false}
        position={[0.107, 4.243, -8.889]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[0.064, 0.952, 0.064]}
      />
    </group>
  );

  const camMobCalc = (data, currentPos, currentRot) => {
    const pos = [
      Math.sin(((data.offset - 0.5) * 0.75) / 1) + currentPos.current.x,
      Math.sin(((data.offset - 0.5) * 0.75) / 1) + currentPos.current.y,
      currentPos.current.z,
    ];
    const rot = [0, Math.sin(((data.offset - 0.5) * 0.75) / 1) * -0.75, 0];
    return { rotation: rot, position: pos };
  };

  const camDeskCalc = (pointer, currentPos, currentRot) => {
    const pos = [
      Math.sin(pointer.current.x / 1) * -2 + currentPos.current.x,
      pointer.current.y + currentPos.current.y,
      currentPos.current.z,
    ];
    const rot = [
      currentRot.current.x,
      Math.sin(pointer.current.x / -10) + currentRot.current.y,
      currentRot.current.z,
    ];
    return { rotation: rot, position: pos };
  };

  return (
    <group {...props} dispose={null}>
      {isMobile ? (
        <ScrollControls pages={7} damping={0.0001} horizontal={true}>
          <PerspectiveCamera
            makeDefault={true}
            far={100.6}
            near={0.1}
            fov={25.115}
            position={[-9, 4.25, 1]}
          />
          <CameraControl
            camPosDesk={[-8, 4.25, 9]}
            camPosMob={[-9, 5.25, 8]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
        </ScrollControls>
      ) : (
        <>
          <CameraControl
            camPosDesk={[-8, 4.35, 4]}
            camPosMob={[-9, 4.25, 6]}
            camMobCalc={camMobCalc}
            camDeskCalc={camDeskCalc}
          ></CameraControl>
          <PerspectiveCamera
            makeDefault={true}
            far={100.6}
            near={0.1}
            fov={28.115}
            position={[-8, 3.75, 3]}
          />
        </>
      )}
      {colorsItems != null && content()}
    </group>
  );
}
